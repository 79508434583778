import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';

import { whitespace } from 'src/utils/Form/validators';
import { PowerScheduleFormData } from 'src/views/PowerScheduleSetupView';
import PrimaryPowerScheduleFormSchedule from 'src/views/PowerScheduleSetupView/Components/PowerScheduleForm/PrimaryPowerScheduleForm/PrimaryPowerScheduleFormSchedule';
import { PowerScheduleWeekdayLong } from 'src/views/PowerScheduleSetupView/Utils/powerScheduleWeekday';

const PrimaryPowerScheduleForm = () => {
    const t = useTranslation();
    const { formState, setValue, watch } = useFormContext<PowerScheduleFormData>();
    const { errors } = formState;
    const schedules = watch('primaryPowerSchedule.schedules');

    const handlePrimaryEndTimeChange = useCallback(
        (key: string, date: Date | null) => {
            return setValue(
                `primaryPowerSchedule.schedules.${key as PowerScheduleWeekdayLong}.endTime`,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
                date
            );
        },
        [setValue]
    );

    const handlePrimaryStartTimeChange = useCallback(
        (key: string, date: Date | null) => {
            return setValue(
                `primaryPowerSchedule.schedules.${key as PowerScheduleWeekdayLong}.startTime`,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore suppress ts error here because setValue doesn't work well with generated strings as value resolves to never
                date
            );
        },
        [setValue]
    );

    return (
        <div data-testid="primary-power-schedule-form">
            <Typography variant="h5" sx={{ margin: '0 0 10px' }}>
                {t.trans('engage.players.setup.power_schedule.primary.title')}
            </Typography>
            <TextInput
                defaultLabel="common.form.input.name"
                error={errors.primaryPowerSchedule?.name}
                name="primaryPowerSchedule.name"
                required
                rules={{
                    required: 'common.validation.required',
                    validate: { whitespace },
                }}
            />
            <Typography sx={{ margin: '10px 0 10px' }}>
                {t.trans('engage.players.setup.power_schedule.primary.description')}
            </Typography>
            <div data-testid="schedules">
                {Object.entries(schedules).map(([weekday, schedule]) => (
                    <PrimaryPowerScheduleFormSchedule
                        key={weekday}
                        onEndTimeChange={handlePrimaryEndTimeChange}
                        onStartTimeChange={handlePrimaryStartTimeChange}
                        schedule={schedule}
                        weekday={weekday}
                    />
                ))}
            </div>
        </div>
    );
};

export default PrimaryPowerScheduleForm;
