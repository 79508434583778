import React from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { Subtract } from 'utility-types';
import { v1 as uuid } from 'uuid';

type WithMediaResolverProps = {
    playlistMedia: Partial<PlaylistMediaType>;
};

export interface WithMediaResolverInjectedProps {
    media: PlaylistMediaType;
}

export const createPlaylistMedia = (mediaFile: DiscriminatedEntity<LibraryModelsMedia>): PlaylistMediaType => ({
    id: uuid().toString(),
    mediaId: mediaFile.id,
    mediaSchedules: [],
    model: mediaFile,
});

const withMediaResolver = <P extends WithMediaResolverInjectedProps>(
    Component: React.ComponentType<P>,
    SkeletonComponent: React.ComponentType | null = null
): React.FC<Subtract<P, WithMediaResolverInjectedProps> & WithMediaResolverProps> =>
    function MediaResolverComponent({ playlistMedia, ...rest }) {
        const api = useApi();
        const { workspace } = useWorkspace();

        const { result: resolvedMedia, loading: isResolvingMedia } = useAsyncAbortable(
            async (signal) => {
                // Special case to handle when we're adding files into content list
                if (playlistMedia.model !== undefined) {
                    return playlistMedia as PlaylistMediaType;
                }

                // If there's no media id then just bail out
                if (playlistMedia.mediaId === undefined) {
                    return undefined;
                }

                return api.media
                    .getMediaById(
                        {
                            mediaid: playlistMedia.mediaId,
                            workspaceid: workspace.id,
                        },
                        { signal }
                    )
                    .then((response) => response.data)
                    .then(mediaFileMapper)
                    .then(
                        (media) =>
                            ({
                                ...playlistMedia,
                                model: media,
                                mediaSchedules: playlistMedia.mediaSchedules || [],
                            } as PlaylistMediaType)
                    );
            },
            [api, playlistMedia, workspace.id]
        );

        if (isResolvingMedia || resolvedMedia === undefined) {
            return SkeletonComponent === null ? null : <SkeletonComponent />;
        }

        return <Component {...(rest as unknown as P)} media={resolvedMedia} />;
    };

export default withMediaResolver;
