import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Link, Toolbar } from '@mui/material';
import { DropdownMenuItem } from '@zetadisplay/engage-components';
import { Logo } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import MobileHeader from 'src/layouts/Components/MobileHeader/MobileHeader';
import Navigation from 'src/layouts/Components/Navigation';
import UserMenu from 'src/layouts/Components/UserMenu';
import WorkspaceSelection from 'src/layouts/Components/WorkspaceSelection';
import useNavigation from 'src/layouts/Hooks/useNavigation';
import useUserMenu from 'src/layouts/Hooks/useUserMenu';

const useStyles = makeStyles()((theme) => ({
    root: {
        position: 'relative',
    },
    toolbar: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        height: 54,

        [theme.breakpoints.up('md')]: {
            height: 83,
        },
    },
    appBar: {
        backgroundColor: 'transparent',
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
            borderBottom: '1px solid #ccc;',
            marginBottom: 41,
        },
    },
    customerContext: {
        marginLeft: -7,
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 130,
        },
    },
    secondaryLink: {
        color: '#1D1D1D',
    },
    secondaryLinkBold: {
        color: '#1D1D1D',
        fontSize: 14,
        fontWeight: 600,
    },
    logo: {
        display: 'block',
        height: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            height: 40,
            marginLeft: 0,
            marginRight: 0,
        },
    },
    button: {
        color: theme.palette.text.primary,
    },
    hamburger: {
        height: 24,
        minWidth: 24,
        padding: 0,
        marginLeft: 6,
    },
    drawerContent: {
        padding: '24px 48px',
    },
}));

const Header = () => {
    const { classes, cx } = useStyles();
    const navigationItems = useNavigation();
    const userMenuItems: DropdownMenuItem[] = useUserMenu();

    return (
        <div id="header" className={cx(classes.root)}>
            <AppBar position="static" className={cx(classes.appBar)} elevation={0}>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <MobileHeader secondaryMenuItems={userMenuItems} />
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Toolbar className={cx(classes.toolbar)}>
                        <Link to="/" component={RouterLink}>
                            <Logo height={40} className={cx(classes.logo)} />
                        </Link>
                        <Navigation items={navigationItems} />
                        <WorkspaceSelection />
                        <UserMenu menuItems={userMenuItems} />
                    </Toolbar>
                </Box>
            </AppBar>
        </div>
    );
};

export default Header;
