import React, { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { convertScheduleToEngage } from '@zetadisplay/engage-components/utils/scheduling';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Schedule, Scheduling } from '@zetadisplay/zeta-ui-components';
import {
    MODE_NAME_ALWAYS,
    MODE_NAME_SCHEDULED,
} from '@zetadisplay/zeta-ui-components/components/scheduling/components/modes';
import pLimit from 'p-limit';

import usePendingPromise from 'src/hooks/usePendingPromise';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

const limit = pLimit(1);

export type ScheduleMultipleMediaFields = {
    schedules: Schedule[];
};

export type ScheduleMultipleMediaModalProps = {
    items: DiscriminatedEntity<LibraryModelsMedia>[];
} & NiceModalHocProps;

const ScheduleMultipleMediaFilesModal = NiceModal.create<ScheduleMultipleMediaModalProps>(({ items }) => {
    const api = useApi();
    const methods = useForm<ScheduleMultipleMediaFields>();
    const modal = useModal();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        async (formValues: ScheduleMultipleMediaFields) => {
            const response = await api.media.setMultipleMediaDefaultSchedules({
                body: {
                    mediaIds: items.map((m) => m.id),
                    defaultSchedules: formValues.schedules.map((schedule) => convertScheduleToEngage(schedule)),
                },
                workspaceid: workspace.id,
            });

            // We need to iterate over every media that has playlists and apply schedules to them
            const mediaWithPlaylists = items.filter((m) => m.campaignIds && m.campaignIds.length !== 0);
            if (mediaWithPlaylists.length) {
                await Promise.all(
                    mediaWithPlaylists.map((m) =>
                        limit(() => {
                            return api.media.applyMediaSchedulesToPlaylists({
                                body: m.campaignIds!,
                                mediaid: m.id,
                                workspaceid: workspace.id,
                            });
                        })
                    )
                );
            }

            return response.data;
        },
        {
            success: {
                message: 'engage.notification.schedule.multiple.media.success',
                params: [items.length.toString()],
            },
        }
    );

    const onSubmit = useCallback(
        async (formValues: ScheduleMultipleMediaFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: {
                            disabled: methods.formState.isSubmitting,
                            onClick: modal.hide,
                        },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{ label: 'engage.modal.schedule.media.title' }}
            >
                <Controller
                    defaultValue={[]}
                    name="schedules"
                    render={({ field: { onChange } }) => (
                        <Scheduling
                            allowedModes={[MODE_NAME_ALWAYS, MODE_NAME_SCHEDULED]}
                            initialSchedules={[]}
                            multiple
                            onChange={onChange}
                            showPlayingTimes
                            showWeekdays
                            useDateTime
                        />
                    )}
                />
                <Typography sx={{ paddingTop: 3 }} variant="h5">
                    {t.trans('engage.modal.schedule.media.content.usage')}
                </Typography>
                <Typography>{t.trans('engage.modal.schedule.media.content.info')}</Typography>
            </Modal>
        </FormProvider>
    );
});

export default ScheduleMultipleMediaFilesModal;
