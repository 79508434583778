import React, { useCallback } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client';
import { useReturnUrl, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { createButtonClickEvent, pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { AxiosError } from 'axios';

import { PLAYERS_BASE_PATH } from 'src/constants/Paths';

type Props = {
    groupId?: string | null;
};

const GroupSetupActions = ({ groupId = undefined }: Props) => {
    const api = useApi();
    const history = useNavigate();
    const location = useLocation();
    const snackbar = useTranslatedSnackbar();
    const t = useTranslation();
    const { workspace } = useWorkspace();
    const returnUrl = useReturnUrl();

    // Extract copy flag from search params
    const [searchParams] = useSearchParams();
    const isCopy = searchParams.get('copy') === 'true';

    const { formState, handleSubmit } = useFormContext();
    const { isSubmitting, isValid } = formState;

    const onCancel = useCallback(() => {
        history(returnUrl || PLAYERS_BASE_PATH);
    }, [history, returnUrl]);

    // TODO: Use proper type for data instead of FieldValues
    const onSubmit = async (data: FieldValues) => {
        try {
            const { attributesConstraints, description, name } = data;
            const state = location.state as { currentGroupId?: number };

            const body: ConfigModelsGroupPersistModel = {
                attributesConstraints: (attributesConstraints.length > 0 && attributesConstraints) || [],
                description,
                name,
                parent: state?.currentGroupId,
            };

            if (groupId && !isCopy) {
                await api.publishGroups.editGroup({
                    groupid: Number.parseInt(groupId, 10),
                    workspaceid: workspace.id,
                    body,
                });

                snackbar('engage.notification.edit.group.success', [name], 'success');
            } else {
                await api.publishGroups.createGroup({
                    workspaceid: workspace.id,
                    body,
                });

                snackbar('engage.notification.create.group.success', [name], 'success');
            }

            pushToDataLayer(createButtonClickEvent('Group Settings', 'Save'));

            return onCancel();
        } catch (e) {
            if (!(e instanceof AxiosError)) {
                throw e;
            }

            return snackbar(handleResponseError(e), [], 'error');
        }
    };

    return (
        <div>
            <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={onCancel} />
            <Button
                busy={isSubmitting}
                disabled={isSubmitting || !isValid}
                kind="primaryAction"
                label={t.trans('common.action.save')}
                onClick={handleSubmit((data) => onSubmit(data))}
            />
        </div>
    );
};

export default GroupSetupActions;
