import { useCallback, useMemo } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import {
    NetworkModelsLifelineConnectionStatusEnum,
    NetworkModelsPlayerInformation,
    SharedModelsPagedResultsOfNetworkModelsPlayerInformation,
} from '@zetadisplay/engage-api-client';
import { usePaginatedRequests, usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import { onPaginatedResultsErrorHandler } from '@zetadisplay/engage-components/hooks/use-paginated-results/use-paginated-results';
import getResultData from '@zetadisplay/engage-components/hooks/utils/get-result-data';
import { DiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useSorting } from '@zetadisplay/engage-components/modules/options';
import { FilterFields, QueryFilter, QueryOperation } from '@zetadisplay/engage-components/modules/search';
import { getQueryFilter } from '@zetadisplay/engage-components/modules/search/query-filters';
import { createKeywordFilterQuery, hasSearchFilters } from '@zetadisplay/engage-components/modules/search/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { uuidRegex } from '@zetadisplay/engage-components/utils/route';
import { SearchFilters, SearchState } from '@zetadisplay/zeta-ui-components';

const getPlayerOnlineStateFilterQuery = (searchState: SearchState<SearchFilters>) => {
    if (!(FilterFields.STATUS in searchState) || typeof searchState[FilterFields.STATUS] !== 'string') {
        return undefined;
    }

    const status = searchState[FilterFields.STATUS] as string;
    return NetworkModelsLifelineConnectionStatusEnum[status as keyof typeof NetworkModelsLifelineConnectionStatusEnum];
};

export type UsePaginatedPlayersOptions = {
    currentGroupId?: number;
    enabled?: boolean;
    pageSize?: number;
    searchFilters?: SearchState<SearchFilters>;
    searchFiltersKey?: string;
    showPlayersOnly?: boolean;
};

export type UsePaginatedPlayersReturnType = {
    getNextResultPage: () => void;
    data: DiscriminatedEntity<NetworkModelsPlayerInformation>[];
    isLoading: boolean;
    removeResult: (item: DiscriminatedEntity<NetworkModelsPlayerInformation>) => void;
    setResult: (item: DiscriminatedEntity<NetworkModelsPlayerInformation>) => void;
    total: number | undefined;
};

const usePaginatedPlayers = ({
    currentGroupId,
    enabled = true,
    pageSize,
    searchFilters,
    searchFiltersKey,
    showPlayersOnly,
}: UsePaginatedPlayersOptions): UsePaginatedPlayersReturnType => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace } = useWorkspace();

    const filters = useMemo(() => {
        if (searchFilters === undefined) {
            return [];
        }

        return [
            createKeywordFilterQuery(searchFilters, [
                {
                    condition: (term: string) => uuidRegex.test(term),
                    operation: QueryOperation.Eq,
                    property: 'guid',
                },
                'name',
                'friendlyName',
            ]),
        ].filter((filter): filter is QueryFilter => filter !== undefined);
    }, [searchFilters]);

    const { $top, $skip, getNextResultPage } = usePaginatedRequests(
        `group-${currentGroupId}`,
        searchFiltersKey,
        sorting.sort,
        pageSize
    );

    const { results, removeResult, setResult, setResults, total } = usePaginatedResults<NetworkModelsPlayerInformation>(
        `group-${currentGroupId}`,
        EntityDiscriminators.Player,
        searchFiltersKey
    );

    const onPlayerFetchSuccess = useCallback(
        (response: SharedModelsPagedResultsOfNetworkModelsPlayerInformation) => {
            return setResults(response.items, response.total);
        },
        [setResults]
    );

    const getPlayersByFilter = useCallback(
        (
            signal: AbortSignal,
            filter: string | undefined,
            onlineState: NetworkModelsLifelineConnectionStatusEnum | undefined
        ) => {
            return api.players
                .getPlayers(
                    {
                        workspaceid: workspace.id,
                        onlineState,
                        $filter: filter,
                        $top,
                        $skip,
                    },
                    { signal }
                )
                .then((response) => response.data)
                .then(onPlayerFetchSuccess)
                .catch(() => onPaginatedResultsErrorHandler(results.current, setResults));
        },
        [api.players, workspace.id, $top, $skip, onPlayerFetchSuccess, results, setResults]
    );

    const getPlayersByGroups = useCallback(
        (signal: AbortSignal) => {
            if (currentGroupId === undefined) {
                return undefined;
            }

            return api.players
                .getPlayersByGroups(
                    {
                        ids: [currentGroupId],
                        workspaceid: workspace.id,
                        $top,
                        $skip,
                    },
                    { signal }
                )
                .then((response) => response.data)
                .then(onPlayerFetchSuccess)
                .catch(() => onPaginatedResultsErrorHandler(results.current, setResults));
        },
        [$skip, $top, api.players, currentGroupId, onPlayerFetchSuccess, results, setResults, workspace.id]
    );

    const players = useAsyncAbortable(
        async (signal) => {
            if (!enabled || !hasSearchFilters(searchFilters, 'keyword', 'status')) {
                return undefined;
            }

            const filter = getQueryFilter(filters);
            const onlineState = searchFilters && getPlayerOnlineStateFilterQuery(searchFilters);

            const fetchPlayersOnly = showPlayersOnly && currentGroupId === undefined;
            if (filter !== undefined || onlineState !== undefined || fetchPlayersOnly) {
                return getPlayersByFilter(signal, filter, onlineState);
            }

            return getPlayersByGroups(signal);
        },
        [currentGroupId, enabled, filters, getPlayersByFilter, getPlayersByGroups, searchFilters, showPlayersOnly],
        {
            setLoading: (state) => {
                return { ...state, result: results.current && [...results.current.values()], loading: true };
            },
        }
    );

    return {
        getNextResultPage,
        data: getResultData(results.current),
        isLoading: players.loading,
        removeResult,
        setResult,
        total: total.current,
    };
};

export default usePaginatedPlayers;
