import { useMemo } from 'react';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import {
    UseMediaContentReturnType,
    UseMediaFoldersReturnType,
} from '@zetadisplay/engage-components/modules/library/hooks';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import mediaMovePromiseFactory from 'src/modules/library/actions/mediaMovePromiseFactory';
import handlePendingPromise from 'src/utils/HandlePendingPromise';
import { useOnMediaFileCreatedListener } from 'src/views/LibraryView/Events/onMediaFileCreatedEvent';
import {
    emitOnMediaFileDeleted,
    useOnMediaFileDeletedListener,
} from 'src/views/LibraryView/Events/onMediaFileDeletedEvent';
import { useOnMediaFileDraggedListener } from 'src/views/LibraryView/Events/onMediaFileDraggedEvent';
import { useOnMediaFileEditedListener } from 'src/views/LibraryView/Events/onMediaFileEditedEvent';
import { useOnMediaFileScheduledListener } from 'src/views/LibraryView/Events/onMediaFileScheduledEvent';
import { useOnMediaFolderCreatedListener } from 'src/views/LibraryView/Events/onMediaFolderCreatedEvent';
import { useOnMediaFolderDeletedListener } from 'src/views/LibraryView/Events/onMediaFolderDeletedEvent';
import { useOnMediaFolderEditedListener } from 'src/views/LibraryView/Events/onMediaFolderEditedEvent';
import { useOnMediaFolderSharedListener } from 'src/views/LibraryView/Events/onMediaFolderSharedEvent';

const useLibraryViewEvents = (content: UseMediaContentReturnType, folders: UseMediaFoldersReturnType) => {
    const api = useApi();
    const { workspace } = useWorkspace();
    const notify = useNotify();

    const moveMediaPromise = useMemo(
        () => mediaMovePromiseFactory({ api, workspaceid: workspace.id }),
        [api, workspace.id]
    );

    useOnMediaFileCreatedListener(content.setResult);
    useOnMediaFileDeletedListener(content.removeResult);
    useOnMediaFileEditedListener(content.setResult);
    useOnMediaFileScheduledListener(content.setResult);
    useOnMediaFolderCreatedListener(folders.setResult);
    useOnMediaFolderDeletedListener(folders.removeResult);
    useOnMediaFolderEditedListener(folders.setResult);
    useOnMediaFolderSharedListener(folders.setResult);
    useOnMediaFileDraggedListener(async ({ source, target }) => {
        if (!source.data) {
            return;
        }
        await handlePendingPromise(
            moveMediaPromise([source.data], target.id as string),
            {
                pending: {
                    message: 'common.notification.move.executing',
                    params: [source.data.name, target.name || ''],
                },
                success: {
                    message: 'common.notification.move.success',
                    params: [source.data.name, target?.name || ''],
                },
            },
            notify
        );
        emitOnMediaFileDeleted(source.data);
    });
};

export default useLibraryViewEvents;
