import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter as Router,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import env from '@beam-australia/react-env';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import 'react-app-polyfill/ie11';
import 'core-js';
import 'es6-promise/auto';
import './index.css';
import pkg from '../package.json';
import App from './App';
import unregisterServiceWorker from './unregisterServiceWorker';

const release = env('APP_VERSION') || pkg.version;
const version = process.env.NODE_ENV === 'development' ? `${release}.DEV` : release;

Sentry.init({
    dsn: env('SENTRY_DSN'),
    environment: env('STAGE') || 'dev',
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
    ],
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay(),
    ],
    release: `engage@${version}`,
    replaysSessionSampleRate: ['dev', 'test'].includes(env('STAGE')) ? 0.1 : 0, // Disabled in production
    replaysOnErrorSampleRate: ['dev', 'test'].includes(env('STAGE')) ? 1.0 : 0, // Disabled in production
    tracesSampleRate: env('STAGE') === 'dev' ? 1.0 : 0.2,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Router basename={process.env.PUBLIC_URL}>
            <App />
        </Router>
    </React.StrictMode>
);

unregisterServiceWorker();
