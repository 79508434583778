import React, { useCallback, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { LibraryModelsFolder, LibraryModelsFolderActions } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile, isMediaFolder } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { getMediaFolderBreadcrumbs } from '@zetadisplay/engage-components/modules/library/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { getFolderById, getFoldersByParentId } from '@zetadisplay/engage-components/utils/media';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { PrimaryAction } from '@zetadisplay/zeta-ui-components';

import useSidekickNavigation from 'src/components/Sidekicks/Hooks/useSidekickNavigation';
import useSidekickTargetSelection from 'src/components/Sidekicks/Hooks/useSidekickTargetSelection';
import useSidekickTargets from 'src/components/Sidekicks/Library/Hooks/useSidekickTargets';
import MoveSidekick from 'src/components/Sidekicks/MoveSidekick';
import MediaSubtitle from 'src/components/Subtitles/Library/MediaSubtitle';
import usePendingPromise from 'src/hooks/usePendingPromise';
import mediaMovePromiseFactory from 'src/modules/library/actions/mediaMovePromiseFactory';
import { LibraryItemsType } from 'src/views/LibraryView';

import { getFilteredFolders, getFoldersByParentWithChildrenAsArray } from '../Utils/GetFolders';

export type MoveMediaTargetType = TreeNode<DiscriminatedEntity<LibraryModelsFolder>>;

export type MoveLibraryItemsSidekickProps = {
    subjects: LibraryItemsType[];
} & NiceModalHocProps;

const isSameFolderForMediaFile = (subject: LibraryItemsType, item: MoveMediaTargetType) => {
    if (!isMediaFile(subject)) {
        return false;
    }

    return subject.folderId === item.id;
};

const isSameFolderForMediaFolder = (
    subject: LibraryItemsType,
    item: MoveMediaTargetType,
    targets: Map<string, MoveMediaTargetType> | undefined
) => {
    if (!isMediaFolder(subject) || targets === undefined) {
        return false;
    }

    if (subject.id === item.id || subject.parentId === item.id) {
        return true;
    }

    const collection = getFoldersByParentWithChildrenAsArray([...getFoldersByParentId(subject.id, targets).values()]);

    // Find item in collection of folders and return true if found
    return collection.some((folder) => folder.id === item.id);
};

const MoveLibraryItemSidekick = NiceModal.create<MoveLibraryItemsSidekickProps>(({ subjects }) => {
    const api = useApi();
    const modal = useModal();
    const t = useTranslation();
    const { rootNode, targets } = useSidekickTargets();
    const { workspace } = useWorkspace();

    const targetSelection = useSidekickTargetSelection(getFolderById, targets.result || new Map());
    const navigation = useSidekickNavigation(getFolderById, targets.result || new Map());
    const currentNode: MoveMediaTargetType | undefined = navigation.currentNode || rootNode.result || undefined;

    const breadcrumbs = useMemo(
        () =>
            getMediaFolderBreadcrumbs(
                navigation.currentNode,
                targets.result || new Map(),
                navigation.updateCurrentNode
            ),
        [navigation.currentNode, navigation.updateCurrentNode, targets.result]
    );

    const folders = useMemo(
        () => getFilteredFolders(currentNode, navigation.currentNode, targets.result),
        [currentNode, navigation.currentNode, targets]
    );

    const isClickable = useCallback(
        (item: MoveMediaTargetType) => {
            if (currentNode === undefined || item.id === currentNode.id) {
                return false;
            }

            return 'children' in item && !!item.children.size;
        },
        [currentNode]
    );

    const moveMediaPromise = useMemo(
        () => mediaMovePromiseFactory({ api, workspaceid: workspace.id }),
        [api, workspace.id]
    );

    const submitAction = usePendingPromise(
        async () => {
            if (targetSelection.selectedTarget === undefined) {
                return undefined;
            }

            return moveMediaPromise(subjects, targetSelection.selectedTarget.id).then(() => subjects);
        },
        {
            success: {
                message: 'common.notification.move.success',
                params: [
                    subjects.length === 1 ? subjects[0].name : subjects.length.toString(),
                    targetSelection.selectedTarget?.name || '',
                ],
                plural: subjects.length > 1,
            },
        }
    );

    const onSubmit = useAsyncCallback(async () => {
        const response = await submitAction();
        if (response === undefined) {
            return;
        }

        modal.resolve(response);
        modal.hide();
    });

    const primaryAction = useCallback(
        (item: MoveMediaTargetType): PrimaryAction<MoveMediaTargetType> => ({
            label: t.trans('common.action.select'),
            handler: () => targetSelection.addSelectedTarget(item),
            disabled:
                !(item.actionFlags & LibraryModelsFolderActions.Add) ||
                subjects.find((subject) => isSameFolderForMediaFile(subject, item)) !== undefined ||
                subjects.find((subject) => isSameFolderForMediaFolder(subject, item, targets.result)) !== undefined ||
                targetSelection.selectedTarget !== undefined,
        }),
        [subjects, t, targetSelection, targets.result]
    );

    return (
        <MoveSidekick
            ActionProps={{
                busy: onSubmit.loading,
                disabled: onSubmit.loading || targetSelection.selectedTarget === undefined,
                onClick: onSubmit.execute,
            }}
            breadcrumbs={breadcrumbs}
            isLoading={rootNode.loading || targets.loading}
            selectedTargets={targetSelection.selectedTarget ? [targetSelection.selectedTarget] : []}
            onRemoveSelectedTarget={targetSelection.removeSelectedTarget}
            subjects={subjects}
            SubjectItemProps={{ renderSubtitle: (item) => <MediaSubtitle item={item} /> }}
            targets={folders}
            TargetItemProps={{
                clickable: isClickable,
                onItemClick: navigation.updateCurrentNode,
                primaryAction,
            }}
        />
    );
});

export default MoveLibraryItemSidekick;
