import * as z from 'zod';
import { ZodType } from 'zod';

import { PowerScheduleAssignedPlayerType, PrimaryPowerScheduleFormData } from 'src/views/PowerScheduleSetupView';

import { PowerScheduleAssignedPlayerState } from '../../../Utils/powerScheduleFormEnums';

const assignedPlayersSchema: ZodType<PowerScheduleAssignedPlayerType> = z.object({
    id: z.number(),
    name: z.string(),
    friendlyName: z.string(),
    guid: z.string(),
    state: z.enum([PowerScheduleAssignedPlayerState.NEW, PowerScheduleAssignedPlayerState.DELETED]).optional(),
    discriminatorType: z.literal('Player'),
});

export const scheduleDaySchema = z
    .object({
        enabled: z.boolean(),
        endTime: z.date().nullable(),
        startTime: z.date().nullable(),
    })
    .refine(
        (data) => {
            // CASE1: if start time is empty but end time is not empty
            const { startTime, endTime } = data;
            if ((startTime === null && endTime === null) || (startTime !== null && endTime !== null)) {
                return true;
            }
            return !(startTime === null && endTime !== null);
        },
        { message: 'common.validation.required', path: ['startTime'] }
    )
    .refine(
        (data) => {
            const { startTime, endTime } = data;
            // CASE2: if start time is not empty but end time is empty
            if ((startTime === null && endTime === null) || (startTime !== null && endTime !== null)) {
                return true;
            }
            return !(startTime !== null && endTime === null);
        },
        { message: 'common.validation.required', path: ['endTime'] }
    );

const PrimaryPowerScheduleSchema: ZodType<PrimaryPowerScheduleFormData> = z.object({
    id: z.number(),
    name: z.string().min(1, { message: 'Required' }),
    schedules: z.object({
        monday: scheduleDaySchema,
        tuesday: scheduleDaySchema,
        wednesday: scheduleDaySchema,
        thursday: scheduleDaySchema,
        friday: scheduleDaySchema,
        saturday: scheduleDaySchema,
        sunday: scheduleDaySchema,
    }),
    assignedPlayers: z.array(assignedPlayersSchema).optional(),
    workspaceId: z.string().nonempty(),
});

export default PrimaryPowerScheduleSchema;
