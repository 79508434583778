import React from 'react';
import { DialogActions } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, ButtonKind } from '@zetadisplay/zeta-ui-components';

export type ModalActionButton = {
    busy?: boolean;
    disabled?: boolean;
    kind?: ButtonKind;
    label: string;
    name: string;
    onClick?: () => void;
    type?: 'submit' | 'button' | 'reset';
};

export type ModalActionsProps = {
    buttons?: ModalActionButton[];
};

export const defaultCancelButton: ModalActionButton = {
    kind: 'secondaryAction',
    label: 'common.action.cancel',
    name: 'cancel',
    type: 'button',
};

export const defaultSubmitButton: ModalActionButton = {
    kind: 'primaryAction',
    label: 'common.action.save',
    name: 'save',
    type: 'submit',
};

export const createDefaultButtons = (buttons?: {
    cancel?: Partial<ModalActionButton>;
    submit?: Partial<ModalActionButton>;
}) => [
    { ...defaultCancelButton, ...buttons?.cancel },
    { ...defaultSubmitButton, ...buttons?.submit },
];

const ModalActions = ({ buttons = [] }: ModalActionsProps) => {
    const t = useTranslation();

    return (
        <DialogActions>
            {buttons.map((action) => (
                <Button
                    busy={action.busy}
                    disabled={action.disabled}
                    key={action.label}
                    kind={action.kind}
                    label={t.trans(action.label)}
                    name={action.name}
                    onClick={action.onClick}
                    type={action.type}
                />
            ))}
        </DialogActions>
    );
};

export default ModalActions;
