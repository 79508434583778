import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { ListItem, Stack, Typography } from '@mui/material';
import { LibraryModelsMedia, LibraryModelsThumbStatus } from '@zetadisplay/engage-api-client';
import { PreviewDialog } from '@zetadisplay/engage-components';
import { DiscriminatedEntity, EntityDiscriminators, isMediaFolder } from '@zetadisplay/engage-components/models';
import {
    PlaylistMediaItemScheduling,
    PlaylistMediaType,
    PlaylistSetupModifiedMediaType,
} from '@zetadisplay/engage-components/modules/playlist';
import { getPreviewUrl, getThumbnailUrl, isPreviewable } from '@zetadisplay/engage-components/utils/media';
import { ContextMenu, DragObject, ItemListItemSkeleton, Thumbnail } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { XYCoord } from 'dnd-core';

import MediaSubtitle from 'src/components/Subtitles/Library/MediaSubtitle';
import usePlaylistSetupContentListItemActions from 'src/views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistSetupContentList/Hooks/usePlaylistSetupContentListItemActions';
import PlaylistSetupContentListItemAspectRatio from 'src/views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistSetupContentList/PlaylistSetupContentListItem/PlaylistSetupContentListItemAspectRatio';
import withMediaResolver, { WithMediaResolverInjectedProps } from 'src/views/PlaylistView/Utils/withMediaResolver';

const useStyles = makeStyles()(() => ({
    root: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        height: 76,
        padding: '16px 15px 16px 0',
    },
    thumbCell: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 12,
        position: 'relative',
        width: 70,
    },
    details: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    'details--icons': {
        marginTop: 5,
    },
    title: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.47px',
    },
}));

const renderPreview = (item: DiscriminatedEntity<LibraryModelsMedia>) => {
    if (isMediaFolder(item) || item.thumbStatus !== LibraryModelsThumbStatus.HasThumb) {
        return null;
    }

    return (
        <PreviewDialog
            media={item}
            previewable={isPreviewable(item)}
            previewUrl={getPreviewUrl(item)}
            renderSubtitle={(arg) => <MediaSubtitle item={arg} />}
        />
    );
};

interface Props extends WithMediaResolverInjectedProps {
    copies: string[];
    layoutZoneId?: number;
    modifiedMedia: Map<string, PlaylistSetupModifiedMediaType>;
    onDragItem: (dragIndex: number, hoverIndex: number, newItem: PlaylistMediaType | undefined) => void;
    playlistMediaIndex: number;
}

const PlaylistSetupContentListItem = ({
    copies,
    layoutZoneId,
    media,
    modifiedMedia,
    onDragItem,
    playlistMediaIndex,
}: Props) => {
    const { classes, cx } = useStyles();

    const modifiedData = modifiedMedia.get(media.model.id)?.data || undefined;
    const thumbnailUrl = getThumbnailUrl(media.model);
    const actions = usePlaylistSetupContentListItemActions(copies, media, modifiedData);

    const ref = useRef<HTMLDivElement>(null);

    const [isDragging, drag] = useDrag({
        type: EntityDiscriminators.MediaFile,
        item: () => ({
            id: media.id,
            index: playlistMediaIndex,
            data: media.model,
            type: media.model.discriminatorType,
        }),
        collect: (monitor) => monitor.isDragging(),
    });

    const [handlerId, drop] = useDrop({
        accept: EntityDiscriminators.MediaFile,
        collect: (monitor) => monitor.getHandlerId(),
        hover: (item: DragObject<DiscriminatedEntity<LibraryModelsMedia>>, monitor: DropTargetMonitor) => {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index || 0;
            const hoverIndex = playlistMediaIndex;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            onDragItem(dragIndex, hoverIndex, { id: item.id as string, model: item.data!, mediaSchedules: [] });

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return (
        <div data-handler-id={handlerId} ref={ref} style={{ opacity: isDragging ? 0.2 : 1 }}>
            <ListItem className={cx(classes.root)} role="listitem" data-testid={media.id}>
                <span className={cx(classes.thumbCell)}>
                    {renderPreview(media.model)}
                    {thumbnailUrl && <Thumbnail alt={media.model.name} size={70} source={thumbnailUrl} />}
                </span>
                <span className={cx(classes.details)}>
                    <Typography className={cx(classes.title)} noWrap title={modifiedData?.name || media.model.name}>
                        {modifiedData?.name || media.model.name}
                    </Typography>
                    <Stack className={cx(classes['details--icons'])} direction="row" spacing={1}>
                        <PlaylistSetupContentListItemAspectRatio media={media.model} layoutZoneId={layoutZoneId} />
                        <PlaylistMediaItemScheduling schedules={media.mediaSchedules} />
                    </Stack>
                </span>
                <ContextMenu actions={actions} item={media} />
            </ListItem>
        </div>
    );
};

export default withMediaResolver(PlaylistSetupContentListItem, () => <ItemListItemSkeleton textCells={1} />);
