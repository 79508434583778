import React from 'react';
import { Typography } from '@mui/material';
import {
    LibraryModelsFolder,
    LibraryModelsMedia,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile, isMediaFolder } from '@zetadisplay/engage-components/models';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import MediaFileSubtitle from 'src/components/Subtitles/Library/MediaSubtitle/MediaFileSubtitle';
import MediaFolderSubtitle from 'src/components/Subtitles/Library/MediaSubtitle/MediaFolderSubtitle';

const useStyles = makeStyles()((theme) => ({
    text: {
        color: theme.palette.background.disabledText,
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
}));

type Props = {
    item:
        | DiscriminatedEntity<LibraryModelsMedia>
        | DiscriminatedEntity<LibraryModelsFolder>
        | DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>;
};

const MediaSubtitle = ({ item }: Props) => {
    const { classes, cx } = useStyles();

    return (
        <Typography variant="subtitle1" noWrap className={cx(classes.text)}>
            {isMediaFile(item) && <MediaFileSubtitle item={item} />}
            {isMediaFolder(item) && <MediaFolderSubtitle item={item} />}
        </Typography>
    );
};

export default MediaSubtitle;
