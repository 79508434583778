import React, { useState } from 'react';
import { Popover } from '@mui/material';
import { CaretDown } from '@styled-icons/boxicons-regular';
import { SelectWorkspaceView, useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        marginLeft: -7,
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 12,
        },
    },
    caret: {
        marginLeft: 6,
    },
}));

const WorkspaceSelection = () => {
    const { classes, cx } = useStyles();
    const { changeWorkspace, workspace } = useWorkspace();

    const [anchor, setAnchor] = useState<HTMLButtonElement | undefined>(undefined);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget);
    const handleClose = () => setAnchor(undefined);

    return (
        <div className={cx(classes.root)}>
            <Button onClick={handleClick} variant="text">
                {workspace.name}
                <CaretDown size={14} color="#c1c1c1" className={cx(classes.caret)} />
            </Button>

            <Popover
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                open={!!anchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SelectWorkspaceView
                    mode="compact"
                    onSelectWorkspace={(ws) => {
                        setAnchor(undefined);
                        changeWorkspace(ws);
                    }}
                    selectedWorkspace={workspace}
                />
            </Popover>
        </div>
    );
};

export default WorkspaceSelection;
