import NiceModal from '@ebay/nice-modal-react';
import { LibraryModelsFolder, LibraryModelsFolderActions } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFolder } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';

import DeleteMediaFolderPrompt, {
    RemoveFolderPromptProps,
} from 'src/components/Modals/Library/MediaFolder/DeleteMediaFolderPrompt';
import ShareMediaFolderForm, {
    ShareMediaFolderFormProps,
} from 'src/components/Modals/Library/MediaFolder/ShareMediaFolderForm';
import UpdateMediaFolderForm, {
    UpdateMediaFolderFormProps,
} from 'src/components/Modals/Library/MediaFolder/UpdateMediaFolderForm';
import MoveLibraryItemSidekick, {
    MoveLibraryItemsSidekickProps,
} from 'src/components/Sidekicks/Library/MoveLibraryItemSidekick';
import { emitOnMediaFolderDeleted } from 'src/views/LibraryView/Events/onMediaFolderDeletedEvent';
import { emitOnMediaFolderEdited } from 'src/views/LibraryView/Events/onMediaFolderEditedEvent';
import { emitOnMediaFolderShared } from 'src/views/LibraryView/Events/onMediaFolderSharedEvent';
import useIsDisabledLibraryAction from 'src/views/LibraryView/Hooks/useIsDisabledLibraryAction';

const useLibraryListFolderActions = (): ActionGroup<DiscriminatedEntity<LibraryModelsFolder>>[] => {
    const shouldDisableAction = useIsDisabledLibraryAction();
    const t = useTranslation();

    const handleMoveMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>[], MoveLibraryItemsSidekickProps>(
            MoveLibraryItemSidekick,
            {
                subjects: [folder],
            }
        ).then((folders) => folders.forEach((f) => emitOnMediaFolderDeleted(f)));
    };

    const handleRenameMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, UpdateMediaFolderFormProps>(UpdateMediaFolderForm, {
            folder,
        }).then(emitOnMediaFolderEdited);
    };

    const handleShareMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, ShareMediaFolderFormProps>(ShareMediaFolderForm, {
            folder,
        }).then(emitOnMediaFolderShared);
    };

    const handleDeleteMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsFolder>, RemoveFolderPromptProps>(DeleteMediaFolderPrompt, {
            folder,
        }).then(emitOnMediaFolderDeleted);
    };

    const isDisabledForMoveMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        return shouldDisableAction(folder, LibraryModelsFolderActions.Move, ['ENGAGE_LIBRARY_CONTENT_EDIT']);
    };

    const isDisabledForRenameMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        return shouldDisableAction(folder, LibraryModelsFolderActions.Rename, ['ENGAGE_LIBRARY_FOLDER_EDIT']);
    };

    const isDisabledForShareMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        return shouldDisableAction(folder, LibraryModelsFolderActions.Share, ['ENGAGE_LIBRARY_FOLDER_SHARE']);
    };

    const isDisabledForDeleteMediaFolder = (folder: DiscriminatedEntity<LibraryModelsFolder>) => {
        return shouldDisableAction(folder, LibraryModelsFolderActions.Delete, ['ENGAGE_LIBRARY_FOLDER_DELETE']);
    };

    return [
        {
            actions: [
                {
                    handler: handleMoveMediaFolder,
                    isDisabled: isDisabledForMoveMediaFolder,
                    isVisible: isMediaFolder,
                    label: t.trans('common.action.move'),
                    name: 'move',
                },
                {
                    handler: handleRenameMediaFolder,
                    isDisabled: isDisabledForRenameMediaFolder,
                    isVisible: isMediaFolder,
                    label: t.trans('common.action.rename'),
                    name: 'rename',
                },
                {
                    handler: handleShareMediaFolder,
                    isDisabled: isDisabledForShareMediaFolder,
                    isVisible: isMediaFolder,
                    label: t.trans('common.action.share'),
                    name: 'share',
                },
                {
                    handler: handleDeleteMediaFolder,
                    isDisabled: isDisabledForDeleteMediaFolder,
                    isVisible: isMediaFolder,
                    label: t.trans('common.action.delete'),
                    name: 'delete',
                },
            ],
            name: 'library-list-folder-actions',
        },
    ];
};

export default useLibraryListFolderActions;
