import React, { useContext } from 'react';
import { IconButton, Stack } from '@mui/material';
import { ConfigModelsAttributeValueRelations } from '@zetadisplay/engage-api-client/models/config-models-attribute-value-relations';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import LabelValueInput from 'src/components/Inputs/LabelValueInput';

import PlayerSettingsChannelContext from '../PlayerSettingsChannelContext';

type PlayerSettingsChannelLabelsDetailsProps = {
    attributes: (ConfigModelsAttributeValueRelations & { key: string })[];
    onRemove: (index: number) => void;
};

const PlayerSettingsChannelLabelsDetails: React.FC<PlayerSettingsChannelLabelsDetailsProps> = ({
    attributes,
    onRemove,
}) => {
    const { currentChannelIndex } = useContext(PlayerSettingsChannelContext);

    return (
        <Stack spacing={1} sx={{ maxHeight: '375px', overflow: 'auto' }}>
            {attributes.map((attribute, index) => (
                <Stack data-testid={`attribute-detail-${attribute.attribute.id}`} direction="row" key={attribute.key}>
                    <LabelValueInput
                        label={attribute.attribute}
                        name={`channels.${currentChannelIndex}.attributes.${index}.attributeValue.id`}
                        variant="filled"
                    />
                    <IconButton data-testid="delete" onClick={() => onRemove(index)} size="small">
                        <Icon type="DELETE" size={24} color={themeOptions.colors.ORANGE} />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    );
};

export default PlayerSettingsChannelLabelsDetails;
