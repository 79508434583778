import {
    ConfigModelsGroupInfo,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { createScheduleFromEngageSchedule } from '@zetadisplay/engage-components/modules/playlist';
import { TreeBuilder } from '@zetadisplay/engage-components/utils/tree-builder';
import pLimit from 'p-limit';

import createPlaylistFormValues from 'src/modules/playlist/utils/createPlaylistFormValues';

const limit = pLimit(1);

const fetchPublishingGroups = async (
    publishGroups: PublishingCampaignSchedulingCampaignInfoWithMediaSchedules['publishGroups'],
    workspaceId: string,
    api: ReturnType<typeof useApi>
) => {
    if (publishGroups === null || publishGroups === undefined || publishGroups.length === 0) {
        return [];
    }

    const groups = await Promise.all(
        publishGroups.map((group) =>
            limit(async () => {
                return api.publishGroups
                    .getGroup({
                        groupid: group,
                        workspaceid: workspaceId,
                    })
                    .then((response) => response.data)
                    .catch(() => undefined);
            })
        )
    );

    return [
        ...TreeBuilder<DiscriminatedEntity<ConfigModelsGroupInfo>, ConfigModelsGroupInfo, number>(
            groups.filter((group): group is ConfigModelsGroupInfo => group !== undefined),
            (group) => createDiscriminatedEntity(EntityDiscriminators.Group, group),
            undefined,
            'parent'
        ).values(),
    ];
};

const fetchPlaylistFormData = async (playlistId: number, workspaceId: string, api: ReturnType<typeof useApi>) => {
    const playlist = await api.playlists.getCampaignById({
        campaignid: playlistId,
        workspaceid: workspaceId,
    });

    const groups = await fetchPublishingGroups(playlist.data.publishGroups, workspaceId, api);
    const playlistMediaCollection = playlist.data.campaignMedia?.map((media) => ({
        ...media,
        mediaSchedules: media.mediaSchedules?.map(createScheduleFromEngageSchedule) || [],
    }));

    return createPlaylistFormValues({
        folderId: playlist.data.folderId,
        groups,
        layoutZoneId: playlist.data.layoutZoneId ?? undefined,
        name: playlist.data.name,
        playlistMediaCollection: playlistMediaCollection || [],
        mixingMode: playlist.data.mixingMode,
        priority: playlist.data.priority,
        schedules: playlist.data.schedules,
        status: playlist.status,
    });
};

export default fetchPlaylistFormData;
