import React, { ReactNode } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { PlaylistMediaMenu, PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { formatDuration } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Theme } from '@zetadisplay/zeta-ui-components/components/theme';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import useResolvePlaylistMediaCollection from 'src/hooks/useResolvePlaylistMediaCollection';

const useStyles = makeStyles()((theme) => ({
    container: {
        marginTop: 10,
    },
    detailSubject: {
        fontSize: 13,
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: 2,
    },
    text: {
        fontSize: 12,
        lineHeight: '16px',
        marginBottom: 2,
    },
}));

type Props = {
    playlistMediaCollection: Partial<PlaylistMediaType>[];
};

const PlaylistItemDetailsContent = ({ playlistMediaCollection }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();
    const [resolvedMedia] = useResolvePlaylistMediaCollection(playlistMediaCollection);

    const renderInformationDetail = (detailSubject: string, detailValue: ReactNode, isLoading?: boolean) => (
        <>
            <Typography className={cx(classes.detailSubject, classes.text)}>{detailSubject}:</Typography>
            <Typography className={classes.text}>{isLoading ? <Skeleton width={40} /> : detailValue}</Typography>
        </>
    );

    return (
        <div className={classes.container} data-testid="playlist-content">
            <Typography className={classes.title} variant="h5">
                {t.trans('engage.playlist.details.content')}
            </Typography>
            <Grid container>
                <Grid container item>
                    {renderInformationDetail(
                        t.trans('engage.playlist.details.duration'),
                        formatDuration(
                            resolvedMedia.reduce(
                                (duration, mediaFile) => duration + (mediaFile.model?.duration || 0),
                                0
                            )
                        ),
                        resolvedMedia.some((mediaFile) => !mediaFile)
                    )}
                </Grid>
            </Grid>
            <Theme dark nested>
                <PlaylistMediaMenu mediaCollection={resolvedMedia} />
            </Theme>
        </div>
    );
};

export default PlaylistItemDetailsContent;
