import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Box, IconButton, LinearProgress, LinearProgressProps, ListItem, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useCurrentMediaFolder } from '@zetadisplay/engage-components/modules/library/hooks';
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon, Thumbnail } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';
import { AxiosError } from 'axios';
import { filesize } from 'filesize';
import update from 'immutability-helper';
import pLimit from 'p-limit';

import ConfirmMediaFileOverwritePrompt, {
    ConfirmMediaFileOverwritePromptProps,
} from 'src/components/Modals/Library/MediaFile/ConfirmMediaFileOverwritePrompt';
import useNotify from 'src/hooks/useNotify';

const limit = pLimit(1);
const { DARKGRAY, ORANGE } = themeOptions.colors;

export const availableFileExtensions = [
    '.jpg',
    '.jpeg',
    '.gif',
    '.png',
    '.bmp',
    '.wmv',
    '.mp3',
    '.swf',
    '.flv',
    '.mp4',
    '.f4v',
    '.f4p',
];

const useStyles = makeStyles()((theme) => ({
    details: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    fileList: {
        display: 'block',
        maxHeight: 900,
        margin: '20px 0',
        overflow: 'auto',
    },
    root: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        height: 76,
        padding: '16px 15px 16px 0',
    },
    subtitleText: {
        color: theme.palette.background.disabledText,
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    thumbCell: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: 12,
        position: 'relative',
        width: 70,
    },
}));

type FileUploadState = {
    error?: string;
    progress: number;
    status: 'cancelled' | 'done' | 'error' | 'pending' | 'processing' | 'uploading';
};

type UploadMediaFileFormFields = {
    files: File[];
};

const getProgressColor = (status: FileUploadState['status']) => {
    switch (status) {
        case 'cancelled':
            return 'error';
        case 'done':
        case 'processing':
            return 'success';
        case 'uploading':
            return 'primary';
        default:
            return 'inherit';
    }
};

const getStatusText = (status: FileUploadState['status']) => {
    switch (status) {
        case 'cancelled':
            return 'engage.modal.upload.file.status.cancelled';
        case 'done':
            return 'engage.modal.upload.file.status.done';
        case 'processing':
            return 'engage.modal.upload.file.status.processing';
        case 'uploading':
            return 'engage.modal.upload.file.status.uploading';
        default:
            return 'engage.modal.upload.file.status.pending';
    }
};

const isVideo = (file: File) => file.type.includes('video');

const LinearProgressWithLabel: React.FC<LinearProgressProps & { statusText: string; value: number }> = ({
    statusText,
    value,
    ...rest
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress {...rest} value={value} />
            </Box>
            <Box>
                <Typography variant="body2" color="text.secondary">
                    {statusText}
                </Typography>
            </Box>
        </Box>
    );
};

export type UploadMediaFileFormProps = {
    initialFiles?: File[];
} & NiceModalHocProps;

const UploadMediaFileForm = NiceModal.create<UploadMediaFileFormProps>(({ initialFiles }) => {
    const api = useApi();
    const { classes } = useStyles();
    const currentFolderId = useCurrentMediaFolder();
    const methods = useForm<UploadMediaFileFormFields>({ defaultValues: { files: initialFiles || [] } });
    const modal = useModal();
    const notify = useNotify();
    const t = useTranslation();
    const { workspace, workspaceSettings } = useWorkspace();

    const { isSubmitted, isSubmitting } = methods.formState;
    const [uploadFilesState, setUploadFileState] = useState<Record<string, FileUploadState>>({});
    const allowedFileExtensions = availableFileExtensions.filter(
        (extension) => !workspaceSettings?.mediaSettings?.disabledMediaAssetExtensions?.includes(extension)
    );
    const files = Object.values(methods.watch('files') || {});

    const onUploadError = useCallback((error: AxiosError, file: File) => {
        const errorMessage = handleResponseError(error);

        setUploadFileState((prevState) =>
            update(prevState, {
                [file.name]: {
                    error: { $set: errorMessage },
                    status: { $set: 'error' },
                },
            })
        );

        return undefined;
    }, []);

    const onUploadProgress = useCallback((event: ProgressEvent, file: File) => {
        const completed = Math.round((event.loaded * 100) / event.total);

        setUploadFileState((prevState) =>
            update(prevState, {
                [file.name]: {
                    progress: { $set: completed },
                    status: { $set: completed < 100 ? 'uploading' : 'processing' },
                },
            })
        );
    }, []);

    const checkMediaFilenameExists = useCallback(
        async (filename: string) => {
            const exists = await api.media
                .checkMediaFilenameExists({
                    workspaceid: workspace.id,
                    filename,
                    folderid: currentFolderId,
                })
                .then(() => true)
                // TODO: Check ONLY for 404 NOT FOUND else throw error / handle error in a different way to stop the upload
                .catch(() => false);

            if (exists) {
                return NiceModal.show<boolean, ConfirmMediaFileOverwritePromptProps>(ConfirmMediaFileOverwritePrompt, {
                    filename,
                });
            }

            return undefined;
        },
        [api.media, currentFolderId, workspace.id]
    );

    const createFileUploadPromise = useCallback(
        (file: File) =>
            limit(async () => {
                const overwrite = await checkMediaFilenameExists(file.name);
                if (overwrite === false) {
                    setUploadFileState((prevState) =>
                        update(prevState, {
                            [file.name]: {
                                status: { $set: 'cancelled' },
                            },
                        })
                    );

                    return undefined;
                }

                return api.media
                    .uploadMedia(
                        { file, folderId: currentFolderId, overwrite, workspaceid: workspace.id },
                        { onUploadProgress: (event) => onUploadProgress(event, file) }
                    )
                    .then((response) => {
                        setUploadFileState((prevState) =>
                            update(prevState, {
                                [file.name]: {
                                    status: { $set: 'done' },
                                },
                            })
                        );

                        return response;
                    })
                    .then((response) => mediaFileMapper(response.data))
                    .catch((error) => onUploadError(error, file));
            }),
        [api.media, checkMediaFilenameExists, currentFolderId, onUploadError, onUploadProgress, workspace.id]
    );

    const onDeleteFile = useCallback(
        (file: File) => {
            methods.setValue(
                'files',
                files.filter((f) => f !== file)
            );
        },
        [files, methods]
    );

    const onFilesChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const existingFiles = methods.getValues('files') || [];
            const filteredFiles = Array.from(event.currentTarget.files || []).filter(
                (f) => !existingFiles.find((e) => e.name === f.name)
            );

            methods.setValue('files', existingFiles.concat(filteredFiles));
            event.currentTarget.value = '';
        },
        [methods]
    );

    const onSubmit = useCallback(
        async (formValues: UploadMediaFileFormFields) => {
            setUploadFileState(
                formValues.files.reduce(
                    (state, file) => ({ ...state, [file.name]: { progress: 0, status: 'pending' } }),
                    {} as Record<string, FileUploadState>
                )
            );

            const response = await Promise.all(Array.from(formValues.files || []).map(createFileUploadPromise));
            if (response === undefined) {
                return;
            }

            const actualUploadedFiles = response.filter(
                (r): r is DiscriminatedEntity<LibraryModelsMedia> => r !== undefined
            );

            // Resolve only successful files
            modal.resolve(actualUploadedFiles);

            // Hide modal only when response doesn't contain undefined results
            if (response.every((r) => r !== undefined)) {
                const plural = actualUploadedFiles.length > 1;
                const params = [plural ? actualUploadedFiles.length.toString() : actualUploadedFiles[0].name];

                notify({ message: 'engage.notification.upload.file.success', params, plural, variant: 'success' });
                modal.hide();
            }
        },
        [createFileUploadPromise, modal, notify]
    );

    const renderFileDetails = useCallback(
        (file: File) => {
            const uploadState = uploadFilesState[file.name] || undefined;
            if (uploadState === undefined) {
                return (
                    <Stack direction="row" spacing={1}>
                        <Typography key="filetype" className={classes.subtitleText} variant="subtitle1">
                            {isVideo(file)
                                ? t.trans('engage.model.media.file.type.video')
                                : t.trans('engage.model.media.file.type.image')}
                        </Typography>
                        <Typography key="filesize" className={classes.subtitleText} variant="subtitle1">
                            {filesize(file.size).toString()}
                        </Typography>
                    </Stack>
                );
            }

            if (uploadState.error !== undefined) {
                return (
                    <Typography color="error" variant="subtitle1">
                        {uploadState.error}
                    </Typography>
                );
            }

            return (
                <LinearProgressWithLabel
                    color={getProgressColor(uploadState.status)}
                    statusText={t.trans(getStatusText(uploadState.status), [uploadState.progress.toString()])}
                    variant={['pending', 'processing'].includes(uploadState.status) ? 'indeterminate' : 'determinate'}
                    value={uploadState.progress}
                />
            );
        },
        [classes.subtitleText, t, uploadFilesState]
    );

    const renderFileRow = (file: File) => {
        const isDeleteDisabled = isSubmitted || isSubmitting || uploadFilesState[file.name]?.status === 'done';

        return (
            <ListItem className={classes.root} key={file.name} role="listitem">
                <span className={classes.thumbCell}>
                    <Thumbnail alt={file.name} size={70} source={URL.createObjectURL(file)} />
                </span>
                <span className={classes.details}>
                    <Typography noWrap title={file.name} variant="h4">
                        {file.name}
                    </Typography>
                    {renderFileDetails(file)}
                </span>
                <span>
                    <IconButton
                        data-testid="removeFile"
                        disabled={isDeleteDisabled}
                        onClick={() => onDeleteFile(file)}
                        size="small"
                    >
                        <Icon type="DELETE" size={16} color={isDeleteDisabled ? DARKGRAY : ORANGE} />
                    </IconButton>
                </span>
            </ListItem>
        );
    };

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { disabled: isSubmitting, onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: files.length === 0 || isSubmitted || isSubmitting,
                            label: 'engage.action.upload.file',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                preventClosing={isSubmitting}
                title={{
                    icon: <Icon type="ADD_MEDIA" />,
                    label: 'engage.modal.upload.file.title',
                }}
            >
                <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={classes.fileList}>
                        {files.length === 0 ? (
                            <Typography align="center">Please select files for upload to continue.</Typography>
                        ) : (
                            files.map((file) => renderFileRow(file))
                        )}
                    </div>
                    <input
                        accept={allowedFileExtensions.join(',')}
                        data-testid="uploadMedia"
                        disabled={isSubmitted || isSubmitting}
                        hidden
                        id="files"
                        multiple
                        onChange={onFilesChanged}
                        type="file"
                    />
                    <label htmlFor="files">
                        <Stack direction="row" spacing={1}>
                            <Button
                                disabled={isSubmitted || isSubmitting}
                                color="primary"
                                component="span"
                                variant="text"
                            >
                                {t.trans('common.action.select.file')}
                            </Button>
                            <Typography>
                                You can upload following file formats: {allowedFileExtensions.join(', ')}
                            </Typography>
                        </Stack>
                    </label>
                </form>
            </Modal>
        </FormProvider>
    );
});

export default UploadMediaFileForm;
