import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import { SelectInput, SelectOption } from '@zetadisplay/engage-components';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import { getDefaultLayout } from 'src/views/PlaylistSetupView';
import PlaylistSetupLayoutZone from 'src/views/PlaylistSetupView/Components/PlaylistSetupInitializeForm/PlaylistSetupLayoutSelect/PlaylistSetupLayoutZone';

const useStyles = makeStyles()((theme) => ({
    layoutOptions: {
        backgroundColor: theme.palette.background.contentSecondary,
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'center',
        padding: '20px 10px 10px',
        position: 'relative',
    },
    layoutOptionsLabel: {
        color: theme.palette.text.disabled,
        fontSize: 15,
        padding: '2px 14px',
        position: 'absolute',
        top: -14,
        left: -17,
        transform: 'scale(0.75)',
    },
}));

const PlaylistSetupLayoutSelect = () => {
    const { workspaceLayouts, workspaceSettings } = useWorkspace();
    const { setValue, watch } = useFormContext<PlaylistSetupFormValues>();
    const t = useTranslation();
    const { classes, cx } = useStyles();

    const layoutOptions: SelectOption[] =
        workspaceLayouts?.map((layout) => ({ id: layout.id, name: layout.name })) || [];

    const layoutId = watch('layoutId') || getDefaultLayout(workspaceLayouts, workspaceSettings?.defaultLayout)?.id;

    const handleLayoutChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            setValue('layoutId', event.target.value as number);
        },
        [setValue]
    );

    if (layoutOptions.length === 0) {
        return (
            <div>
                <Skeleton data-testid="skeleton" />
            </div>
        );
    }

    const selectedLayout = workspaceLayouts?.find((layout) => layout.id === layoutId);
    const zones = workspaceLayouts?.find((layout) => layout.id === layoutId)?.zones || [];

    return (
        <>
            <Grid item xs={12}>
                <SelectInput
                    defaultValue={layoutId}
                    fullWidth
                    label={t.trans('engage.playlist.details.layout')}
                    LabelProps={{
                        backgroundColor: '#2c3138',
                    }}
                    name="layoutId"
                    onChangeCallback={handleLayoutChange}
                    options={layoutOptions}
                    testId="layout-select"
                />
            </Grid>

            <Grid item xs={12}>
                <div className={cx(classes.layoutOptions)}>
                    <Typography className={cx(classes.layoutOptionsLabel)}>
                        {t.trans('engage.playlist.setup.layout.options')}
                    </Typography>

                    {selectedLayout && (
                        <Controller
                            render={({ field: { onChange, value } }) => (
                                <>
                                    {zones.map((zone) => (
                                        <PlaylistSetupLayoutZone
                                            key={zone.id}
                                            layout={selectedLayout}
                                            onChange={onChange}
                                            selected={value === zone.id}
                                            zone={zone}
                                        />
                                    ))}
                                </>
                            )}
                            name="layoutZoneId"
                        />
                    )}
                </div>
            </Grid>
        </>
    );
};

export default PlaylistSetupLayoutSelect;
