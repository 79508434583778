import React, { useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
    EndpointsRootLibraryModelsScheduleDetails,
    LibraryModelsMedia,
    LibraryModelsMediaActions,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { SplitButton, SplitButtonOption } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import DeleteMultipleItemsConfirmPrompt, {
    DeleteMultipleItemsConfirmPromptProps,
} from 'src/components/Modals/Library/Multiple/DeleteMultipleItemsConfirmPrompt';
import ScheduleMultipleMediaFilesModal, {
    ScheduleMultipleMediaModalProps,
} from 'src/components/Modals/Library/Multiple/ScheduleMultipleMediaFilesModal';
import MoveLibraryItemSidekick, {
    MoveLibraryItemsSidekickProps,
} from 'src/components/Sidekicks/Library/MoveLibraryItemSidekick';
import { LibraryItemsType } from 'src/views/LibraryView';
import { emitOnMediaFileDeleted } from 'src/views/LibraryView/Events/onMediaFileDeletedEvent';
import { emitOnMediaFileScheduled } from 'src/views/LibraryView/Events/onMediaFileScheduledEvent';
import { emitOnMediaFolderDeleted } from 'src/views/LibraryView/Events/onMediaFolderDeletedEvent';

const useStyles = makeStyles()(() => ({
    root: {
        alignSelf: 'center',
        display: 'inline-block',
        paddingLeft: 6,
    },
}));

type Props = {
    items?: LibraryItemsType[];
    selectAllItems: (items: LibraryItemsType[], selectAll: boolean) => void;
    selectedItems?: Record<string, LibraryItemsType>;
};

const LibraryViewBulkActions = ({ items = [], selectAllItems, selectedItems = {} }: Props) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const { hasRole } = useUserInformation();
    const selectedItemsArray = useMemo(() => Object.values(selectedItems), [selectedItems]);

    const shouldDisableOption = useCallback(
        (action: LibraryModelsMediaActions, roles: string[]) => {
            return (
                !hasRole(roles) ||
                selectedItemsArray.length === 0 ||
                selectedItemsArray.some((item) => !(item.actionFlags & action))
            );
        },
        [hasRole, selectedItemsArray]
    );

    const showBulkDeleteConfirmPrompt = useCallback(() => {
        NiceModal.show<LibraryItemsType[], DeleteMultipleItemsConfirmPromptProps>(DeleteMultipleItemsConfirmPrompt, {
            items: selectedItemsArray,
        }).then((response) =>
            response.forEach((item) =>
                isMediaFile(item) ? emitOnMediaFileDeleted(item) : emitOnMediaFolderDeleted(item)
            )
        );
    }, [selectedItemsArray]);

    const showMoveLibraryItemSidekick = useCallback(() => {
        NiceModal.show<LibraryItemsType[], MoveLibraryItemsSidekickProps>(MoveLibraryItemSidekick, {
            subjects: selectedItemsArray,
        }).then((response) =>
            response.forEach((item) =>
                isMediaFile(item) ? emitOnMediaFileDeleted(item) : emitOnMediaFolderDeleted(item)
            )
        );
    }, [selectedItemsArray]);

    const showScheduleMultipleMediaModal = useCallback(() => {
        NiceModal.show<EndpointsRootLibraryModelsScheduleDetails[], ScheduleMultipleMediaModalProps>(
            ScheduleMultipleMediaFilesModal,
            {
                items: selectedItemsArray.filter(isMediaFile),
            }
        ).then((response) =>
            selectedItemsArray
                .filter((item): item is DiscriminatedEntity<LibraryModelsMedia> => isMediaFile(item))
                .forEach((media) =>
                    emitOnMediaFileScheduled({
                        ...media,
                        scheduleIds: response.map((schedule) => schedule.id),
                    })
                )
        );
    }, [selectedItemsArray]);

    const splitButtonOptions = useMemo(
        (): SplitButtonOption[] => [
            {
                disabled: () => {
                    return shouldDisableOption(LibraryModelsMediaActions.Delete, [
                        'ENGAGE_LIBRARY_FOLDER_DELETE',
                        'ENGAGE_LIBRARY_CONTENT_DELETE',
                    ]);
                },
                key: 'delete',
                label: t.trans('common.action.delete'),
                onClick: showBulkDeleteConfirmPrompt,
            },
            {
                disabled: () => {
                    return shouldDisableOption(LibraryModelsMediaActions.Move, ['ENGAGE_LIBRARY_CONTENT_EDIT']);
                },
                key: 'move',
                label: t.trans('common.action.move'),
                onClick: showMoveLibraryItemSidekick,
            },
            {
                disabled: () => {
                    return shouldDisableOption(LibraryModelsMediaActions.Edit, ['ENGAGE_PLAYLIST_EDIT']);
                },
                key: 'schedule',
                label: t.trans('engage.action.schedule'),
                onClick: showScheduleMultipleMediaModal,
            },
        ],
        [
            t,
            showBulkDeleteConfirmPrompt,
            showMoveLibraryItemSidekick,
            showScheduleMultipleMediaModal,
            shouldDisableOption,
        ]
    );

    return (
        <div className={classes.root}>
            <SplitButton
                fillPrimaryAction={() => !!(selectedItemsArray.length && selectedItemsArray.length === items?.length)}
                fillSecondaryAction={() => selectedItemsArray.length !== 0}
                onPrimaryActionClick={() => selectAllItems(items, items.length !== selectedItemsArray.length)}
                options={splitButtonOptions}
                primaryActionText={t.trans('common.action.select_all')}
                size="small"
                variant="outlined"
            />
        </div>
    );
};

export default LibraryViewBulkActions;
