import React from 'react';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import ExpandedDetailsPlaylists from 'src/components/ExpandedDetails/ExpandedDetailsPlaylists';
import ExpandedDetailsScheduling from 'src/components/ExpandedDetails/ExpandedDetailsScheduling';
import useMediaRelations from 'src/components/Modals/Library/MediaFile/Hooks/useMediaRelations';
import useDefaultMediaSchedules from 'src/hooks/Library/useDefaultMediaSchedules';
import LibraryListMediaDetailsInformation from 'src/views/LibraryView/Components/LibraryViewList/LibraryListMediaDetails/LibraryListMediaDetailsInformation';

import useTemplate from '../../Hooks/useTemplate';

const useStyles = makeStyles()(() => ({
    root: {
        padding: '0 36px',
    },
}));

type LibraryListMediaDetailsProps = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
};

const LibraryListMediaDetails = ({ item }: LibraryListMediaDetailsProps) => {
    const scheduling = useDefaultMediaSchedules(item, item.scheduleIds?.length !== 0);
    const details = useMediaRelations(item.id);
    const { classes } = useStyles();

    // TODO: We need to most likely refactor backend to expose template object in media object or refactor media object to include template live property
    const template = useTemplate(item.templateId);

    return (
        <div className={classes.root}>
            <LibraryListMediaDetailsInformation item={item} template={template.result} />
            <ExpandedDetailsScheduling schedules={scheduling.result || []} />
            <ExpandedDetailsPlaylists
                playlists={
                    details.result?.campaigns
                        ?.filter((playlist, index, self) => {
                            return index === self.findIndex((p) => p.id === playlist.id);
                        })
                        .sort((left, right) => left.name.localeCompare(right.name)) || []
                }
            />
        </div>
    );
};

export default LibraryListMediaDetails;
