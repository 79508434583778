import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';

import ConfirmPrompt, { ConfirmPromptActions } from 'src/components/Modals/ConfirmPrompt';
import usePendingPromise from 'src/hooks/usePendingPromise';

export type DeleteTemplatePromptProps = {
    template: DiscriminatedEntity<LibraryModelsTemplateRelations>;
} & NiceModalHocProps;

const DeleteMediaFolderPrompt = NiceModal.create<DeleteTemplatePromptProps>(({ template }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        async () => {
            return api.templates
                .deleteTemplate({
                    templateid: template.id,
                    workspaceid: workspace.id,
                })
                .then(() => template);
        },
        { success: { message: 'engage.notification.delete.template.success', params: [template.name] } },
        createButtonClickEvent('Editing option', 'Delete')
    );

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.delete.content', [template.name])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
            title={{ label: 'engage.modal.delete.title', labelParams: [template.name] }}
            // TODO: Update label to 'common.validation.validate.action' after R18 upgrade
            validate={{ value: ConfirmPromptActions.DELETE, label: 'Action confirmation' }}
        />
    );
});

export default DeleteMediaFolderPrompt;
