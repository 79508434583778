import { useCallback } from 'react';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { pushToDataLayer } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { DataLayerObject } from '@zetadisplay/zeta-ui-components/utils/data-layer/push-to-data-layer';
import { AxiosError } from 'axios';
import { SnackbarKey } from 'notistack';
import hash from 'object-hash';

import useNotify, { ShowNotificationParams } from 'src/hooks/useNotify';

export type MessageObject = Pick<ShowNotificationParams, 'message' | 'params' | 'plural'>;
export type PendingPromiseMessages = {
    pending?: MessageObject | string;
    success?: MessageObject | string;
};
export type PendingPromiseMessageType = keyof PendingPromiseMessages;

export type MessageConstructor = (
    messageType: PendingPromiseMessageType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
) => MessageObject | string | undefined;

export type DataLayerEventsConstructor = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
) => DataLayerObject[];

const createNotificationObject = (message: MessageObject | string): MessageObject => {
    if (typeof message === 'string') {
        return { message };
    }

    return message;
};

const generateMessageKey = (messages?: PendingPromiseMessages): SnackbarKey => {
    if (messages) {
        return hash(messages);
    }

    return new Date().toISOString();
};

const getMessage = (
    messageType: keyof PendingPromiseMessages,
    messages?: PendingPromiseMessages | MessageConstructor,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
) => {
    if (messages === undefined) {
        return undefined;
    }

    return typeof messages === 'function' ? messages(messageType, ...args) : messages[messageType];
};

const getEvents = (
    dataLayerEvents?: DataLayerObject | DataLayerObject[] | DataLayerEventsConstructor,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
) => {
    if (typeof dataLayerEvents === 'function') {
        return dataLayerEvents(...args);
    }

    const events = Array.isArray(dataLayerEvents) ? dataLayerEvents : [dataLayerEvents];
    return events.filter((event): event is DataLayerObject => event !== undefined);
};

/**
 * @deprecated This function is already exported to engage-components. Use it from there.
 */
const usePendingPromise = <ReturnValue>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise: Promise<ReturnValue> | ((...args: any[]) => Promise<ReturnValue>),
    messages?: PendingPromiseMessages | MessageConstructor,
    dataLayerEvents?: DataLayerObject | DataLayerObject[] | DataLayerEventsConstructor
) => {
    const notify = useNotify();

    return useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (...args: any[]) => {
            const key = generateMessageKey();
            const pendingMessage = getMessage('pending', messages, ...args);
            if (pendingMessage) {
                notify({
                    ...createNotificationObject(pendingMessage),
                    key,
                    persist: true,
                    variant: 'info',
                });
            }

            try {
                const awaitable = typeof promise === 'function' ? promise(...args) : promise;
                const response = await awaitable;
                const successMessage = getMessage('success', messages, ...args, response);

                // Notify on successful promises when we have success message defined
                if (successMessage) {
                    notify({ ...createNotificationObject(successMessage), key, variant: 'success' });
                }

                // Notify data layer with event on successful promise when it is defined
                const events = getEvents(dataLayerEvents, ...args, response);
                for (const event of events) {
                    pushToDataLayer(event);
                }

                return response;
            } catch (e) {
                notify({ key, remove: true });

                if (!(e instanceof AxiosError)) {
                    throw e;
                }

                notify({ message: handleResponseError(e), variant: 'error' });
                return undefined;
            }
        },
        [messages, notify, promise, dataLayerEvents]
    );
};

export default usePendingPromise;
