import { PublishingModelsCampaignCampaignStatus } from '@zetadisplay/engage-api-client';

export const statuses = [
    {
        key: PublishingModelsCampaignCampaignStatus.New,
        label: 'engage.playlist.status.disabled',
        literal: 'New',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Promoted,
        label: 'engage.playlist.status.promoted',
        literal: 'Promoted',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Published,
        label: 'engage.playlist.status.published',
        literal: 'Published',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Expired,
        label: 'engage.playlist.status.expired',
        literal: 'Expired',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Reused,
        label: 'engage.playlist.status.reused',
        literal: 'Reused',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Rejected,
        label: 'engage.playlist.status.rejected',
        literal: 'Rejected',
    },
    {
        key: PublishingModelsCampaignCampaignStatus.Deleted,
        label: 'engage.playlist.status.deleted',
        literal: 'Deleted',
    },
];

export const PlaylistSetupFormKey = {
    CampaignMedia: 'campaignMedia',
    CurrentFolderId: 'currentFolderId',
    Groups: 'groups',
    Name: 'name',
    Schedules: 'schedules',
    LayoutZoneId: 'layoutZoneId',
};
