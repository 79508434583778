import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';
import { PlaylistMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import update from 'immutability-helper';

const useResolvePlaylistMediaCollection = (
    playlistMediaCollection: Partial<PlaylistMediaType>[] | undefined
): [Partial<PlaylistMediaType>[], boolean] => {
    const api = useApi();
    const { workspace } = useWorkspace();

    // State cache for media that is being resolved
    const [resolvedMedia, setResolvedMedia] = useState(playlistMediaCollection || []);

    const { loading: isResolving } = useAsyncAbortable(
        async (signal) => {
            // Reset already resolved media
            setResolvedMedia(playlistMediaCollection || []);

            // TODO: Missing error handling
            const promises = playlistMediaCollection?.map((playlistMedia) => {
                if (playlistMedia.mediaId === undefined) {
                    return undefined;
                }

                return api.media
                    .getMediaById(
                        {
                            mediaid: playlistMedia.mediaId,
                            workspaceid: workspace.id,
                        },
                        { signal }
                    )
                    .then((response) =>
                        setResolvedMedia((prevState) => {
                            const existingIndex = prevState.findIndex((item) => item.id === playlistMedia.id);
                            if (existingIndex === -1) {
                                return update(prevState, {
                                    $push: [{ ...playlistMedia, model: mediaFileMapper(response.data) }],
                                });
                            }

                            return update(prevState, {
                                [existingIndex]: {
                                    model: {
                                        $set: mediaFileMapper(response.data),
                                    },
                                },
                            });
                        })
                    );
            });

            if (promises === undefined || promises.length === 0) {
                return;
            }

            await Promise.all(promises);
        },
        [api, playlistMediaCollection, workspace.id]
    );

    return [resolvedMedia, isResolving];
};

export default useResolvePlaylistMediaCollection;
