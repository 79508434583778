import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Drawer } from '@mui/material';
import { Theme } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import SidekickActions, { SidekickActionsProps } from 'src/modules/Sidekick/Components/SidekickActions';
import SidekickContent from 'src/modules/Sidekick/Components/SidekickContent';
import SidekickHeader, { SidekickHeaderProps } from 'src/modules/Sidekick/Components/SidekickHeader';

const useStyles = makeStyles<{ drawerWidth: number }>()((_, params) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        height: '100%', // 100vh
    },
    drawer: {
        flexShrink: 0,
        width: params.drawerWidth,
    },
    drawerPaper: {
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        padding: '20px',
        width: params.drawerWidth,
    },
}));

export type SidekickProps = React.PropsWithChildren<{
    ActionProps?: SidekickActionsProps;
    dark?: boolean;
    drawerWidth?: number;
    elevation?: number;
    header?: Omit<SidekickHeaderProps, 'onClose'>;
}>;

const Sidekick = ({ ActionProps, children, dark = true, drawerWidth = 340, elevation = 3, header }: SidekickProps) => {
    const { classes } = useStyles({ drawerWidth });
    const modal = useModal();

    return (
        <Theme dark={dark} nested>
            <Drawer
                anchor="right"
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                onClose={() => modal.hide()}
                open={modal.visible}
                PaperProps={{ elevation }}
                SlideProps={{ onExited: () => modal.remove() }}
                variant="temporary"
            >
                <div className={classes.container}>
                    <SidekickHeader {...header} onClose={() => modal.hide()} />
                    <SidekickContent>{children}</SidekickContent>
                    {ActionProps && <SidekickActions {...ActionProps} />}
                </div>
            </Drawer>
        </Theme>
    );
};

export default Sidekick;
