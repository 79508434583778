import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { AspectRatio } from '@styled-icons/material-outlined';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import usePlaylistLayout from 'src/views/PlaylistView/Hooks/usePlaylistLayout';

const { ERROR, SUCCESS, WARNING } = themeOptions.colors;

type Props = {
    layoutZoneId?: number;
    media: DiscriminatedEntity<LibraryModelsMedia>;
};

const RATIO_VALID = 'valid';
const RATIO_UNKNOWN = 'unknown';
const RATIO_INVALID = 'invalid';

const getAspectRatio = (width: number | undefined | null = 0, height: number | undefined | null = 0) => {
    if (width && height) {
        return width / height;
    }

    return 0;
};

const getRatioColor = (ratio: string) => {
    if (ratio === RATIO_VALID) {
        return SUCCESS;
    }

    if (ratio === RATIO_INVALID) {
        return ERROR;
    }

    return WARNING;
};

const PlaylistSetupContentListItemAspectRatio = ({ media, layoutZoneId }: Props) => {
    const { layout, zone } = usePlaylistLayout(layoutZoneId);
    const t = useTranslation();

    const layoutAspectRatio = getAspectRatio(
        (layout && zone && layout.width * zone.width) || 0,
        (layout && zone && layout.height * zone.height) || 0
    );
    const imageAspectRatio = getAspectRatio(media?.width, media?.height);

    const ratio = useMemo(() => {
        if (layoutAspectRatio === imageAspectRatio) {
            return RATIO_VALID;
        }

        if (layoutAspectRatio > 0 && imageAspectRatio > 0 && layoutAspectRatio !== imageAspectRatio) {
            return RATIO_INVALID;
        }

        return RATIO_UNKNOWN;
    }, [imageAspectRatio, layoutAspectRatio]);

    const title = useMemo(() => {
        if (ratio === RATIO_VALID) {
            return t.trans('engage.playlist.setup.content.aspect_ratio.valid');
        }

        if (ratio === RATIO_INVALID) {
            return t.trans('engage.playlist.setup.content.aspect_ratio.invalid');
        }

        return t.trans('engage.playlist.setup.content.aspect_ratio.unknown');
    }, [ratio, t]);

    return (
        <Tooltip data-testid="aspect-ratio" title={title}>
            <AspectRatio size={14} color={getRatioColor(ratio)} />
        </Tooltip>
    );
};

export default PlaylistSetupContentListItemAspectRatio;
