import { PublishingCampaignSchedulingCreateCampaignModel } from '@zetadisplay/engage-api-client';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import pLimit from 'p-limit';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import createPlaylistPayload from 'src/modules/playlist/utils/createPlaylistPayload';

const limit = pLimit(1);

const playlistCreateOrUpdatePromiseFactory = ({
    api,
    playlistId,
    workspaceid,
}: {
    api: ApiInterface;
    playlistId?: number;
    workspaceid: string;
}) => {
    return async (formValues: PlaylistSetupFormValues) => {
        const modifiedMediaPromises = Array.from(formValues.modifiedMedia, ([key, value]) => {
            return limit(() => {
                return api.media
                    .updateMedia({
                        body: {
                            duration: value.data?.duration || value.object.duration,
                            name: value.data?.name || value.object.name,
                            url: value.data?.url || value.object.url,
                            urlInternal: value.data?.urlInternal || value.object.urlInternal,
                            folderId: value.object.folderId,
                            type: value.object.type,
                        },
                        mediaid: key,
                        workspaceid,
                    })
                    .then((response) => response.data);
            });
        });

        if (modifiedMediaPromises.length > 0) {
            await Promise.all(modifiedMediaPromises);
        }

        // Create new playlist
        if (playlistId === undefined) {
            return api.playlists.createCampaign({
                body: createPlaylistPayload(formValues, workspaceid) as PublishingCampaignSchedulingCreateCampaignModel,
                workspaceid,
            });
        }

        return api.playlists.editCampaign({
            body: createPlaylistPayload(formValues) as PublishingCampaignSchedulingCreateCampaignModel,
            campaignid: playlistId,
            workspaceid,
        });
    };
};

export default playlistCreateOrUpdatePromiseFactory;
