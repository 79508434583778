import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@mui/material';
import { Theme } from '@zetadisplay/zeta-ui-components';

import ModalActions, { ModalActionsProps } from 'src/modules/Modal/Components/ModalActions';
import ModalContent, { ModalContentProps } from 'src/modules/Modal/Components/ModalContent';
import ModalTitle, { ModalTitleProps } from 'src/modules/Modal/Components/ModalTitle';
import ModalTransition from 'src/modules/Modal/Components/ModalTransition';

export type ModalProps = React.PropsWithChildren<{
    actions?: ModalActionsProps;
    content?: ModalContentProps;
    dark?: boolean;
    fullWidth?: boolean;
    isDisabledClose?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    title?: ModalTitleProps;
}>;

const Modal = ({
    actions,
    children,
    content,
    dark = false,
    fullWidth = false,
    isDisabledClose = false,
    maxWidth = 'xl',
    title,
}: ModalProps) => {
    const modal = useModal();

    const handleOnClose = () => {
        if (!isDisabledClose) {
            modal.hide();
        }
    };

    return (
        <Theme dark={dark} nested>
            <Dialog
                fullWidth={fullWidth}
                onClose={handleOnClose}
                open={modal.visible}
                maxWidth={maxWidth}
                TransitionComponent={ModalTransition}
                TransitionProps={{
                    onExited: () => modal.remove(),
                }}
            >
                <ModalTitle icon={title?.icon} label={title?.label} labelParams={title?.labelParams} />
                <ModalContent type={content?.type}>{children}</ModalContent>
                <ModalActions buttons={actions?.buttons} />
            </Dialog>
        </Theme>
    );
};

export default Modal;
