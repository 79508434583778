import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
    EndpointsRootLibraryModelsScheduleDetails,
    LibraryModelsFolder,
    LibraryModelsMedia,
    LibraryModelsMediaActions,
    LibraryModelsMediaTypes,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile } from '@zetadisplay/engage-components/models';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { createTemplateUrl } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';

import DeleteMediaFilePrompt, {
    DeleteMediaFilePromptProps,
} from 'src/components/Modals/Library/MediaFile/DeleteMediaFilePrompt';
import ScheduleMediaFileForm, {
    ScheduleMediaFileFormProps,
} from 'src/components/Modals/Library/MediaFile/ScheduleMediaFileForm';
import UpdateMediaFileForm, {
    UpdateMediaFileFormProps,
} from 'src/components/Modals/Library/MediaFile/UpdateMediaFileForm';
import MoveLibraryItemSidekick, {
    MoveLibraryItemsSidekickProps,
} from 'src/components/Sidekicks/Library/MoveLibraryItemSidekick';
import { emitOnMediaFileDeleted } from 'src/views/LibraryView/Events/onMediaFileDeletedEvent';
import { emitOnMediaFileEdited } from 'src/views/LibraryView/Events/onMediaFileEditedEvent';
import { emitOnMediaFileScheduled } from 'src/views/LibraryView/Events/onMediaFileScheduledEvent';
import useIsDisabledLibraryAction from 'src/views/LibraryView/Hooks/useIsDisabledLibraryAction';

const isTemplate = (media: DiscriminatedEntity<LibraryModelsFolder> | DiscriminatedEntity<LibraryModelsMedia>) => {
    return (
        isMediaFile(media) &&
        [LibraryModelsMediaTypes.TemplateHtml, LibraryModelsMediaTypes.TemplateZetaCast].includes(media.type)
    );
};

const useLibraryListMediaActions = (): ActionGroup<DiscriminatedEntity<LibraryModelsMedia>>[] => {
    const shouldDisableAction = useIsDisabledLibraryAction();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const onRemoveMediaFile = useCallback(
        (
            originalMedia: DiscriminatedEntity<LibraryModelsMedia>,
            response:
                | DiscriminatedEntity<LibraryModelsMedia>
                | (PublishingCampaignSchedulingCampaignInfoWithMediaSchedules | undefined)[]
        ) => {
            if (Array.isArray(response)) {
                return emitOnMediaFileEdited({
                    ...originalMedia,
                    campaignIds: originalMedia.campaignIds?.filter((playlistId) => {
                        return response.find((playlist) => playlist?.id === playlistId) === undefined;
                    }),
                });
            }

            return emitOnMediaFileDeleted(response);
        },
        []
    );

    const handleOpenInContentEditor = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        window.open(createTemplateUrl(media, workspace), '_blank');
    };

    const handleMoveMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>[], MoveLibraryItemsSidekickProps>(
            MoveLibraryItemSidekick,
            { subjects: [media] }
        ).then((response) => response.forEach((m) => emitOnMediaFileDeleted(m)));
    };

    const handleEditMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>, UpdateMediaFileFormProps>(UpdateMediaFileForm, {
            media,
        }).then(emitOnMediaFileEdited);
    };

    const handleScheduleMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<EndpointsRootLibraryModelsScheduleDetails[], ScheduleMediaFileFormProps>(ScheduleMediaFileForm, {
            media,
        }).then((response) =>
            emitOnMediaFileScheduled({ ...media, scheduleIds: response.map((schedule) => schedule.id) })
        );
    };

    const handleDeleteMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<
            | DiscriminatedEntity<LibraryModelsMedia>
            | (PublishingCampaignSchedulingCampaignInfoWithMediaSchedules | undefined)[],
            DeleteMediaFilePromptProps
        >(DeleteMediaFilePrompt, { media }).then((response) => onRemoveMediaFile(media, response));
    };

    const isDisabledForOpenInContentEditor = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        return (
            shouldDisableAction(media, LibraryModelsMediaActions.Edit, ['ENGAGE_LIBRARY_TEMPLATE_VIEW']) ||
            !media.templateId
        );
    };

    const isDisabledForMoveMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        return shouldDisableAction(media, LibraryModelsMediaActions.Move, [
            'ENGAGE_LIBRARY_VIEW',
            'ENGAGE_LIBRARY_CONTENT_EDIT',
        ]);
    };

    const isDisabledForEditMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        return shouldDisableAction(media, LibraryModelsMediaActions.Edit, [
            'ENGAGE_LIBRARY_VIEW',
            'ENGAGE_LIBRARY_CONTENT_EDIT',
        ]);
    };

    const isDisabledForScheduleMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        return shouldDisableAction(media, LibraryModelsMediaActions.Edit, ['ENGAGE_PLAYLIST_EDIT']);
    };

    const isDisabledForDeleteMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
        return shouldDisableAction(media, LibraryModelsMediaActions.Delete, [
            'ENGAGE_LIBRARY_VIEW',
            'ENGAGE_LIBRARY_CONTENT_DELETE',
        ]);
    };

    return [
        {
            actions: [
                {
                    handler: handleOpenInContentEditor,
                    isDisabled: isDisabledForOpenInContentEditor,
                    isVisible: isTemplate,
                    label: t.trans('engage.templates.content-editor.open'),
                    name: 'open-in-editor',
                },
                {
                    handler: handleMoveMediaFile,
                    isDisabled: isDisabledForMoveMediaFile,
                    isVisible: isMediaFile,
                    label: t.trans('common.action.move'),
                    name: 'move',
                },
                {
                    handler: handleEditMediaFile,
                    isDisabled: isDisabledForEditMediaFile,
                    isVisible: (media) => !isTemplate(media),
                    label: t.trans('common.action.edit'),
                    name: 'edit',
                },
                {
                    handler: handleScheduleMediaFile,
                    isDisabled: isDisabledForScheduleMediaFile,
                    isVisible: isMediaFile,
                    label: t.trans('engage.action.schedule'),
                    name: 'schedule',
                },
                {
                    handler: handleDeleteMediaFile,
                    isDisabled: isDisabledForDeleteMediaFile,
                    isVisible: isMediaFile,
                    label: t.trans('common.action.delete'),
                    name: 'delete',
                },
            ],
            name: 'library-list-media-actions',
        },
    ];
};

export default useLibraryListMediaActions;
