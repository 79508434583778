import {
    PublishingCampaignSchedulingCampaignMediaSchedule,
    PublishingCampaignSchedulingSchedule,
} from '@zetadisplay/engage-api-client';
import { createScheduleFromEngageSchedule } from '@zetadisplay/engage-components/modules/playlist';
import { Schedule } from '@zetadisplay/zeta-ui-components';

const createSchedulesFromPlaylist = (
    schedules?: (PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignMediaSchedule)[] | null
): Schedule[] => {
    const convertedSchedules =
        schedules && schedules?.length > 0 ? schedules : [{ id: 0, endDate: null, startDate: null }];

    return convertedSchedules.map(createScheduleFromEngageSchedule);
};

export default createSchedulesFromPlaylist;
