import {
    PublishingCampaignSchedulingCampaignMediaSchedule,
    PublishingCampaignSchedulingCampaignSchedule,
    PublishingCampaignSchedulingCampaignSegment,
    PublishingCampaignSchedulingSchedule,
} from '@zetadisplay/engage-api-client';
import convertDateToUTC from '@zetadisplay/engage-components/utils/scheduling/convert-date-to-utc';
import createBinaryValueFromWeekdays from '@zetadisplay/engage-components/utils/scheduling/create-binary-value-from-weekdays';
import getDateFromSchedule from '@zetadisplay/engage-components/utils/scheduling/get-date-from-schedule';
import { Schedule } from '@zetadisplay/zeta-ui-components';

const findSegmentId = (
    schedule: Schedule,
    schedules?: (PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignMediaSchedule)[] | null
) => {
    if (schedule.id === undefined || schedules === undefined || schedules === null) {
        return 0;
    }

    return schedules.find((originalSchedule) => originalSchedule.id === schedule.id)?.segment?.id || 0;
};

const createSegmentFromSchedule = (
    schedule: Schedule,
    schedules?: (PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignMediaSchedule)[] | null
): PublishingCampaignSchedulingCampaignSegment => {
    const endTime = getDateFromSchedule(schedule, 'endTime');
    const startTime = getDateFromSchedule(schedule, 'startTime');

    return {
        id: findSegmentId(schedule, schedules),
        end:
            (endTime &&
                `${String(endTime.getHours()).padStart(2, '0')}:${String(endTime.getMinutes()).padStart(2, '0')}`) ||
            null,
        daysOfWeek: createBinaryValueFromWeekdays(schedule),
        start:
            (startTime &&
                `${String(startTime.getHours()).padStart(2, '0')}:${String(startTime.getMinutes()).padStart(
                    2,
                    '0'
                )}`) ||
            null,
    };
};

const convertScheduleToEngage = (
    schedule: Schedule,
    playlistSchedules?: PublishingCampaignSchedulingSchedule[] | null | undefined
): PublishingCampaignSchedulingSchedule | PublishingCampaignSchedulingCampaignSchedule => {
    return {
        id: (schedule?.id && (schedule.id as number)) || 0,
        endDate: convertDateToUTC(getDateFromSchedule(schedule, 'endDate'))?.toISOString(),
        segment: schedule.mode === 2 ? createSegmentFromSchedule(schedule, playlistSchedules) : undefined,
        startDate: convertDateToUTC(getDateFromSchedule(schedule, 'startDate'))?.toISOString(),
    };
};

export default convertScheduleToEngage;
