import React from 'react';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, ButtonKind } from '@zetadisplay/zeta-ui-components';

export type SidekickActionsProps = {
    busy?: boolean;
    disabled?: boolean;
    kind?: ButtonKind;
    label: string;
    onClick?: () => void;
};

const defaultSubmitButton: SidekickActionsProps = {
    kind: 'primaryAction',
    label: 'common.action.save',
};

export const createDefaultButton = (props?: Partial<SidekickActionsProps>) => ({ ...defaultSubmitButton, ...props });

const SidekickActions = ({ busy, disabled, kind, label, onClick }: SidekickActionsProps) => {
    const t = useTranslation();

    return (
        <Button
            busy={busy}
            data-testid="sidekick-action"
            disabled={disabled}
            fullWidth
            key={label}
            kind={kind}
            label={t.trans(label)}
            onClick={onClick}
        />
    );
};

export default SidekickActions;
