import React, { useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import { PublishingModelsCampaignCampaignBasic } from '@zetadisplay/engage-api-client';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    playlist: {
        display: 'flex',
        flex: 1,
    },
    root: {
        paddingTop: '5px',
    },
    title: {
        marginBottom: 2,
    },
}));

type ExpandedDetailsPlaylistsProps = {
    playlists: PublishingModelsCampaignCampaignBasic[];
};

const ExpandedDetailsPlaylists: React.FC<ExpandedDetailsPlaylistsProps> = ({ playlists }) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();
    const location = useLocation();
    const { workspace } = useWorkspace();

    const renderPlaylists = useCallback(() => {
        if (playlists.length === 0) {
            return <Typography>{trans('engage.players.details.playlist.no_playlists')}</Typography>;
        }

        return playlists.map((playlist) => (
            <Box className={classes.playlist} key={playlist.id}>
                <Icon type="PLAYLIST" />
                {playlist.workspaceId === workspace.id ? (
                    <Link
                        data-testid="playlist-edit-link"
                        to={`/playlists/edit?playlistId=${playlist.id}&returnUrl=${location.pathname}`}
                        component={RouterLink}
                    >
                        {playlist.name}
                    </Link>
                ) : (
                    playlist.name
                )}
            </Box>
        ));
    }, [classes.playlist, location.pathname, playlists, trans, workspace.id]);

    return (
        <Box className={classes.root}>
            <Typography className={classes.title} variant="h5">
                {trans('engage.library.media.details.usage.title')}
            </Typography>
            {renderPlaylists()}
        </Box>
    );
};

export default ExpandedDetailsPlaylists;
