import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Grid } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import PlaylistLayoutInformation from 'src/views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistLayoutInformation';
import PlaylistSetupContentList from 'src/views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistSetupContentList';

const useStyles = makeStyles()(() => ({
    root: {
        height: '100%',
        paddingTop: 20,
        width: '100%',
    },
}));

const PlaylistSetupMainForm = () => {
    const { classes, cx } = useStyles();
    const { watch } = useFormContext<PlaylistSetupFormValues>();
    const { errors, isLoading } = useFormState<PlaylistSetupFormValues>();

    const layoutZoneId = watch('layoutZoneId');
    const modifiedMedia = watch('modifiedMedia');
    const playlistMediaCollection = watch('playlistMediaCollection');

    if (isLoading) {
        return <ComponentLoader />;
    }

    return (
        <div className={cx(classes.root)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        name="name"
                        defaultLabel="common.form.input.name"
                        error={errors?.name}
                        rules={{
                            required: 'common.validation.required',
                            validate: {
                                maxLength: (value: string) =>
                                    value.length <= 50 ? true : 'common.validation.maxLength',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PlaylistLayoutInformation layoutZoneId={layoutZoneId} />
                </Grid>
                <Grid item xs={12}>
                    <PlaylistSetupContentList
                        layoutZoneId={layoutZoneId}
                        modifiedMedia={modifiedMedia}
                        playlistMediaCollection={playlistMediaCollection}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default PlaylistSetupMainForm;
