import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { usePaginatedRequests, usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { getOrderByProperties, useSorting } from '@zetadisplay/engage-components/modules/options';
import {
    hasSearchEventScope,
    SearchEventScope,
    useOnSearchListener,
} from '@zetadisplay/engage-components/modules/search/events';
import { getQueryFilter } from '@zetadisplay/engage-components/modules/search/query-filters';
import { createKeywordFilterQuery } from '@zetadisplay/engage-components/modules/search/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { SearchFilters, SearchState } from '@zetadisplay/zeta-ui-components';
import objectHash from 'object-hash';

import { useOnTemplateCreatedListener } from 'src/components/Modals/Library/TemplateLibrary/Events/onTemplateCreatedEvent';
import { useOnTemplateDeletedListener } from 'src/components/Modals/Library/TemplateLibrary/Events/onTemplateDeletedEvent';

type UseTemplatesReturnType = {
    getNextResultPage: () => void;
    data: DiscriminatedEntity<LibraryModelsTemplateRelations>[];
    isLoading: boolean;
    total: number | undefined;
};

const useTemplates = (pageSize?: number): UseTemplatesReturnType => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace } = useWorkspace();

    const [searchFilters, setSearchFilters] = useState<SearchState<SearchFilters>>({});
    const filter = getQueryFilter(createKeywordFilterQuery(searchFilters, ['name']));
    const filterHash = objectHash(searchFilters);

    const { $top, $skip, getNextResultPage } = usePaginatedRequests('templates', filterHash, undefined, pageSize);
    const { results, removeResult, setResult, setResults, total } = usePaginatedResults<LibraryModelsTemplateRelations>(
        'templates',
        EntityDiscriminators.Template,
        filterHash,
        getOrderByProperties('Template', sorting.sort)
    );

    const templates = useAsyncAbortable(
        async (signal) => {
            return api.templates
                .getTemplates(
                    {
                        workspaceid: workspace.id,
                        $filter: filter,
                        $top,
                        $skip,
                    },
                    { signal }
                )
                .then((response) => setResults(response.data.items, response.data.total));
        },
        [$skip, $top, api.templates, filter, setResults, workspace.id],
        {
            setLoading: (state) => {
                return { ...state, result: results.current && [...results.current.values()], loading: true };
            },
        }
    );

    // Subscribe to events to manage states
    useOnSearchListener((event) => {
        if (hasSearchEventScope(event, SearchEventScope.TEMPLATE)) {
            setSearchFilters(event.value);
        }
    });

    useOnTemplateCreatedListener(setResult);
    useOnTemplateDeletedListener(removeResult);

    return {
        getNextResultPage,
        data: (results.current && [...results.current.values()]) || [],
        isLoading: templates.loading,
        total: total.current,
    };
};

export default useTemplates;
