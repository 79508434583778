import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublishingModelsCampaignCampaignStatus, SharedModelsLayoutRelations } from '@zetadisplay/engage-api-client';
import { usePreserveFormState } from '@zetadisplay/engage-components/hooks';
import { ThreeColumnView } from '@zetadisplay/engage-components/layouts/components';
import { ApiInterface, useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

import createPlaylistFormValues, { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import fetchPlaylistFormData from 'src/modules/playlist/utils/fetchPlaylistFormData';
import withDarkLayout from 'src/utils/Layout/withDarkLayout';
import PlaylistSetupActions from 'src/views/PlaylistSetupView/Components/PlaylistSetupActions';
import PlaylistSetupInitializeForm from 'src/views/PlaylistSetupView/Components/PlaylistSetupInitializeForm';
import PlaylistSetupLibrary from 'src/views/PlaylistSetupView/Components/PlaylistSetupLibrary';
import PlaylistSetupMainForm from 'src/views/PlaylistSetupView/Components/PlaylistSetupMainForm';
import PlaylistSetupSettingsForm from 'src/views/PlaylistSetupView/Components/PlaylistSetupSettingsForm';
import usePlaylistSetupEvents from 'src/views/PlaylistSetupView/Hooks/usePlaylistSetupEvents';

const STEP_INITIALIZE_NEW = 1;
const STEP_SETTINGS = 2;

export const getDefaultLayout = (layouts?: SharedModelsLayoutRelations[], defaultLayoutId?: number | null) => {
    if (layouts === undefined) {
        return undefined;
    }

    const defaultLayout = layouts.find((layout) => layout.id === defaultLayoutId);
    if (defaultLayout !== undefined) {
        return defaultLayout;
    }

    return layouts[0];
};
const getDefaultValues = ({
    api,
    defaultLayoutId,
    folderId,
    playlistId,
    workspaceId,
    workspaceLayouts,
}: {
    api: ApiInterface;
    defaultLayoutId?: number | null;
    folderId?: string;
    playlistId?: number;
    workspaceId: string;
    workspaceLayouts?: SharedModelsLayoutRelations[];
}) => {
    if (playlistId) {
        return fetchPlaylistFormData(playlistId, workspaceId, api);
    }

    return createPlaylistFormValues({
        folderId,
        status: PublishingModelsCampaignCampaignStatus.New,
        layoutZoneId: getDefaultLayout(workspaceLayouts, defaultLayoutId)?.zones[0].id,
    });
};

const PlaylistSetupView = () => {
    const location = useLocation();
    const api = useApi();
    const navigate = useNavigate();
    const { workspace, workspaceLayouts, workspaceSettings } = useWorkspace();

    // Extract current folder id from location state
    const state = location.state as { currentFolderId?: string };
    const playlistId = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('playlistId')) {
            return Number(searchParams.get('playlistId'));
        }

        return undefined;
    }, [location.search]);

    const [step, setStep] = useState(playlistId ? STEP_SETTINGS : STEP_INITIALIZE_NEW);

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<PlaylistSetupFormValues>(
        'playlistSetup',
        playlistId || 'new'
    );

    const methods = useForm({
        defaultValues: async () => {
            const defaultValues = await getDefaultValues({
                api,
                defaultLayoutId: workspaceSettings?.defaultLayout,
                folderId: state?.currentFolderId,
                playlistId,
                workspaceId: workspace.id,
                workspaceLayouts,
            });

            return { ...defaultValues, ...preservedValues };
        },
    });

    usePlaylistSetupEvents(methods.getValues, methods.setValue, methods.watch);

    const onCancelCallback = useCallback(() => {
        defuse().then(() => {
            navigate(-1);
        });
    }, [defuse, navigate]);

    useEffect(() => {
        const subscription = methods.watch((values) => setPreservedValues(values as PlaylistSetupFormValues));

        return () => subscription?.unsubscribe();
    }, [methods, setPreservedValues]);

    if (step === STEP_INITIALIZE_NEW) {
        return (
            <FormProvider {...methods}>
                <ThreeColumnView
                    center={
                        <PlaylistSetupInitializeForm
                            onCancel={onCancelCallback}
                            onSubmit={() => setStep(STEP_SETTINGS)}
                        />
                    }
                    heading="engage.playlist.setup.new_playlist.title"
                />
            </FormProvider>
        );
    }

    return (
        <FormProvider {...methods}>
            <ThreeColumnView
                actions={<PlaylistSetupActions onCancel={onCancelCallback} playlistId={playlistId} />}
                center={<PlaylistSetupMainForm />}
                heading="engage.playlist.setup.title"
                left={<PlaylistSetupLibrary />}
                right={<PlaylistSetupSettingsForm isNewPlaylist={!playlistId} />}
            />
        </FormProvider>
    );
};

export default withDarkLayout(PlaylistSetupView, {
    useViewModeProvider: false,
});
