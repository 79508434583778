import { PublishingCampaignSchedulingCreateCampaignModel } from '@zetadisplay/engage-api-client';
import { createCampaignMediaFromPlaylistMedia } from '@zetadisplay/engage-components/modules/playlist';

import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';

const createPlaylistPayload = (formValues: PlaylistSetupFormValues, workspaceid?: string) => {
    const payload: Partial<PublishingCampaignSchedulingCreateCampaignModel> = {
        campaignMedia: formValues.playlistMediaCollection.map(createCampaignMediaFromPlaylistMedia) || [],
        description: null,
        folderId: formValues.folderId,
        layoutZoneId: formValues.layoutZoneId,
        mixingMode: formValues.mixingMode,
        name: formValues.name,
        priority: formValues.priority,
        publishGroups: (formValues.groups?.length >= 1 && formValues.groups.map((group) => group.id)) || null,
        schedules: formValues.schedules,
        status: formValues.status,
    };

    if (workspaceid) {
        return {
            ...payload,
            workspaceid,
        };
    }

    return { ...payload };
};

export default createPlaylistPayload;
