import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import { createPlayingTimeFromDate } from '@zetadisplay/engage-components/utils/scheduling';

import { NotifyFunctionType } from 'src/hooks/useNotify';
import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { PrimaryPowerScheduleFormData } from 'src/views/PowerScheduleSetupView';
import {
    getPowerScheduleCapitalWeekdayShort,
    getPowerScheduleWeekdayKey,
    PowerScheduleWeekdayLong,
} from 'src/views/PowerScheduleSetupView/Utils/powerScheduleWeekday';

const createOrUpdatePowerSchedulePromise = (
    api: ApiInterface,
    data: PrimaryPowerScheduleFormData,
    workspaceId: string
) => {
    const schedules = Object.entries(data.schedules).reduce((object, [key, schedule]) => {
        const weekdayKey = getPowerScheduleWeekdayKey(key as PowerScheduleWeekdayLong);
        if (weekdayKey === undefined) {
            return object;
        }

        const shortName = getPowerScheduleCapitalWeekdayShort(weekdayKey);

        return {
            ...object,
            [`endTime${shortName}`]: schedule.enabled ? createPlayingTimeFromDate(schedule.endTime) : null,
            [`startTime${shortName}`]: schedule.enabled ? createPlayingTimeFromDate(schedule.startTime) : null,
        };
    }, {} as Omit<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo, 'id' | 'name'>);

    if (data.id === 0) {
        return api.powerSchedules
            .createPowerSchedule({
                body: {
                    name: data.name,
                    ...schedules,
                },
                workspaceid: workspaceId,
            })
            .then((response) => createDiscriminatedEntity(EntityDiscriminators.PowerSchedule, response.data));
    }

    return api.powerSchedules
        .editPowerSchedule({
            body: {
                name: data.name,
                ...schedules,
            },
            powerscheduleid: data.id,
            workspaceid: workspaceId,
        })
        .then((response) => createDiscriminatedEntity(EntityDiscriminators.PowerSchedule, response.data));
};

const createOrUpdatePowerSchedule = (
    api: ApiInterface,
    data: PrimaryPowerScheduleFormData,
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const isExistingSchedule = data.id !== 0;
    const messages: PendingPromiseMessages = {
        pending: {
            message: isExistingSchedule
                ? 'engage.notification.edit.power_schedule.info'
                : 'engage.notification.create.power_schedule.info',
            params: [data.name],
        },
        success: {
            message: isExistingSchedule
                ? 'engage.notification.edit.power_schedule.success'
                : 'engage.notification.create.power_schedule.success',
            params: [data.name],
        },
    };

    return handlePendingPromise(createOrUpdatePowerSchedulePromise(api, data, workspaceId), messages, notify);
};

export default createOrUpdatePowerSchedule;
