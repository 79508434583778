import {
    ConfigModelsAttributeDetails,
    ConfigModelsAttributeRelations,
    ConfigModelsAttributeValueBasic,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { ApiInterface } from '@zetadisplay/engage-components/modules/api';
import pLimit from 'p-limit';

import { NotifyFunctionType } from 'src/hooks/useNotify';
import handlePendingPromise, { PendingPromiseMessages } from 'src/utils/HandlePendingPromise';
import { LabelValueState, LabelValueType } from 'src/views/LabelSetupView';

const limit = pLimit(1);

const createPromises = (
    api: ApiInterface,
    attribute: ConfigModelsAttributeRelations,
    data: LabelValueType[],
    workspaceId: string
) => {
    return Promise.all(
        data.map((attributeValue) => {
            return limit(async () => {
                if (attributeValue.state === LabelValueState.DELETED) {
                    return api.attributes
                        .deleteAttributeValue({
                            attributeid: attributeValue.labelId,
                            attributevalueid: attributeValue.id,
                            workspaceid: workspaceId,
                        })
                        .then(() => attributeValue);
                }

                return api.attributes
                    .updateAttributeValue({
                        body: { value: attributeValue.value },
                        attributeid: attribute.id,
                        attributevalueid: attributeValue.id,
                        workspaceid: workspaceId,
                    })
                    .then((response) => response.data)
                    .then((updatedValue) => ({ ...attributeValue, ...updatedValue, labelId: attribute.id }));
            });
        })
    );
};

const deleteOrUpdateAttributeValues = (
    api: ApiInterface,
    attribute: DiscriminatedEntity<ConfigModelsAttributeDetails>,
    attributeValues: DiscriminatedEntity<ConfigModelsAttributeValueBasic>[],
    data: LabelValueType[],
    workspaceId: string,
    notify: NotifyFunctionType
) => {
    const pendingValues = data.filter((value) => {
        return (
            value.state === LabelValueState.DELETED ||
            attributeValues.some(
                (attributeValue) => attributeValue.id === value.id && attributeValue.value !== value.value
            )
        );
    });

    if (pendingValues.length === 0) {
        return [];
    }

    const hasMultipleValues = pendingValues.length > 1;
    const messages: PendingPromiseMessages = {
        pending: {
            message: 'engage.notification.edit.label.value.info',
            params: [hasMultipleValues ? pendingValues.length.toString() : pendingValues[0].value],
            plural: hasMultipleValues,
        },
        success: {
            message: 'engage.notification.edit.label.value.success',
            params: [hasMultipleValues ? pendingValues.length.toString() : pendingValues[0].value],
            plural: hasMultipleValues,
        },
    };

    return handlePendingPromise(createPromises(api, attribute, pendingValues, workspaceId), messages, notify);
};

export default deleteOrUpdateAttributeValues;
