import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { PublishingCampaignSchedulingCampaignInfoWithMediaSchedules } from '@zetadisplay/engage-api-client';
import { usePendingPromise } from '@zetadisplay/engage-components/hooks';
import { PendingPromiseMessageType } from '@zetadisplay/engage-components/hooks/use-pending-promise';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';
import { DataLayerObject } from '@zetadisplay/zeta-ui-components/utils/data-layer/push-to-data-layer';
import { AxiosResponse } from 'axios';

import playlistCreateOrUpdatePromiseFactory from 'src/modules/playlist/actions/playlistCreateOrUpdatePromiseFactory';
import { PlaylistSetupFormValues } from 'src/modules/playlist/utils/createPlaylistFormValues';
import { getPlayingModeIdentifier, getScheduleModeIdentifier } from 'src/utils/Analytics/analyticsUtils';

export type PlaylistSetupActionsProps = {
    onCancel: () => void;
    playlistId?: number;
};

const createMessagesCallbackFactory = (edit: boolean) => {
    return (
        messageType: PendingPromiseMessageType,
        formValues: PlaylistSetupFormValues,
        response?: AxiosResponse<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>
    ) => {
        if (messageType === 'pending') {
            return undefined;
        }

        return {
            message: edit ? 'engage.notification.edit.playlist.success' : 'engage.notification.create.playlist.success',
            params: [response?.data.name || formValues.name],
        };
    };
};

const createButtonEvents = ({ schedules, status, mixingMode }: PlaylistSetupFormValues): DataLayerObject[] => {
    const schedule = schedules?.[0];

    return [
        createButtonClickEvent('Playlist Settings', 'Save'),
        createButtonClickEvent(
            'Playlist Settings',
            `Scheduling Type Chosen: ${getScheduleModeIdentifier(schedule, status)}`
        ),
        createButtonClickEvent('Playlist Settings', `Playlist Mode Chosen: ${getPlayingModeIdentifier(mixingMode)}`),
    ];
};

const PlaylistSetupActions = ({ onCancel, playlistId = undefined }: PlaylistSetupActionsProps) => {
    const api = useApi();
    const { formState, handleSubmit } = useFormContext<PlaylistSetupFormValues>();
    const { isSubmitting, isValid } = formState;
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        playlistCreateOrUpdatePromiseFactory({
            api,
            playlistId,
            workspaceid: workspace.id,
        }),
        createMessagesCallbackFactory(playlistId !== undefined),
        createButtonEvents
    );

    const onSubmit = useCallback(
        async (data: PlaylistSetupFormValues) => {
            const response = await submitAction(data);
            if (response === undefined) {
                return;
            }

            onCancel();
        },
        [onCancel, submitAction]
    );

    return (
        <div>
            <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={onCancel} />
            <Button
                busy={isSubmitting}
                disabled={isSubmitting || !isValid}
                kind="primaryAction"
                label={t.trans('common.action.save')}
                onClick={handleSubmit(onSubmit)}
            />
        </div>
    );
};

export default PlaylistSetupActions;
