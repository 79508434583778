import React, { ForwardedRef, forwardRef, JSXElementConstructor, useCallback, useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { ButtonGroup, LinkProps, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    BreadcrumbItem,
    Breadcrumbs,
    DropDownMenu,
    DropdownMenuItem,
    hasLinkInBreadcrumb,
} from '@zetadisplay/engage-components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, Search, SearchControls, SearchState } from '@zetadisplay/zeta-ui-components';
import { SearchFilters } from '@zetadisplay/zeta-ui-components/components/search/search';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        marginBottom: 13,
        position: 'relative',
    },
    children: {
        display: 'inline-block',
        '& > *': {
            height: 52,
        },
    },
    backButton: {
        alignSelf: 'center',
    },
    buttonContainer: {
        display: 'flex',
        marginBottom: 6,
        marginRight: 12,

        [theme.breakpoints.up('sm')]: {
            display: 'inline-flex',
            marginBottom: 0,
        },
    },
    searchContainer: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            flexGrow: 1,
            verticalAlign: 'middle',
        },
    },
    breadcrumbContainer: {},
    optionsContainer: {
        textAlign: 'right',
        flexWrap: 'wrap',
        '& div > button:not(:first-of-type)': {
            marginLeft: '12px !important',
        },
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'unset',
            justifyContent: 'flex-end',
        },
    },
    rowContainer: {
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
            display: 'flex',
        },
    },
}));

export type ViewToolbarBaseOption = {
    label: string;
    name: string;
};

export type ViewToolbarButtonOption = ViewToolbarBaseOption & {
    active?: boolean;
    onClick?: () => void;
};

export type ViewToolbarMenuOption = ViewToolbarBaseOption & {
    menuTrigger?: JSXElementConstructor<unknown>;
    options: DropdownMenuItem[];
    selectedOption?: Pick<DropdownMenuItem, 'label' | 'name'>;
};

export type ViewToolbarOption = ViewToolbarButtonOption | ViewToolbarMenuOption;

export const isViewToolbarMenuOption = (option: ViewToolbarOption): option is ViewToolbarMenuOption =>
    'options' in option;

type SearchOptions<TSearchFilters extends SearchFilters> = {
    filters: TSearchFilters;
    onChangeCallback: (arg: SearchState<TSearchFilters>) => void;
};

type ViewToolbarProps<TSearchFilters extends SearchFilters> = {
    breadcrumbs?: BreadcrumbItem[];
    options: ViewToolbarOption[];
    renderBulkActions?: () => React.FunctionComponentElement<unknown> | null;
    renderReturnButton?: boolean;
    searchOptions?: SearchOptions<TSearchFilters>;
    showBreadcrumbs?: boolean;
};

const ViewToolbar = <TSearchFilters extends SearchFilters>(
    {
        breadcrumbs = [],
        options,
        renderBulkActions = undefined,
        renderReturnButton = false,
        searchOptions,
        showBreadcrumbs = true,
    }: ViewToolbarProps<TSearchFilters>,
    ref: ForwardedRef<SearchControls>
) => {
    const { classes, cx } = useStyles();
    const navigate = useNavigate();
    const t = useTranslation();
    const theme = useTheme();

    const previousBreadcrumbItemPath = useMemo(() => {
        const breadcrumb = breadcrumbs[breadcrumbs.length - 2] || undefined;
        if (breadcrumb === undefined || !hasLinkInBreadcrumb(breadcrumb)) {
            return undefined;
        }

        return breadcrumb.href;
    }, [breadcrumbs]);

    const getLinkProps = useCallback((breadcrumb: BreadcrumbItem): LinkProps<typeof RouterLink> => {
        if (hasLinkInBreadcrumb(breadcrumb)) {
            return {
                component: RouterLink,
                to: breadcrumb.href,
            };
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {};
    }, []);

    const renderToolbarOption = (option: ViewToolbarOption) => {
        if (isViewToolbarMenuOption(option)) {
            return (
                <DropDownMenu
                    key={option.name}
                    menuTrigger={option?.menuTrigger}
                    name={option.name}
                    options={option.options}
                    selectedOption={option.selectedOption}
                    triggerLabel={option.label}
                />
            );
        }

        return (
            <Button key={option.name} onClick={option.onClick}>
                {t.trans(option.label)}
            </Button>
        );
    };

    return (
        <div className={cx(classes.root)}>
            {showBreadcrumbs && breadcrumbs.length > 0 && (
                <div className={cx(classes.breadcrumbContainer)}>
                    <Breadcrumbs getLinkProps={getLinkProps} items={breadcrumbs} />
                </div>
            )}

            <div className={cx(classes.rowContainer)}>
                {(renderBulkActions || renderReturnButton) && (
                    <div className={cx(classes.buttonContainer)}>
                        {renderReturnButton && (
                            <Button
                                className={cx(classes.backButton)}
                                disabled={previousBreadcrumbItemPath === undefined}
                                label={t.trans('common.action.back')}
                                onClick={() => navigate(previousBreadcrumbItemPath || '')}
                                startIcon={<ArrowBack />}
                            />
                        )}

                        {renderBulkActions && renderBulkActions()}
                    </div>
                )}

                {searchOptions && (
                    <div className={cx(classes.searchContainer)}>
                        <Search
                            filters={searchOptions.filters}
                            fullWidth
                            onChangeCallback={searchOptions.onChangeCallback}
                            searchRef={ref}
                        />
                    </div>
                )}

                <div className={cx(classes.optionsContainer)}>
                    <ButtonGroup
                        color="primary"
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        size={(useMediaQuery(theme.breakpoints.up('sm')) && 'medium') || 'small'}
                        variant="outlined"
                    >
                        {options.map((option) => renderToolbarOption(option))}
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(ViewToolbar);
