import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';
import { ThreeColumnView } from '@zetadisplay/engage-components/layouts/components';
import { ApiInterface, useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

import useViewTitle from 'src/hooks/useViewTitle';
import GroupSetupActions from 'src/modules/groups/setup/components/GroupSetupActions';
import GroupSetupSettingsForm from 'src/modules/groups/setup/components/GroupSetupSettingsForm';
import GroupSetupTargeting from 'src/modules/groups/setup/components/GroupSetupTargeting';
import fetchGroupFormData from 'src/modules/groups/setup/utils/fetchGroupFormData';
import LabelsLibrary from 'src/modules/Labels/LabelsLibrary';
import withDarkLayout from 'src/utils/Layout/withDarkLayout';

export const createGroupFormValues = (
    values: Partial<ConfigModelsGroupPersistModel> = {}
): ConfigModelsGroupPersistModel => {
    return {
        attributesConstraints: values.attributesConstraints || [],
        description: values.description || '',
        name: values.name || '',
        parent: values.parent || null,
    };
};

const getDefaultValues = ({
    api,
    groupId,
    parent,
    workspaceId,
}: {
    api: ApiInterface;
    groupId?: number;
    parent?: number;
    workspaceId: string;
}) => {
    if (groupId) {
        return fetchGroupFormData(groupId, workspaceId, api);
    }

    return createGroupFormValues({
        parent,
    });
};
const GroupSetupView = () => {
    useViewTitle('engage.players.group.setup.title');

    // Extract groupId from URL search params
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get('groupId');
    const api = useApi();
    const { workspace } = useWorkspace();

    const location = useLocation();
    const state = location.state as { currentGroupId?: number };

    const methods = useForm({
        defaultValues: async () => {
            return getDefaultValues({
                api,
                groupId: (groupId && Number.parseInt(groupId, 10)) || undefined,
                // TODO: Still missing parent, we need useLocation to get it
                parent: state?.currentGroupId || undefined,
                workspaceId: workspace.id,
            });
        },
    });

    return (
        <FormProvider {...methods}>
            <ThreeColumnView
                actions={<GroupSetupActions groupId={groupId} />}
                center={<GroupSetupSettingsForm />}
                heading="engage.players.group.setup.title"
                icon="DISTRIBUTION_GROUP"
                left={<LabelsLibrary />}
                right={<GroupSetupTargeting />}
            />
        </FormProvider>
    );
};

export default withDarkLayout(GroupSetupView);
