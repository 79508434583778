import React, { FormEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from '@zetadisplay/engage-components';

import { whitespace } from 'src/utils/Form/validators';

export type FolderFormFields = {
    name: string;
};

type FolderFormProps = {
    defaultValues?: FolderFormFields;
    onSubmit?: FormEventHandler<HTMLFormElement>;
};

const FolderForm = ({ defaultValues, onSubmit }: FolderFormProps) => {
    const { formState } = useFormContext<FolderFormFields>();
    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <TextInput
                defaultLabel="common.form.input.name"
                defaultValue={defaultValues?.name || ''}
                error={formState.errors.name}
                name="name"
                required
                rules={{
                    required: 'common.validation.required',
                    validate: { whitespace },
                }}
            />
        </form>
    );
};

export default FolderForm;
