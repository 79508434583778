import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { ItemsView, SkeletonItemsView } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { getThumbnailUrl } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ItemProps } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import TemplateLibraryListItemSubtitle from 'src/components/Modals/Library/TemplateLibrary/Components/TemplateLibraryList/Components/TemplateLibraryListItemSubtitle';
import useTemplateLibraryListActions from 'src/components/Modals/Library/TemplateLibrary/Components/TemplateLibraryList/Hooks/useTemplateLibraryListActions';

const useStyles = makeStyles()(() => ({
    root: {
        height: 'calc(92vh - 200px)',
        paddingLeft: 20,
        paddingRight: 4,
        width: '100%',
    },
    container: {
        height: '100%',
        width: '100%',
        flexGrow: 1,
    },
    info: {
        color: themeOptions.colors.BLACK,
        padding: 48,
    },
}));

type TemplateLibraryListProps = {
    infiniteScrolling: boolean;
    loading: boolean;
    nodes: DiscriminatedEntity<LibraryModelsTemplateRelations>[];
    onInfiniteScroll?: () => void;
};

const TemplateLibraryList = ({ infiniteScrolling, loading, nodes, onInfiniteScroll }: TemplateLibraryListProps) => {
    const actions = useTemplateLibraryListActions();
    const { classes } = useStyles();
    const t = useTranslation();

    const itemProps: ItemProps<DiscriminatedEntity<LibraryModelsTemplateRelations>> = useMemo(
        () => ({
            actions,
            getItemIconType: getIconType,
            getThumbnailUrl: (item) => getThumbnailUrl(item) || '',
            inViewThreshold: 0.3,
            renderSubtitle: (item) => <TemplateLibraryListItemSubtitle item={item} />,
            showThumbnail: true,
        }),
        [actions]
    );

    const renderItemsView = () => {
        if (loading && nodes.length === 0) {
            return <SkeletonItemsView actions={actions} viewMode="GRID" />;
        }

        if (!nodes || nodes.length === 0) {
            return (
                <Typography align="center" className={classes.info}>
                    {t.trans('engage.library.content.no_content')}
                </Typography>
            );
        }

        return (
            <ItemsView
                infiniteScrolling={infiniteScrolling}
                isLoadingMore={loading && nodes.length > 0}
                ItemProps={itemProps}
                items={nodes}
                onInfiniteScroll={onInfiniteScroll}
                viewMode="GRID"
            />
        );
    };

    return (
        <div className={classes.root} data-testid="template-library-list" id="templateLibraryList">
            <div className={classes.container}>{renderItemsView()}</div>
        </div>
    );
};

export default TemplateLibraryList;
