import React, { useCallback, useMemo } from 'react';
import { Chip, LinkProps, Typography } from '@mui/material';
import { Breadcrumb, Breadcrumbs, hasActionInBreadcrumb } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ItemList, ItemListItem, ItemListItemProps, ItemListSkeleton } from '@zetadisplay/zeta-ui-components';
import { IdentifiableObject, NamedObject } from '@zetadisplay/zeta-ui-components/models';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { createDefaultButton, SidekickActionsProps } from 'src/modules/Sidekick/Components/SidekickActions';
import Sidekick from 'src/modules/Sidekick/Sidekick';

const useStyles = makeStyles()(() => ({
    selectedTargetList: {
        maxHeight: 140,
        minHeight: 36,
        padding: '10px 0',
    },
    subject: {
        marginBottom: 10,
    },
    'subject--multiple': {
        paddingTop: 10,
    },
    subtitle: {
        marginBottom: 10,
    },
    targetInfo: {
        lineHeight: '36px',
    },
    targets: {
        flexGrow: 1,
    },
    targetsList: {
        borderTop: '1px solid',
        borderColor: '#D9D9D9',
        height: '100%',
        width: '100%',
    },
}));

const MOVE_SUBJECTS_MAX_CHIP_LIMIT = 4;

type Props<
    Subject extends DiscriminatedEntity<IdentifiableObject & NamedObject>,
    Target extends DiscriminatedEntity<IdentifiableObject & NamedObject>
> = {
    ActionProps?: Partial<SidekickActionsProps>;
    breadcrumbs: Breadcrumb[];
    isLoading?: boolean;
    onRemoveSelectedTarget: (item: Target) => void;
    selectedTargets: Target[];
    subjects: Subject[];
    SubjectItemProps: Pick<ItemListItemProps<Subject>, 'renderSubtitle'>;
    targets: Target[];
    TargetItemProps: Pick<ItemListItemProps<Target>, 'clickable' | 'onItemClick' | 'primaryAction'>;
};

const MoveSidekick = <
    Subject extends DiscriminatedEntity<IdentifiableObject & NamedObject>,
    Target extends DiscriminatedEntity<IdentifiableObject & NamedObject>
>({
    ActionProps,
    breadcrumbs,
    isLoading,
    onRemoveSelectedTarget,
    selectedTargets,
    subjects,
    SubjectItemProps,
    targets,
    TargetItemProps,
}: Props<Subject, Target>) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    // Specific calculated states
    const multipleSubjects = useMemo(() => subjects.length > 1, [subjects.length]);

    const getLinkProps = useCallback(
        (breadcrumb: Breadcrumb): LinkProps => ({
            onClick: hasActionInBreadcrumb(breadcrumb) ? breadcrumb.action : undefined,
        }),
        []
    );

    const renderMoveSubjects = useCallback(() => {
        if (multipleSubjects) {
            return subjects
                .slice(0, MOVE_SUBJECTS_MAX_CHIP_LIMIT)
                .map((subject) => <Chip key={subject.id} label={subject.name} />)
                .concat(
                    <Chip
                        label={`+ ${t.trans('common.action.more', [
                            (subjects.length - MOVE_SUBJECTS_MAX_CHIP_LIMIT).toString(),
                        ])}`}
                    />
                );
        }

        return (
            <ItemListItem
                {...SubjectItemProps}
                clickable={false}
                getItemIconType={(item) => getIconType(item)}
                item={subjects[0]}
                showThumbnail={false}
            />
        );
    }, [SubjectItemProps, multipleSubjects, subjects, t]);

    const renderTargetList = useCallback(() => {
        if (isLoading) {
            return <ItemListSkeleton items={4} textCells={1} />;
        }

        return (
            <ItemList
                items={targets}
                ItemProps={{
                    ...TargetItemProps,
                    getItemIconType: getIconType,
                    showThumbnail: false,
                    type: 'compact',
                }}
                overscan={0}
            />
        );
    }, [isLoading, targets, TargetItemProps]);

    const renderSelectedTargets = useCallback(() => {
        if (selectedTargets.length === 0) {
            return (
                <Typography align="center" variant="body2" className={classes.targetInfo}>
                    {t.trans('engage.sidekick.targets.select_to_continue')}
                </Typography>
            );
        }

        return selectedTargets.map((target) => (
            <Chip key={target.id} label={target.name} clickable onDelete={() => onRemoveSelectedTarget(target)} />
        ));
    }, [classes.targetInfo, onRemoveSelectedTarget, selectedTargets, t]);

    return (
        <Sidekick
            ActionProps={createDefaultButton({ label: 'common.action.move', ...ActionProps })}
            header={{
                label: t.trans(
                    'engage.sidekick.move.title',
                    [multipleSubjects ? subjects.length.toString() : subjects[0].name],
                    multipleSubjects
                ),
            }}
        >
            <div className={cx({ [classes.subject]: true })} data-testid="sidekick-subjects">
                {renderMoveSubjects()}
            </div>
            <Typography variant="h4" className={classes.subtitle}>
                {t.trans('engage.sidekick.targets')}
            </Typography>
            <Breadcrumbs getLinkProps={getLinkProps} items={breadcrumbs} type="mini" />
            <div className={classes.targets} data-testid="sidekick-targets" id="sidekickTargets">
                <div className={classes.targetsList}>{renderTargetList()}</div>
            </div>
            <Typography variant="h4">{t.trans('engage.sidekick.targets.selected')}</Typography>
            <div data-testid="sidekick-selected-targets" id="sidekickSelectedTargets">
                <div className={classes.selectedTargetList}>{renderSelectedTargets()}</div>
            </div>
        </Sidekick>
    );
};

export default MoveSidekick;
