import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import DialogContentText from '@mui/material/DialogContentText';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import DeleteMediaFileForm from 'src/components/Forms/DeleteMediaFileForm';
import useDeleteMediaFileSubmitAction from 'src/components/Modals/Library/MediaFile/Hooks/useDeleteMediaFileSubmitAction';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

export type DeleteMediaFileFields = {
    selectedPlaylists?: number[];
    deleteSourceMedia?: boolean;
};

export type DeleteMediaFilePromptProps = {
    media: DiscriminatedEntity<LibraryModelsMedia>;
} & NiceModalHocProps;

const DeleteMediaFilePrompt = NiceModal.create<DeleteMediaFilePromptProps>(({ media }) => {
    const methods = useForm<DeleteMediaFileFields>({
        defaultValues: { deleteSourceMedia: true, selectedPlaylists: [] },
        shouldUnregister: false,
    });
    const modal = useModal();
    const t = useTranslation();

    const submitAction = useDeleteMediaFileSubmitAction(media);
    const hasPlaylists = media.campaignIds && media.campaignIds.length !== 0;
    const selectedPlaylists = methods.watch('selectedPlaylists') || [];

    const onSubmit = useCallback(
        async (formValues: DeleteMediaFileFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled:
                                (hasPlaylists && selectedPlaylists.length === 0) || methods.formState.isSubmitting,
                            label: 'common.action.delete',
                            name: 'delete',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type={getIconType(media)} />,
                    label: 'engage.modal.delete.title',
                    labelParams: [media.name],
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    {hasPlaylists
                        ? t.trans('engage.library.content.delete.usages.info')
                        : t.trans('engage.modal.delete.content', [media.name])}
                </DialogContentText>
                {hasPlaylists && <DeleteMediaFileForm media={media} />}
            </Modal>
        </FormProvider>
    );
});

export default DeleteMediaFilePrompt;
