import React from 'react';
import { Box, Typography } from '@mui/material';
import { LibraryModelsMedia, LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { filesize } from 'filesize';

import ExpandedDetailsDetail from 'src/components/ExpandedDetails/ExpandedDetailsDetail';

const useStyles = makeStyles()(() => ({
    title: {
        marginBottom: 2,
    },
}));

type LibraryListMediaDetailsInformationProps = {
    item: DiscriminatedEntity<LibraryModelsMedia>;
    template?: LibraryModelsTemplateRelations | undefined;
};

const LibraryListMediaDetailsInformation: React.FC<LibraryListMediaDetailsInformationProps> = ({ item, template }) => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    return (
        <Box>
            <Typography className={classes.title} variant="h5">
                {trans('engage.library.media.details.title')}
            </Typography>
            {template !== undefined && (
                <ExpandedDetailsDetail
                    name="type"
                    subject="common.detail.type"
                    value={
                        template?.liveTemplate
                            ? trans('engage.templates.type.live')
                            : trans('engage.templates.type.transcoded')
                    }
                />
            )}
            <ExpandedDetailsDetail
                name="created"
                subject="common.detail.imported"
                value={new Date(item.created).toLocaleString()}
            />
            <ExpandedDetailsDetail
                name="modified"
                subject="common.detail.updated"
                value={new Date(item.modified).toLocaleString()}
            />
            <ExpandedDetailsDetail
                name="fileSize"
                subject="common.detail.filesize"
                value={item.fileSize !== undefined ? filesize(item.fileSize).toString() : '-'}
            />
        </Box>
    );
};

export default LibraryListMediaDetailsInformation;
