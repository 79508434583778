import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Permission from 'src/routing/Components/Permission';
import DashboardView from 'src/views/DashboardView';
import GroupSetupView from 'src/views/GroupSetupView';
import LabelSetupView from 'src/views/LabelSetupView';
import LibraryView from 'src/views/LibraryView';
import PlayersView from 'src/views/PlayersView';
import PlaylistSetupView from 'src/views/PlaylistSetupView';
import PlaylistView from 'src/views/PlaylistView';
import PowerScheduleSetupView from 'src/views/PowerScheduleSetupView';

import DisplayError from './Components/DisplayError';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Routing = () => {
    return (
        <SentryRoutes>
            <Route index element={<DashboardView />} />
            <Route path="/library" element={<Permission roles={['ENGAGE_LIBRARY_VIEW']} />}>
                <Route index path="*" element={<LibraryView />} />
            </Route>
            <Route path="/playlists" element={<Permission roles={['ENGAGE_PLAYLIST_VIEW']} />}>
                <Route path="create" element={<Permission roles={['ENGAGE_PLAYLIST_CREATE']} />}>
                    <Route index element={<PlaylistSetupView />} />
                </Route>
                <Route path="edit" element={<Permission roles={['ENGAGE_PLAYLIST_EDIT']} />}>
                    <Route index element={<PlaylistSetupView />} />
                </Route>
                <Route index path="*" element={<PlaylistView />} />
            </Route>
            <Route path="/players" element={<Permission roles={['ENGAGE_PLAYER_VIEW']} />}>
                <Route path="group" element={<Permission roles={['ENGAGE_LABEL_MANAGEMENT']} />}>
                    <Route path="create" element={<GroupSetupView />} />
                    <Route path="edit" element={<GroupSetupView />} />
                </Route>
                <Route path="labels" element={<Permission roles={['ENGAGE_LABEL_MANAGEMENT', 'ENGAGE_PLAYER_EDIT']} />}>
                    <Route path="settings">
                        <Route path=":attributeId" element={<LabelSetupView />} />
                        <Route path=":attributeId/:attributeValueId" element={<LabelSetupView />} />
                        <Route index element={<LabelSetupView />} />
                    </Route>
                </Route>
                <Route path="power" element={<Permission roles={['ENGAGE_POWERSCHEDULE_VIEW']} />}>
                    <Route
                        path="settings"
                        element={<Permission roles={['ENGAGE_POWERSCHEDULE_CREATE', 'ENGAGE_POWERSCHEDULE_EDIT']} />}
                    >
                        <Route index element={<PowerScheduleSetupView />} />
                    </Route>
                </Route>
                <Route index path="*" element={<PlayersView />} />
            </Route>
            <Route
                path="*"
                element={
                    <DisplayError
                        title="404 - Not Found"
                        description="Oops! It seems like the page you are looking for does not exist"
                    />
                }
            />
        </SentryRoutes>
    );
};

export default Routing;
