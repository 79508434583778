import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';
import { Close } from 'styled-icons/material';

const { DARKGRAY } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    closeIcon: {
        cursor: 'pointer',
        position: 'relative',
        top: 3,
        right: 0,
        marginRight: 10,
    },
    header: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    iconContainer: {
        display: 'inline-block',
        padding: '0 10px 3px 0',
    },
    title: {
        maxWidth: '92%',
    },
}));

export type SidekickHeaderProps = {
    /**
     * @deprecated use {renderHeader}
     */
    icon?: JSX.Element;
    /**
     * @deprecated use {renderHeader}
     */
    label?: string;
    onClose: () => void;
    renderHeader?: () => JSX.Element;
    renderClose?: (onClose: () => void) => JSX.Element;
};

const SidekickHeader = ({ icon: IconComponent, label, onClose, renderHeader, renderClose }: SidekickHeaderProps) => {
    const { classes } = useStyles();

    const renderDefaultHeader = useCallback(() => {
        return (
            <>
                {IconComponent && <div className={classes.iconContainer}>{IconComponent}</div>}
                {label && (
                    <Typography className={classes.title} noWrap variant="h3" title={label}>
                        {label}
                    </Typography>
                )}
            </>
        );
    }, [IconComponent, classes.iconContainer, classes.title, label]);

    const renderDefaultClose = useCallback(() => {
        return (
            <Close
                className={classes.closeIcon}
                color={DARKGRAY}
                data-testid="sidekick-close"
                onClick={onClose}
                size={22}
            />
        );
    }, [classes.closeIcon, onClose]);

    return (
        <div className={classes.header} data-testid="sidekick-header">
            {renderClose !== undefined ? renderClose(onClose) : renderDefaultClose()}
            {renderHeader !== undefined ? renderHeader() : renderDefaultHeader()}
        </div>
    );
};

export default SidekickHeader;
