import React, { useCallback } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { FormProvider, useForm } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, Workspace } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import {
    getWorkspaceById,
    useWorkspacesContent,
    WorkspaceInput,
} from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import usePendingPromise from 'src/hooks/usePendingPromise';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

type ShareMediaFolderFormFields = {
    selectedWorkspaces: string[];
};

export type ShareMediaFolderFormProps = {
    folder: DiscriminatedEntity<LibraryModelsFolder>;
} & NiceModalHocProps;

const ShareMediaFolderForm = NiceModal.create<ShareMediaFolderFormProps>(({ folder }) => {
    const api = useApi();
    const methods = useForm<ShareMediaFolderFormFields>({
        defaultValues: { selectedWorkspaces: [] },
        shouldUnregister: true,
    });
    const modal = useModal();
    const t = useTranslation();
    const workspaces = useWorkspacesContent();

    // Fetch and resolve workspaces that this folder has
    const defaultWorkspaces = useAsyncAbortable(
        async (signal) => {
            if (workspaces.loading) {
                return null;
            }

            return api.folders
                .getSharedFolderById({ folderid: folder.id, workspaceid: folder.workspaceId }, { signal })
                .then((response) => response.data.workspaces?.map((ws) => getWorkspaceById(ws, workspaces.result)))
                .then((response) => response?.filter((ws): ws is Workspace => ws !== undefined))
                .then((response) => response || []);
        },
        [api.folders, folder.id, folder.workspaceId, workspaces.loading, workspaces.result]
    );

    const submitAction = usePendingPromise(
        async (formValues: ShareMediaFolderFormFields) => {
            return api.folders
                .shareFolder({
                    folderid: folder.id,
                    body: {
                        name: folder.name,
                        workspaces: formValues.selectedWorkspaces || [],
                    },
                    workspaceid: folder.workspaceId,
                })
                .then((response) => ({
                    ...folder,
                    sharedTo: response.data.workspaces && response.data.workspaces.length !== 0,
                }));
        },
        { success: { message: 'engage.notification.share.folder.success', params: [folder.name] } }
    );

    const onSubmit = useCallback(
        async (formValues: ShareMediaFolderFormFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting,
                            label: 'common.action.share',
                            name: 'share',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    label: 'engage.modal.share.folder.title',
                    labelParams: [folder.name],
                }}
            >
                {defaultWorkspaces.loading || defaultWorkspaces.result === null ? (
                    <ComponentLoader />
                ) : (
                    <WorkspaceInput
                        control={methods.control as unknown as Control}
                        defaultWorkspaces={defaultWorkspaces.result}
                        label={t.trans('engage.workspace.search')}
                        name="selectedWorkspaces"
                    />
                )}
            </Modal>
        </FormProvider>
    );
});

export default ShareMediaFolderForm;
