import { useCallback } from 'react';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';

export type RemoveNotificationParams = {
    key: SnackbarKey;
    remove: boolean;
};

export type ShowNotificationParams = {
    duration?: number | null;
    key?: SnackbarKey;
    message: string;
    params?: string[];
    persist?: boolean;
    plural?: boolean;
    variant?: VariantType;
};

export type NotifyFunctionType = (notification: RemoveNotificationParams | ShowNotificationParams) => SnackbarKey;

const isRemoveNotification = (
    notification: RemoveNotificationParams | ShowNotificationParams
): notification is RemoveNotificationParams => 'remove' in notification;

const useNotify = () => {
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const t = useTranslation();

    return useCallback<NotifyFunctionType>(
        (notification) => {
            if (isRemoveNotification(notification)) {
                closeSnackbar(notification.key);

                return notification.key;
            }

            if (notification.key) {
                closeSnackbar(notification.key);
            }

            return enqueueSnackbar(t.trans(notification.message, notification.params, notification.plural), {
                autoHideDuration: notification.duration,
                key: notification.key,
                persist: notification.persist,
                variant: notification.variant,
            });
        },
        [closeSnackbar, enqueueSnackbar, t]
    );
};

export default useNotify;
