import { useCallback, useMemo } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import { PublishGroupsApiGetGroupsRequest } from '@zetadisplay/engage-api-client/api/publish-groups-api';
import { usePaginatedRequests, usePaginatedResults } from '@zetadisplay/engage-components/hooks';
import { onPaginatedResultsErrorHandler } from '@zetadisplay/engage-components/hooks/use-paginated-results/use-paginated-results';
import getResultData from '@zetadisplay/engage-components/hooks/utils/get-result-data';
import { DiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useSorting } from '@zetadisplay/engage-components/modules/options';
import { QueryFilter } from '@zetadisplay/engage-components/modules/search';
import { getQueryFilter } from '@zetadisplay/engage-components/modules/search/query-filters';
import { createKeywordFilterQuery, hasSearchFilters } from '@zetadisplay/engage-components/modules/search/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { SearchFilters, SearchState } from '@zetadisplay/zeta-ui-components';

export type UsePaginatedGroupsOptions = {
    currentGroupId?: number;
    enabled?: boolean;
    pageSize?: number;
    searchFilters?: SearchState<SearchFilters>;
    searchFiltersKey?: string;
};

export type UsePaginatedGroupsReturnType = {
    getNextResultPage: () => void;
    data: DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>[];
    isLoading: boolean;
    removeResult: (item: DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>) => void;
    setResult: (item: DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>) => void;
    total: number | undefined;
};

const usePaginatedGroups = ({
    currentGroupId,
    enabled = true,
    pageSize,
    searchFilters,
    searchFiltersKey,
}: UsePaginatedGroupsOptions): UsePaginatedGroupsReturnType => {
    const api = useApi();
    const sorting = useSorting();
    const { workspace } = useWorkspace();

    const filters = useMemo(() => {
        if (searchFilters === undefined) {
            return [];
        }

        return [createKeywordFilterQuery(searchFilters, 'name')].filter(
            (filter): filter is QueryFilter => filter !== undefined
        );
    }, [searchFilters]);

    const { $top, $skip, getNextResultPage } = usePaginatedRequests(
        `group-${currentGroupId}-${enabled}`,
        searchFiltersKey,
        sorting.sort,
        pageSize
    );

    const { results, removeResult, setResult, setResults, total } =
        usePaginatedResults<ConfigModelsGroupInfoWithChildren>(
            `group-${currentGroupId}-${enabled}`,
            EntityDiscriminators.Group,
            searchFiltersKey
        );

    const fetchGroups = useCallback(
        async (signal: AbortSignal) => {
            const filter = getQueryFilter(filters);
            const recursive = Boolean(filter && filter.length !== 0);
            const params: PublishGroupsApiGetGroupsRequest = {
                parentId: !recursive || currentGroupId ? currentGroupId : undefined,
                recursive,
                workspaceid: workspace.id,
                $filter: filter,
                $top,
                $skip,
            };

            return api.publishGroups.getGroups(params, { signal });
        },
        [$skip, $top, api.publishGroups, currentGroupId, filters, workspace.id]
    );

    const groups = useAsyncAbortable(
        async (signal) => {
            if (!enabled || !hasSearchFilters(searchFilters, 'keyword')) {
                return undefined;
            }

            return fetchGroups(signal)
                .then((response) => setResults(response.data.items, response.data.total))
                .catch(() => onPaginatedResultsErrorHandler(results.current, setResults));
        },
        [enabled, fetchGroups, results, searchFilters, setResults],
        {
            setLoading: (state) => {
                return { ...state, result: results.current && [...results.current.values()], loading: true };
            },
        }
    );

    return {
        getNextResultPage,
        data: getResultData(results.current),
        isLoading: groups.loading,
        removeResult,
        setResult,
        total: total.current,
    };
};

export default usePaginatedGroups;
