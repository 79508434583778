import React, { useCallback } from 'react';
import { LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { PlaylistMediaType, PlaylistSetupModifiedMediaType } from '@zetadisplay/engage-components/modules/playlist';
import { DragObject, DropTarget, NativeFilesDropObject } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlaylistSetupContentListItem from 'src/views/PlaylistSetupView/Components/PlaylistSetupMainForm/PlaylistSetupContentList/PlaylistSetupContentListItem';
import { emitOnPlaylistMediaAdded } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaAddedEvent';
import { emitOnPlaylistMediaDragged } from 'src/views/PlaylistSetupView/Events/onPlaylistMediaDraggedEvent';
import { createPlaylistMedia } from 'src/views/PlaylistView/Utils/withMediaResolver';

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        height: 'calc(100vh - 210px)',
    },
    list: {
        borderTop: '1px solid rgba(255, 255, 255, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        margin: '0 0 13px',
        minHeight: 76,
        overflowY: 'auto',
        padding: 0,
        width: '100%',
    },
}));

// TODO DropTarget could perhaps make use of definition what can be dropped or something as DragObject and NativeObject differ a bit
const isDragObject = (object: unknown): object is DragObject<unknown> => {
    return Object.prototype.hasOwnProperty.call(object, 'data');
};

type Props = {
    layoutZoneId?: number;
    modifiedMedia: Map<string, PlaylistSetupModifiedMediaType>;
    playlistMediaCollection: Partial<PlaylistMediaType>[];
};

const PlaylistSetupContentList = ({ layoutZoneId, modifiedMedia, playlistMediaCollection }: Props) => {
    const { classes, cx } = useStyles();
    const canDropOnList = useCallback(() => playlistMediaCollection.length === 0, [playlistMediaCollection.length]);

    // Drag and dropping from library does not fetch default media schedules until backend supports data without external fetch
    // TODO: We probably should emit on drop event and let the subscriber handle the rest
    const handleOnDrop = useCallback(
        (dragObject: DragObject<DiscriminatedEntity<LibraryModelsMedia>> | NativeFilesDropObject) => {
            if (!isDragObject(dragObject) || dragObject.data === undefined || playlistMediaCollection.length !== 0) {
                return;
            }

            emitOnPlaylistMediaAdded(createPlaylistMedia(dragObject.data));
        },
        [playlistMediaCollection.length]
    );

    return (
        <DropTarget<DiscriminatedEntity<LibraryModelsMedia>>
            acceptTypes={[EntityDiscriminators.MediaFile]}
            validateDrop={canDropOnList}
            onDrop={handleOnDrop}
        >
            <div className={cx(classes.container)}>
                <div className={classes.list} role="list" data-testid="playlist-content-list">
                    {playlistMediaCollection.map((media, index, self) => {
                        const copies: string[] = self
                            .map((copy) => (media.mediaId === copy.mediaId ? copy.id : undefined))
                            .filter((value): value is string => value !== undefined);

                        return (
                            <PlaylistSetupContentListItem
                                copies={copies}
                                key={media.id}
                                layoutZoneId={layoutZoneId}
                                modifiedMedia={modifiedMedia}
                                onDragItem={(dragIndex, hoverIndex, newItem) =>
                                    emitOnPlaylistMediaDragged({ dragIndex, hoverIndex, newItem })
                                }
                                playlistMedia={media}
                                playlistMediaIndex={index}
                            />
                        );
                    })}
                </div>
            </div>
        </DropTarget>
    );
};

export default PlaylistSetupContentList;
