import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { createButtonClickEvent } from '@zetadisplay/zeta-ui-components/utils/data-layer';

import ConfirmPrompt, { ConfirmPromptActions } from 'src/components/Modals/ConfirmPrompt';
import usePendingPromise from 'src/hooks/usePendingPromise';

export type RemoveFolderPromptProps = {
    folder: DiscriminatedEntity<LibraryModelsFolder>;
} & NiceModalHocProps;

const DeletePlaylistFolderPrompt = NiceModal.create<RemoveFolderPromptProps>(({ folder }) => {
    const api = useApi();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const submitAction = usePendingPromise(
        async () => {
            return api.folders
                .deleteFolder({
                    folderid: folder.id,
                    forceDelete: true,
                    workspaceid: workspace.id,
                })
                .then(() => folder);
        },
        { success: { message: 'engage.notification.delete.folder.success', params: [folder.name] } },
        createButtonClickEvent('Editing option', 'Delete')
    );

    return (
        <ConfirmPrompt
            description={t.trans('engage.modal.delete.folder.confirmation', [folder.name])}
            submit={{ label: 'common.action.delete', name: 'delete' }}
            submitAction={submitAction}
            title={{ label: 'engage.modal.delete.title', labelParams: [folder.name] }}
            // TODO: Update label to 'common.validation.validate.action' after R18 upgrade
            validate={{ value: ConfirmPromptActions.DELETE, label: 'Action confirmation' }}
        />
    );
});

export default DeletePlaylistFolderPrompt;
