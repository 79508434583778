import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    detailSubject: {
        fontSize: 13,
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    row: {
        display: 'flex',
        marginBottom: 4,
    },
    text: {
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
    },
}));

type ItemDetailsProps = {
    name: string;
    subject: string;
    value: string;
};

const ExpandedDetailsDetail = ({ name, subject, value }: ItemDetailsProps) => {
    const { classes, cx } = useStyles();
    const { trans } = useTranslation();

    return (
        <Box data-testid={`expanded-details-detail-${name}`} className={classes.row}>
            <Typography
                className={cx(classes.detailSubject, classes.text)}
                data-testid={`expanded-details-detail-${name}-subject`}
            >
                {trans(subject)}:
            </Typography>
            <Typography className={classes.text} data-testid={`expanded-details-detail-${name}-value`}>
                {value}
            </Typography>
        </Box>
    );
};

export default ExpandedDetailsDetail;
