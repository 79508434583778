import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useCurrentMediaFolder } from '@zetadisplay/engage-components/modules/library/hooks';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { Icon } from '@zetadisplay/zeta-ui-components';

import FolderForm, { FolderFormFields } from 'src/components/Forms/FolderForm';
import usePendingPromise from 'src/hooks/usePendingPromise';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

const CreateMediaFolderForm = NiceModal.create(() => {
    const api = useApi();
    const currentFolderId = useCurrentMediaFolder();
    const methods = useForm<FolderFormFields>();
    const modal = useModal();
    const { workspace } = useWorkspace();

    const { errors, isSubmitting } = methods.formState;
    const hasErrors = Object.keys(errors).length !== 0;

    const submitAction = usePendingPromise(
        async (formValues: FolderFormFields) => {
            return api.folders
                .createMediaFolder({
                    body: {
                        parentId: currentFolderId,
                        path: formValues.name,
                    },
                    workspaceid: workspace.id,
                })
                .then((response) => createDiscriminatedEntity(EntityDiscriminators.MediaFolder, response.data));
        },
        { success: 'engage.notification.create.folder.success' }
    );

    const onSubmit = useCallback(
        async (formValues: FolderFormFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: isSubmitting || hasErrors,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="ADD_MEDIA_FOLDER" color="#fff" size={26} />,
                    label: 'engage.modal.create.folder.title',
                }}
            >
                <FolderForm onSubmit={methods.handleSubmit(onSubmit)} />
            </Modal>
        </FormProvider>
    );
});

export default CreateMediaFolderForm;
