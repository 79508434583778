import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { createTemplateUrl } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';

import DeleteTemplatePrompt, {
    DeleteTemplatePromptProps,
} from 'src/components/Modals/Library/TemplateLibrary/Components/Modal/DeleteTemplatePrompt';
import { emitOnTemplateDeleted } from 'src/components/Modals/Library/TemplateLibrary/Events/onTemplateDeletedEvent';

const useTemplateLibraryListActions = (): ActionGroup<DiscriminatedEntity<LibraryModelsTemplateRelations>>[] => {
    const t = useTranslation();
    const { workspace } = useWorkspace();
    const { hasRole } = useUserInformation();

    const handleOpenInContentEditor = useCallback(
        (template: DiscriminatedEntity<LibraryModelsTemplateRelations>) => {
            window.open(createTemplateUrl(template, workspace), '_blank');
        },
        [workspace]
    );

    const handleDeleteTemplate = useCallback((template: DiscriminatedEntity<LibraryModelsTemplateRelations>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsTemplateRelations>, DeleteTemplatePromptProps>(
            DeleteTemplatePrompt,
            { template }
        ).then(emitOnTemplateDeleted);
    }, []);

    return [
        {
            actions: [
                {
                    handler: handleOpenInContentEditor,
                    label: t.trans('engage.templates.content-editor.open'),
                    name: 'open-in-editor',
                },
                {
                    handler: handleDeleteTemplate,
                    isDisabled: () => !hasRole(['ENGAGE_LIBRARY_TEMPLATE_VIEW', 'ENGAGE_LIBRARY_TEMPLATE_DELETE']),
                    label: t.trans('common.action.delete'),
                    name: 'delete',
                },
            ],
            name: 'template-library-actions',
        },
    ];
};

export default useTemplateLibraryListActions;
