import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { LibraryModelsMedia, LibraryModelsMediaTypes } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { convertMediaDuration, extractMediaDuration } from '@zetadisplay/engage-components/utils/media';
import { Icon } from '@zetadisplay/zeta-ui-components';

import MediaFileForm, { MediaFileFormFields, minimumDuration } from 'src/components/Forms/MediaFileForm';
import EditMediaFileInformation from 'src/components/Modals/PlaylistSetup/EditMediaFileForm/EditMediaFileInformation';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

export type UpdateMediaFileFormProps = {
    media: DiscriminatedEntity<LibraryModelsMedia>;
} & NiceModalHocProps;

const EditMediaFileForm = NiceModal.create<UpdateMediaFileFormProps>(({ media }) => {
    const modal = useModal();
    const methods = useForm<MediaFileFormFields>({
        defaultValues: {
            duration: extractMediaDuration(media),
            name: media.name,
            url: media.url,
            urlInternal: media.urlInternal,
        },
    });

    const { errors, isSubmitting } = methods.formState;
    const hasErrors = Object.keys(errors).length !== 0;
    const useDuration = [LibraryModelsMediaTypes.Image, LibraryModelsMediaTypes.WebPage].includes(media.type);
    const useURL = media.type === LibraryModelsMediaTypes.WebPage;

    const onSubmit = useCallback(
        async (formValues: MediaFileFormFields) => {
            const duration = convertMediaDuration(formValues.duration?.minutes || 0, formValues.duration?.seconds || 0);

            modal.resolve({
                ...media,
                ...formValues,
                duration: duration >= minimumDuration ? duration : minimumDuration,
            });
            modal.hide();
        },
        [media, modal]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: isSubmitting || hasErrors,
                            name: 'common.action.continue',
                            label: 'continue',
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type={getIconType(media)} />,
                    label: 'engage.modal.edit.media.title',
                    labelParams: [media.name || ''],
                }}
            >
                <MediaFileForm onSubmit={methods.handleSubmit(onSubmit)} useDuration={useDuration} useURL={useURL} />
                <EditMediaFileInformation />
            </Modal>
        </FormProvider>
    );
});

export default EditMediaFileForm;
