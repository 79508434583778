import React from 'react';
import { Typography } from '@mui/material';
import { LibraryModelsFolder } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '&:not(:last-of-type)::after': {
            content: '" • "',
            marginRight: 4,
        },
    },
}));

type Props = { item: DiscriminatedEntity<LibraryModelsFolder> };

const MediaFolderSubtitle = ({ item }: Props) => {
    const { classes, cx } = useStyles();

    const t = useTranslation();
    const mediaFolderType =
        item.sharedTo || item.sharedFrom
            ? 'engage.model.media.folder.type.shared'
            : 'engage.model.media.folder.type.folder';

    return (
        <div className={cx(classes.root)} data-testid="media-folder-subtitle">
            <Typography
                variant="subtitle1"
                className={cx(classes.text)}
                data-testid={`media-folder-subtitle-type-${item.id}`}
            >
                {t.trans(mediaFolderType)}
            </Typography>
        </div>
    );
};

export default MediaFolderSubtitle;
