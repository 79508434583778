import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client';
import { TextInput } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PowerScheduleSelectionModal, {
    PowerScheduleSelectionModalProps,
} from 'src/components/Modals/PowerScheduleSetup/PowerScheduleSelectionModal';
import { whitespace } from 'src/utils/Form/validators';
import getTimestampInMilliseconds from 'src/utils/GetTimestampInMilliseconds';
import { PowerScheduleFormData, SpecialDayData } from 'src/views/PowerScheduleSetupView';
import SpecialPowerScheduleFormSpecialDay from 'src/views/PowerScheduleSetupView/Components/PowerScheduleForm/SpecialPowerScheduleForm/SpecialPowerScheduleFormSpecialDay';
import { SpecialDayState } from 'src/views/PowerScheduleSetupView/Utils/powerScheduleFormEnums';

const useStyles = makeStyles()(() => ({
    createValueAction: {
        display: 'flex',
        justifyContent: 'right',
        marginBottom: '10px',
        marginTop: '10px',
    },
}));

const SpecialPowerScheduleForm = () => {
    const { classes } = useStyles();
    const t = useTranslation();

    const { formState, control, getValues, setValue, watch } = useFormContext<PowerScheduleFormData>();
    const { append, fields, remove } = useFieldArray<PowerScheduleFormData, 'specialPowerSchedule.specialDays', 'key'>({
        keyName: 'key',
        name: 'specialPowerSchedule.specialDays',
        control,
    });
    const { errors } = formState;
    const specialDays = watch('specialPowerSchedule.specialDays');

    const createSpecialScheduleValue = useCallback(() => {
        const newSpecialDay: SpecialDayData = {
            name: '',
            date: new Date(),
            state: SpecialDayState.NEW,
            startTime: null,
            endTime: null,
            enabled: true,
            timestamp: getTimestampInMilliseconds(),
        };

        append([newSpecialDay], { shouldFocus: true });
    }, [append]);

    const handleDateChanged = useCallback(
        (i: number, date: Date | null) => {
            return setValue(`specialPowerSchedule.specialDays.${i}.date`, date);
        },
        [setValue]
    );

    const handleEndTimeChanged = useCallback(
        (i: number, date: Date | null) => {
            return setValue(`specialPowerSchedule.specialDays.${i}.endTime`, date);
        },
        [setValue]
    );

    const handleStartTimeChanged = useCallback(
        (i: number, date: Date | null) => {
            return setValue(`specialPowerSchedule.specialDays.${i}.startTime`, date);
        },
        [setValue]
    );

    const handleSelectedPowerSchedules = useCallback(
        (powerSchedules: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>[]) => {
            setValue('specialPowerSchedule.powerSchedules', powerSchedules);
        },
        [setValue]
    );

    const handleOnRemoveSpecialDay = useCallback(
        (specialDay: SpecialDayData) => {
            if (specialDays === undefined || specialDays === null) {
                return;
            }

            const values = fields.map((field, index) => ({ ...field, ...specialDays[index] } as SpecialDayData));
            const specialScheduleIndex = values.findIndex((value) =>
                value.timestamp !== undefined ? value.timestamp === specialDay.timestamp : value.id === specialDay.id
            );

            remove(specialScheduleIndex);
        },
        [specialDays, fields, remove]
    );

    const resolveSelectedPowerSchedules = useCallback(
        async (powerSchedules?: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>[]) => {
            if (powerSchedules === undefined || powerSchedules.length === 0) {
                return [];
            }

            const existingPowerSchedules = getValues('specialPowerSchedule.powerSchedules') || [];
            return existingPowerSchedules.concat(
                powerSchedules.filter((item) => existingPowerSchedules.indexOf(item) < 0)
            );
        },
        [getValues]
    );

    return (
        <div data-testid="special-power-schedule-form">
            <Typography variant="h5" sx={{ margin: '0 0 10px' }}>
                {t.trans('engage.players.setup.power_schedule.special.title')}
            </Typography>
            <TextInput
                defaultLabel="common.form.input.name"
                error={errors.specialPowerSchedule?.name}
                name="specialPowerSchedule.name"
                required
                rules={{
                    required: 'common.validation.required',
                    validate: { whitespace },
                }}
            />
            <div className={classes.createValueAction}>
                <Button
                    label={`+ ${t.trans('engage.modal.assign.new.power_schedule')}`}
                    name="assign-new-power-schedule"
                    onClick={() =>
                        NiceModal.show<
                            DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>[],
                            PowerScheduleSelectionModalProps
                        >(PowerScheduleSelectionModal, { resolveSelectedPowerSchedules }).then(
                            handleSelectedPowerSchedules
                        )
                    }
                />
                <Button
                    label={`+ ${t.trans('engage.modal.add.new.special_schedule')}`}
                    name="add-new-special-schedule"
                    onClick={createSpecialScheduleValue}
                />
            </div>
            <div data-testid="specialDays">
                {specialDays?.map((specialDay, index) => (
                    <SpecialPowerScheduleFormSpecialDay
                        index={index}
                        key={specialDay.timestamp}
                        onDateChange={(date) => handleDateChanged(index, date)}
                        onEndTimeChange={(date) => handleEndTimeChanged(index, date)}
                        onRemoveSpecialDay={handleOnRemoveSpecialDay}
                        onStartTimeChange={(date) => handleStartTimeChanged(index, date)}
                        specialDay={specialDay}
                    />
                ))}
            </div>
        </div>
    );
};

export default SpecialPowerScheduleForm;
