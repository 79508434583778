import React, { ReactNode, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import {
    ConfigModelsWorkspaceBasic,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
    PublishingModelsCampaignCampaignRules,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { createSchedulesFromPlaylist } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Schedule, ScheduleToString } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';
import { DateTime } from 'luxon';

import FeatureFlag from 'src/components/FeatureFlag';
import { availableFeatures } from 'src/hooks/useIsFeatureEnabled';
import { getPlaybackPriorityTranslation } from 'src/views/PlaylistView/Utils/PlaylistPlaybackDetails';

const { WHITE } = themeOptions.colors;

const useStyles = makeStyles()((theme) => ({
    detailsContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: '5px',
    },
    detailSubject: {
        fontSize: 13,
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    information: {
        display: 'flex',
        flexGrow: 1,
    },
    text: {
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
        color: WHITE,
    },
    schedules: {
        'p > span': {
            display: 'block',
        },
    },
    row: {
        display: 'flex',
        marginBottom: 4,
    },
}));

const getPlaybackModeTranslation = (mixingMode?: PublishingModelsCampaignCampaignRules) => {
    const translations = {
        [PublishingModelsCampaignCampaignRules.Shuffled]: 'engage.playlist.setup.mode.option.shuffled',
        [PublishingModelsCampaignCampaignRules.Grouped]: 'engage.playlist.setup.mode.option.grouped',
        [PublishingModelsCampaignCampaignRules.Exclusive]: 'engage.playlist.setup.mode.option.exclusive',
        [PublishingModelsCampaignCampaignRules.OnDemand]: 'engage.playlist.setup.mode.option.on-demand',
    };

    if (mixingMode === undefined) {
        return 'engage.playlist.setup.mode.option.unknown';
    }

    return translations[mixingMode];
};

// Inject workspace that we get from PublishingModelsPlaylistPlaylistCampaignInfo
export type PlaylistItem = PublishingCampaignSchedulingCampaignInfoWithMediaSchedules & {
    workspace?: ConfigModelsWorkspaceBasic;
};

type Props = {
    item: DiscriminatedEntity<PlaylistItem>;
};

const PlayerPlaylistDetails = ({ item }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const timezone = DateTime.local().zoneName;

    const schedules = useMemo(() => {
        return createSchedulesFromPlaylist(item);
    }, [item]);

    const renderInformationDetail = (detailSubject: string, detailValue: ReactNode, flexGrow?: number) => (
        <Box sx={{ display: 'flex', flex: flexGrow || 1 }}>
            <Typography className={cx(classes.detailSubject, classes.text)}>{detailSubject}:</Typography>
            <Typography className={cx(classes.text)}>{detailValue || '-'}</Typography>
        </Box>
    );

    return (
        <div className={classes.detailsContainer}>
            <div data-testid="workspace" className={classes.row}>
                {renderInformationDetail(t.trans('engage.workspace.label'), item.workspace?.name, 1)}

                {renderInformationDetail(
                    t.trans('common.detail.updated'),
                    item.modified && new Date(item.modified).toLocaleString()
                )}
            </div>

            <div data-testid="start-stop" className={classes.row}>
                {renderInformationDetail(
                    t.trans('engage.playlist.details.start'),
                    item.start && new Date(item.start).toLocaleString()
                )}

                {renderInformationDetail(
                    t.trans('engage.playlist.details.end'),
                    item.end && new Date(item.end).toLocaleString()
                )}
            </div>

            <div data-testid="playback-mode-priority" className={classes.row}>
                {renderInformationDetail(
                    t.trans('engage.playlist.details.playback-mode'),
                    t.trans(getPlaybackModeTranslation(item.mixingMode))
                )}
                <FeatureFlag feature={availableFeatures.playlistPriority}>
                    {item.priority && (
                        <>
                            {renderInformationDetail(
                                t.trans('engage.playlist.details.playback-priority'),
                                t.trans(getPlaybackPriorityTranslation(item.priority))
                            )}
                        </>
                    )}
                </FeatureFlag>
            </div>

            <div data-testid="scheduling" className={cx(classes.row, classes.schedules)}>
                {renderInformationDetail(
                    t.trans('engage.playlist.setup.scheduling.title'),
                    schedules?.map((schedule: Schedule) => (
                        <ScheduleToString key={schedule.id} schedule={schedule} timezone={timezone} />
                    ))
                )}
            </div>
        </div>
    );
};

export default PlayerPlaylistDetails;
