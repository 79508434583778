import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LibraryModelsFolder, LibraryModelsMedia } from '@zetadisplay/engage-api-client';
import { ViewHeader } from '@zetadisplay/engage-components';
import { useBreadcrumbs, useToolbarOptions } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useCurrentMediaFolder, useLibraryContent } from '@zetadisplay/engage-components/modules/library/hooks';
import { FilterFields, keywordFilter } from '@zetadisplay/engage-components/modules/search';
import { emitOnSearch, SearchEventScope } from '@zetadisplay/engage-components/modules/search/events';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import {
    buildUuidNamePathNode,
    getRoutePathNodes,
    uuidNamePathNodeRegex,
} from '@zetadisplay/engage-components/utils/route';
import { useSelectableItems } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import queryString, { ParsedQuery } from 'query-string';

import ViewToolbar from 'src/components/ViewToolbar';
import { MAX_ITEMS_PER_PAGE } from 'src/config/appConfig';
import { LIBRARY_BASE_PATH } from 'src/constants/Paths';
import useResetSearchOnLocationChange from 'src/hooks/Search/useResetSearchOnLocationChange';
import useViewTitle from 'src/hooks/useViewTitle';
import createRootNode from 'src/utils/breadcrumbs/createRootNode';
import withDefaultLayout from 'src/utils/Layout/withDefaultLayout';
import LibraryViewActions from 'src/views/LibraryView/Components/LibraryViewActions';
import LibraryViewBulkActions from 'src/views/LibraryView/Components/LibraryViewBulkActions';
import LibraryViewList from 'src/views/LibraryView/Components/LibraryViewList';
import useLibraryViewEvents from 'src/views/LibraryView/Hooks/useLibraryViewEvents';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',

        [theme.breakpoints.up('lg')]: {
            paddingLeft: 148,
            paddingRight: 152,
        },
    },
}));

export type LibrarySearchQueryParams = ParsedQuery & {
    keyword?: string;
};

export type LibraryItemsType = DiscriminatedEntity<LibraryModelsMedia> | DiscriminatedEntity<LibraryModelsFolder>;

const LibraryView = () => {
    useViewTitle('engage.library.title');

    const { classes } = useStyles();
    const currentMediaFolderId = useCurrentMediaFolder();
    const location = useLocation();
    const searchRef = useResetSearchOnLocationChange();
    const toolbarOptions = useToolbarOptions();
    const { workspace, workspaceSettings } = useWorkspace();

    const [pageSize, setPageSize] = useState<number>(MAX_ITEMS_PER_PAGE);
    const searchQueryParams = useMemo(() => {
        return queryString.parse(location.search) as LibrarySearchQueryParams;
    }, [location.search]);

    const { content, folders, getNextResultPage, total } = useLibraryContent({ currentMediaFolderId, pageSize });
    const isLoading = folders.isLoading || content.isLoading;

    const items = useMemo(() => {
        return ((folders?.data || []) as LibraryItemsType[]).concat(content.data);
    }, [content.data, folders?.data]);

    const { selectItem, selectAllItems, selectedItems, shouldSelectAll } = useSelectableItems<LibraryItemsType>(
        items,
        location.pathname
    );

    useLibraryViewEvents(content, folders);

    const rootNode = useMemo(() => {
        if (workspaceSettings?.mediaRootFolder === undefined) {
            return undefined;
        }

        return createRootNode(workspaceSettings.mediaRootFolder, workspace.name, LIBRARY_BASE_PATH);
    }, [workspace.name, workspaceSettings?.mediaRootFolder]);

    const pathNodes = getRoutePathNodes(location?.pathname, uuidNamePathNodeRegex, buildUuidNamePathNode);
    const breadcrumbs = useBreadcrumbs(pathNodes, LIBRARY_BASE_PATH, rootNode, true);

    const handleSelectAllItems = useCallback(
        (availableItems: LibraryItemsType[], all: boolean) => {
            if (selectAllItems === undefined) {
                return;
            }

            selectAllItems(availableItems, all);
        },
        [selectAllItems]
    );

    /**
     * Temporary solution (legendary) to bypass technical limitations related to select all and API pagination + frontend things
     * which makes it challenging to load all data in small batches programmatically
     */
    useEffect(() => {
        if (pageSize === MAX_ITEMS_PER_PAGE && shouldSelectAll) {
            setPageSize(1000);
        } else if (pageSize !== MAX_ITEMS_PER_PAGE && !shouldSelectAll) {
            setPageSize(MAX_ITEMS_PER_PAGE);
        }
    }, [pageSize, shouldSelectAll]);

    return (
        <main className={classes.root}>
            <LibraryViewActions />
            <ViewHeader title="engage.library.title" />
            <ViewToolbar
                breadcrumbs={breadcrumbs}
                options={toolbarOptions}
                renderBulkActions={() => (
                    <LibraryViewBulkActions
                        items={items}
                        selectedItems={selectedItems}
                        selectAllItems={handleSelectAllItems}
                    />
                )}
                ref={searchRef}
                renderReturnButton
                searchOptions={{
                    filters: {
                        [FilterFields.KEYWORD]: { ...keywordFilter, defaultValue: searchQueryParams.keyword },
                    },
                    onChangeCallback: (value) => {
                        emitOnSearch({ scope: SearchEventScope.MEDIA_FILE | SearchEventScope.MEDIA_FOLDER, value });
                    },
                }}
            />
            <LibraryViewList
                basePath={LIBRARY_BASE_PATH}
                infiniteScrolling={!total || total > items.length}
                loading={isLoading}
                multiSelect
                nodes={items}
                onInfiniteScroll={getNextResultPage}
                onItemSelect={selectItem}
                path={pathNodes}
                selectable
                selectedItems={selectedItems}
            />
        </main>
    );
};
export default withDefaultLayout(LibraryView);
