import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Grid from '@mui/material/Grid';
import { LibraryModelsPersistZetaCastTemplate } from '@zetadisplay/engage-api-client';
import { SwitchInput, TextInput } from '@zetadisplay/engage-components';
import { createDiscriminatedEntity, EntityDiscriminators } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import usePendingPromise from 'src/hooks/usePendingPromise';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';
import { whitespace } from 'src/utils/Form/validators';

type CreateZetaCastTemplateFormFields = Pick<
    LibraryModelsPersistZetaCastTemplate,
    'height' | 'liveTemplate' | 'name' | 'sourceUrl' | 'width'
>;

const CreateZetaCastTemplateForm = NiceModal.create(() => {
    const api = useApi();
    const methods = useForm<CreateZetaCastTemplateFormFields>();
    const modal = useModal();
    const t = useTranslation();
    const { workspace } = useWorkspace();

    const { errors, isSubmitting } = methods.formState;
    const hasErrors = Object.keys(errors).length !== 0;

    const submitAction = usePendingPromise(
        async (formValues: CreateZetaCastTemplateFormFields) => {
            return api.templates
                .createZetaCastTemplate({
                    body: { ...formValues, description: '' },
                    workspaceid: workspace.id,
                })
                .then((response) => createDiscriminatedEntity(EntityDiscriminators.Template, response.data));
        },
        { success: 'engage.notification.create.template.success' }
    );

    const onSubmit = useCallback(
        async (formValues: CreateZetaCastTemplateFormFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(createDiscriminatedEntity(EntityDiscriminators.Template, response));
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: isSubmitting || hasErrors,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="UPLOAD" />,
                    label: 'engage.action.upload.template',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            defaultLabel="common.form.input.name"
                            error={errors.name}
                            name="name"
                            rules={{
                                required: 'common.validation.required',
                                validate: { whitespace },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            defaultLabel="common.form.input.url"
                            error={errors.sourceUrl}
                            name="sourceUrl"
                            rules={{
                                required: 'common.validation.required',
                                validate: { whitespace },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SwitchInput
                            // TODO: Update label to 'common.form.input.template.live' after R18 upgrade
                            label={t.trans('Live template')}
                            name="liveTemplate"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            defaultLabel="common.form.input.width"
                            error={errors.width}
                            name="width"
                            rules={{
                                required: 'common.validation.required',
                                validate: { whitespace },
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            defaultLabel="common.form.input.height"
                            error={errors.height}
                            name="height"
                            rules={{
                                required: 'common.validation.required',
                                validate: { whitespace },
                            }}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </FormProvider>
    );
});

export default CreateZetaCastTemplateForm;
