import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OidcSecure, useOidcAccessToken } from '@axa-fr/react-oidc';
import NiceModal from '@ebay/nice-modal-react';
import { UserInformationProvider } from '@zetadisplay/engage-components/modules/auth';
import { DependencyInjectionProvider } from '@zetadisplay/engage-components/modules/dependency-injection';
import { WorkspaceProvider } from '@zetadisplay/engage-components/modules/workspaces';

import { DEFAULT_OIDC_CONFIGURATION_NAME } from 'src/config/appConfig';
import Routing from 'src/routing/Routing';

const ProtectedDependencyInjectionProvider = () => {
    const { accessToken } = useOidcAccessToken(DEFAULT_OIDC_CONFIGURATION_NAME);
    const navigate = useNavigate();

    return (
        <OidcSecure configurationName={DEFAULT_OIDC_CONFIGURATION_NAME}>
            <DependencyInjectionProvider accessToken={accessToken}>
                <UserInformationProvider>
                    <WorkspaceProvider onSetWorkspace={() => navigate('/')}>
                        <NiceModal.Provider>
                            <Routing />
                        </NiceModal.Provider>
                    </WorkspaceProvider>
                </UserInformationProvider>
            </DependencyInjectionProvider>
        </OidcSecure>
    );
};

export default ProtectedDependencyInjectionProvider;
