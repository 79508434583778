import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { LibraryModelsMedia, LibraryModelsMediaTypes } from '@zetadisplay/engage-api-client';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { convertMediaDuration, extractMediaDuration } from '@zetadisplay/engage-components/utils/media';

import MediaFileForm, { MediaFileFormFields, minimumDuration } from 'src/components/Forms/MediaFileForm';
import usePendingPromise from 'src/hooks/usePendingPromise';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

export type UpdateMediaFileFormProps = {
    media: LibraryModelsMedia;
} & NiceModalHocProps;

const UpdateMediaFileForm = NiceModal.create<UpdateMediaFileFormProps>(({ media }) => {
    const api = useApi();
    const modal = useModal();
    const { workspace } = useWorkspace();
    const methods = useForm<MediaFileFormFields>({
        defaultValues: {
            duration: extractMediaDuration(media),
            name: media.name,
            url: media.url,
            urlInternal: media.urlInternal,
        },
    });

    const { errors, isSubmitting } = methods.formState;
    const hasErrors = Object.keys(errors).length !== 0;
    const useDuration = [LibraryModelsMediaTypes.Image, LibraryModelsMediaTypes.WebPage].includes(media.type);
    const useURL = media.type === LibraryModelsMediaTypes.WebPage;

    const submitAction = usePendingPromise(
        async (formValues: MediaFileFormFields) => {
            const duration = convertMediaDuration(formValues.duration?.minutes || 0, formValues.duration?.seconds || 0);

            return api.media
                .updateMedia({
                    body: {
                        duration: duration >= minimumDuration ? duration : minimumDuration,
                        folderId: media.folderId,
                        name: formValues.name,
                        url: formValues.url || null,
                        urlInternal: formValues.urlInternal || null,
                        type: media.type,
                    },
                    mediaid: media.id,
                    workspaceid: workspace.id,
                })
                .then((response) => mediaFileMapper(response.data));
        },
        {
            success: { message: 'engage.notification.edit.media.success', params: [media.name] },
        }
    );

    const onSubmit = useCallback(
        async (formValues: MediaFileFormFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: isSubmitting || hasErrors,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    label: 'engage.modal.edit.media.title',
                    labelParams: [media.name || ''],
                }}
            >
                <MediaFileForm onSubmit={methods.handleSubmit(onSubmit)} useDuration={useDuration} useURL={useURL} />
            </Modal>
        </FormProvider>
    );
});

export default UpdateMediaFileForm;
