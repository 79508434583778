import React, { FormEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SliderInput, SwitchInput, TextInput } from '@zetadisplay/engage-components';
import { createMediaDurationMarks } from '@zetadisplay/engage-components/utils/media/';
import { useTranslation } from '@zetadisplay/zeta-localization';

import { validUrl, whitespace } from 'src/utils/Form/validators';

export const minimumDuration = 3;
export const minimumMinutesDuration = 0;
export const maximumMinutesDuration = 60;
export const minimumSecondsDuration = 0;
export const maximumSecondsDuration = 59;

export type MediaFileFormFields = {
    duration?: { minutes?: number; seconds?: number };
    name: string;
    url?: string | null;
    urlInternal?: boolean | null;
};

type MediaFileFormProps = {
    onSubmit: FormEventHandler<HTMLFormElement>;
    useDuration?: boolean;
    useURL?: boolean;
};

const MediaFileForm = ({ onSubmit, useDuration = false, useURL = false }: MediaFileFormProps) => {
    const { formState } = useFormContext<MediaFileFormFields>();
    const t = useTranslation();

    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        defaultLabel="common.form.input.name"
                        error={formState.errors.name}
                        name="name"
                        rules={{
                            required: 'common.validation.required',
                            validate: { whitespace },
                        }}
                    />
                </Grid>
                {useURL && (
                    <>
                        <Grid item xs={12}>
                            <TextInput
                                defaultLabel="common.form.input.url"
                                error={formState.errors.url}
                                name="url"
                                rules={{
                                    required: 'common.validation.required',
                                    validate: { validUrl },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SwitchInput defaultChecked={false} label="Internal URL" name="urlInternal" />
                        </Grid>
                    </>
                )}
                {useDuration && (
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h6">
                            {t.trans('common.form.input.duration')}
                        </Typography>
                        <SliderInput
                            defaultValue={minimumMinutesDuration}
                            label="Minutes"
                            marks={createMediaDurationMarks(maximumMinutesDuration, 5)}
                            max={maximumMinutesDuration}
                            min={minimumMinutesDuration}
                            name="duration.minutes"
                            unit="m"
                        />
                        <SliderInput
                            defaultValue={minimumMinutesDuration}
                            label="Seconds"
                            marks={createMediaDurationMarks(maximumSecondsDuration, 5)}
                            max={maximumSecondsDuration}
                            min={minimumSecondsDuration}
                            name="duration.seconds"
                            unit="s"
                        />
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default MediaFileForm;
