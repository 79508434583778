import React from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ConfigModelsGroupPersistModel } from '@zetadisplay/engage-api-client/models/config-models-group-persist-model';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    root: {
        paddingLeft: 4,
    },
}));

const AttributesConstraintsPlayers = () => {
    const { classes, cx } = useStyles();
    const api = useApi();
    const { workspace } = useWorkspace();
    const t = useTranslation();

    const attributesConstraints = useWatch<ConfigModelsGroupPersistModel, 'attributesConstraints'>({
        defaultValue: [],
        name: 'attributesConstraints',
    });

    const { result: players = [] } = useAsyncAbortable(
        async (signal) => {
            return api.players
                .getByAttributeConstraints(
                    {
                        body: attributesConstraints || [],
                        workspaceid: workspace.id,
                    },
                    { signal }
                )
                .then((response) => response.data);
        },

        [api.players, attributesConstraints, workspace.id]
    );

    return (
        <div className={cx(classes.root)} data-testid="groups-targeting">
            {players.length === 0 && <Typography>{t.trans('engage.players.targeting.no_results')}</Typography>}
            {players.map((player) => (
                <Typography key={player.id}>{player.friendlyName}</Typography>
            ))}
        </div>
    );
};

export default AttributesConstraintsPlayers;
