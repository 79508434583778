import React from 'react';
import { Box } from '@mui/material';

export type SidekickContentProps = React.PropsWithChildren;

const SidekickContent = ({ children }: SidekickContentProps) => {
    return (
        <Box data-testid="sidekick-content" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {children}
        </Box>
    );
};

export default SidekickContent;
