import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { ConfigModelsAttributeDetails, NetworkModelsPlayersPlayerRelations } from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';
import { Close } from 'styled-icons/material';

import PlayerSettingsChannelContext from './PlayerSettingsChannelContext';
import PlayerSettingsChannelLabelsAppendOverlay from './PlayerSettingsChannelLabels/PlayerSettingsChannelLabelsAppendOverlay';
import PlayerSettingsChannelLabelsDetails from './PlayerSettingsChannelLabels/PlayerSettingsChannelLabelsDetails';

type ChannelAttributePath = `channels.${number}.attributes`;

const useStyles = makeStyles()(() => ({
    drawerPaper: {
        backgroundColor: themeOptions.colors.BLACK,
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        marginLeft: 'auto',
        padding: '20px',
        width: '700px',
    },
}));

const PlayerSettingsChannelLabels = () => {
    const { classes } = useStyles();
    const { trans } = useTranslation();

    const { currentChannelIndex } = useContext(PlayerSettingsChannelContext);
    const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

    const watchedFields = useWatch<NetworkModelsPlayersPlayerRelations, ChannelAttributePath>({
        name: `channels.${currentChannelIndex}.attributes`,
    });

    const { append, fields, remove } = useFieldArray<NetworkModelsPlayersPlayerRelations, ChannelAttributePath, 'key'>({
        name: `channels.${currentChannelIndex}.attributes`,
        keyName: 'key',
    });

    const attributes = useMemo(
        () =>
            fields.map((field, index) => {
                if (watchedFields?.[index]) {
                    return { ...field, ...watchedFields[index] };
                }

                return field;
            }),
        [fields, watchedFields]
    );

    const handleLabelAdd = useCallback((attribute: ConfigModelsAttributeDetails) => append({ attribute }), [append]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '5px 0' }}>
            <Drawer
                anchor="bottom"
                classes={{ paper: classes.drawerPaper }}
                keepMounted
                onClose={() => setIsOverlayVisible(false)}
                open={isOverlayVisible}
                PaperProps={{ elevation: 0 }}
                SlideProps={{ direction: 'up' }}
                slotProps={{ backdrop: { invisible: true } }}
                variant="temporary"
            >
                <IconButton
                    onClick={() => setIsOverlayVisible(false)}
                    sx={{
                        backgroundColor: themeOptions.colors.CONTENT_DARK,
                        margin: '-4px -4px 0 auto',
                    }}
                >
                    <Close color={themeOptions.colors.DARKGRAY} data-testid="labels-overlay-close" size={22} />
                </IconButton>
                <Typography color={themeOptions.colors.WHITE} variant="h3">
                    {trans('engage.players.settings.labels.overlay.title')}
                </Typography>
                <PlayerSettingsChannelLabelsAppendOverlay onLabelAdd={handleLabelAdd} selectedAttributes={attributes} />
            </Drawer>
            <PlayerSettingsChannelLabelsDetails attributes={attributes} onRemove={remove} />
            <Box sx={{ paddingTop: '10px' }}>
                <Button
                    fullWidth
                    label={`${trans('common.action.add')} +`}
                    onClick={() => setIsOverlayVisible(true)}
                    sx={{
                        border: `2px dashed ${themeOptions.colors.DARKGRAY}`,
                        borderRadius: '4px',
                        height: 54,
                        margin: '0 !important',
                    }}
                    variant="text"
                />
            </Box>
        </Box>
    );
};

export default PlayerSettingsChannelLabels;
