import React, { useMemo } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Icon } from '@zetadisplay/zeta-ui-components';

import CreateZetaCastTemplateForm from 'src/components/Modals/Library/TemplateLibrary/Components/Modal/CreateZetaCastTemplateForm';
import { emitOnTemplateCreated } from 'src/components/Modals/Library/TemplateLibrary/Events/onTemplateCreatedEvent';
import ViewActions from 'src/components/ViewActions';

const TemplateLibraryActions = () => {
    const t = useTranslation();
    const { hasRole } = useUserInformation();

    const actions = useMemo(
        () => [
            {
                disabled: !hasRole(['ENGAGE_LIBRARY_TEMPLATE_VIEW', 'ENGAGE_LIBRARY_TEMPLATE_CREATE']),
                icon: <Icon type="UPLOAD" color="#fff" />,
                label: t.trans('engage.action.upload.template'),
                onClick: () => {
                    NiceModal.show<DiscriminatedEntity<LibraryModelsTemplateRelations>, NiceModalHocProps>(
                        CreateZetaCastTemplateForm
                    ).then(emitOnTemplateCreated);
                },
                name: 'upload-original-template',
            },
        ],
        [hasRole, t]
    );

    return <ViewActions actions={actions} label="template-library-actions" context="template-library" />;
};

export default TemplateLibraryActions;
