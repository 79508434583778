import React, { useContext } from 'react';
import { useFormState } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';

import PlayerSettingsChannelContext from './PlayerSettingsChannelContext';

const PlayerSettingsChannelSettings = () => {
    const { currentChannelIndex } = useContext(PlayerSettingsChannelContext);
    const { isLoading } = useFormState();

    return (
        <Box>
            <Stack direction="row" spacing={1} sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
                <TextInput
                    defaultLabel="common.form.input.width"
                    loading={isLoading}
                    name={`channels.${currentChannelIndex}.width`}
                    variant="filled"
                />
                <TextInput
                    defaultLabel="common.form.input.height"
                    loading={isLoading}
                    name={`channels.${currentChannelIndex}.height`}
                    variant="filled"
                />
            </Stack>
        </Box>
    );
};

export default PlayerSettingsChannelSettings;
