export const isSameValue = (comparison: string) => (value: string) => {
    if (value !== comparison) {
        return 'common.validation.same';
    }

    return true;
};

export const validUrl = (value: string) => {
    if (!/^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(value)) {
        return 'common.validation.invalid.url';
    }

    return true;
};

export const whitespace = (value: string) => {
    if (value.startsWith(' ') || value.endsWith(' ')) {
        return 'common.validation.whitespace';
    }

    return true;
};
