import React from 'react';
import { Typography } from '@mui/material';
import { LibraryModelsMedia, LibraryModelsMediaTypes } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: theme.palette.background.disabledText,
        display: 'inline-block',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '&:not(:last-of-type)::after': {
            content: '" • "',
            marginRight: 4,
        },
    },
}));

const getMediaFileMediaTypeLabel = (mediaType: LibraryModelsMediaTypes) => {
    switch (mediaType) {
        case LibraryModelsMediaTypes.Image:
            return 'engage.model.media.file.type.image';
        case LibraryModelsMediaTypes.TemplateHtml:
            return 'engage.model.media.file.type.template';
        case LibraryModelsMediaTypes.Video:
            return 'engage.model.media.file.type.video';
        case LibraryModelsMediaTypes.WebPage:
            return 'engage.model.media.file.type.url';
        case LibraryModelsMediaTypes.TemplateZetaCast:
            return 'engage.model.media.file.type.template';
        default:
            return 'engage.model.media.file.type.unknown';
    }
};

type Props = { item: DiscriminatedEntity<LibraryModelsMedia> };

const MediaFileSubtitle = ({ item }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const mediaItemDuration = item.duration ? `${item.duration} s` : undefined;
    const mediaItemSize =
        item.height && item.width
            ? t.trans('common.detail.dimensions', [item.width.toString(), item.height.toString()])
            : undefined;

    const generatedLabel = [t.trans(getMediaFileMediaTypeLabel(item.type)), mediaItemSize, mediaItemDuration]
        .filter((part) => part !== undefined)
        .join(' • ');

    return (
        <div className={cx(classes.root)} data-testid="media-file-subtitle">
            <Typography
                variant="subtitle1"
                className={cx(classes.text)}
                data-testid={`media-file-subtitle-type-${item.id}`}
            >
                {generatedLabel}
            </Typography>
        </div>
    );
};

export default MediaFileSubtitle;
