import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import {
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
    PublishingModelsCampaignCampaignStatus,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { createSchedulesFromPlaylist } from '@zetadisplay/engage-components/modules/playlist';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ScheduleToString } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { statuses } from 'src/views/PlaylistView/Utils/contants';

const useStyles = makeStyles()((theme) => ({
    text: {
        color: theme.palette.background.disabledText,
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '> span:not(:last-of-type)::after': {
            content: '" • "',
            marginLeft: 1,
            marginRight: 1,
        },
    },
}));

type Props = {
    playlist: DiscriminatedEntity<PublishingCampaignSchedulingCampaignInfoWithMediaSchedules>;
};

const PlaylistItemSubtitle = ({ playlist }: Props) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    const status = t.trans(
        (playlist.status && statuses.find((item) => item.key === playlist.status)?.label) ||
            'engage.playlist.status.disabled'
    );

    const schedules = useMemo(() => {
        return createSchedulesFromPlaylist(playlist);
    }, [playlist]);

    const renderScheduleInformation = () => {
        if (schedules.length === 1) {
            return <ScheduleToString schedule={schedules[0]} />;
        }
        if (schedules.length > 1) {
            return t.trans('engage.scheduling.count.information', [`${schedules.length}`], true);
        }

        return null;
    };

    return (
        <Typography
            className={cx(classes.text)}
            data-testid={`playlist-item-subtitle-${playlist.id}`}
            noWrap
            variant="subtitle1"
        >
            <span data-testid={`playlist-item-subtitle-status-${playlist.id}`}>{status}</span>

            {playlist.status !== PublishingModelsCampaignCampaignStatus.New && (
                <span data-testid={`playlist-item-subtitle-scheduling-${playlist.id}`}>
                    {renderScheduleInformation()}
                </span>
            )}

            {playlist?.modified && (
                <span data-testid={`playlist-item-subtitle-modified-${playlist.id}`}>
                    {[
                        t.trans('common.detail.updated'),
                        new Date(playlist.modified).toLocaleDateString(),
                        new Date(playlist.modified).toLocaleTimeString(),
                    ].join(' ')}
                </span>
            )}
        </Typography>
    );
};

export default PlaylistItemSubtitle;
