import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useCurrentMediaFolder } from '@zetadisplay/engage-components/modules/library/hooks';
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { convertMediaDuration } from '@zetadisplay/engage-components/utils/media';
import { Icon } from '@zetadisplay/zeta-ui-components';

import MediaFileForm, { MediaFileFormFields, minimumDuration } from 'src/components/Forms/MediaFileForm';
import usePendingPromise from 'src/hooks/usePendingPromise';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

const CreateURLMediaFileForm = NiceModal.create(() => {
    const api = useApi();
    const currentFolderId = useCurrentMediaFolder();
    const methods = useForm<MediaFileFormFields>();
    const modal = useModal();
    const { workspace } = useWorkspace();

    const { errors, isSubmitting } = methods.formState;
    const hasErrors = Object.keys(errors).length !== 0;

    const submitAction = usePendingPromise(
        async (formValues: MediaFileFormFields) => {
            if (formValues.url === undefined || formValues.url === null) {
                return undefined;
            }

            const duration = convertMediaDuration(formValues.duration?.minutes || 0, formValues.duration?.seconds || 0);

            return api.media
                .createUrlMedia({
                    body: {
                        duration: duration >= minimumDuration ? duration : minimumDuration,
                        folderId: currentFolderId,
                        name: formValues.name,
                        url: formValues.url,
                        urlInternal: formValues.urlInternal || false,
                    },
                    workspaceid: workspace.id,
                })
                .then((response) => mediaFileMapper(response.data));
        },
        { success: 'engage.notification.create.url.success' }
    );

    const onSubmit = useCallback(
        async (formValues: MediaFileFormFields) => {
            const response = await submitAction(formValues);
            if (response === undefined) {
                return;
            }

            modal.resolve(response);
            modal.hide();
        },
        [modal, submitAction]
    );

    return (
        <FormProvider {...methods}>
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: isSubmitting,
                            disabled: isSubmitting || hasErrors,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="ADD_URL_CONTENT" color="#fff" />,
                    label: 'engage.modal.create.url.title',
                }}
            >
                <MediaFileForm onSubmit={methods.handleSubmit(onSubmit)} useDuration useURL />
            </Modal>
        </FormProvider>
    );
});

export default CreateURLMediaFileForm;
