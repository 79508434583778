import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@mui/material';
import { Theme } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import TemplateLibraryActions from 'src/components/Modals/Library/TemplateLibrary/Components/TemplateLibraryActions';
import TemplateLibraryHeader from 'src/components/Modals/Library/TemplateLibrary/Components/TemplateLibraryHeader';
import TemplateLibraryList from 'src/components/Modals/Library/TemplateLibrary/Components/TemplateLibraryList';
import useTemplates from 'src/components/Modals/Library/TemplateLibrary/Hooks/useTemplates';
import ModalTransition from 'src/modules/Modal/Components/ModalTransition';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

const TemplateLibrary = NiceModal.create(() => {
    const modal = useModal();
    const { classes } = useStyles();
    const content = useTemplates();

    return (
        <Theme nested>
            <Dialog
                fullWidth
                onClose={() => modal.hide()}
                open={modal.visible}
                maxWidth="lg"
                TransitionComponent={ModalTransition}
                TransitionProps={{
                    onExited: () => modal.remove(),
                }}
            >
                <div className={classes.root} data-testid="template-library">
                    <TemplateLibraryHeader />
                    <TemplateLibraryActions />
                    <TemplateLibraryList
                        infiniteScrolling={!content.total || content?.total > content.data.length}
                        loading={content.isLoading}
                        nodes={content.data}
                        onInfiniteScroll={content.getNextResultPage}
                    />
                </div>
            </Dialog>
        </Theme>
    );
});

export default TemplateLibrary;
