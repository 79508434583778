import React, { useCallback } from 'react';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import {
    defaultCancelButton,
    defaultSubmitButton,
    Modal,
} from '@zetadisplay/engage-components/modules/modal/components';
import { useTranslation } from '@zetadisplay/zeta-localization';

export type ConfirmMediaFileOverwritePromptProps = {
    filename: string;
} & NiceModalHocProps;

const ConfirmMediaFileOverwritePrompt = NiceModal.create<ConfirmMediaFileOverwritePromptProps>(({ filename }) => {
    const modal = useModal();
    const t = useTranslation();

    const onSubmit = useCallback(async () => {
        modal.resolve(true);
        modal.hide();
    }, [modal]);

    const onCancel = useCallback(async () => {
        modal.resolve(false);
        modal.hide();
    }, [modal]);

    return (
        <Modal
            actions={{
                buttons: [
                    {
                        ...defaultCancelButton,
                        label: 'common.action.no',
                        name: 'no',
                        onClick: onCancel,
                    },
                    {
                        ...defaultSubmitButton,
                        label: 'common.action.yes',
                        name: 'yes',
                        onClick: onSubmit,
                    },
                ],
            }}
            dark
            PaperProps={{ elevation: 24 }}
            preventClosing
            title={{ label: t.trans('engage.modal.upload.file.overwrite.title', [filename]) }}
        >
            {t.trans('engage.modal.upload.file.overwrite.description')}
        </Modal>
    );
});

export default ConfirmMediaFileOverwritePrompt;
