import React, { memo, useCallback, useMemo } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import {
    LibraryModelsFolder,
    LibraryModelsMedia,
    LibraryModelsMediaStatus,
    LibraryModelsThumbStatus,
} from '@zetadisplay/engage-api-client';
import { ItemsView, PreviewDialog, SkeletonItemsView } from '@zetadisplay/engage-components';
import {
    DiscriminatedEntity,
    EntityDiscriminators,
    isMediaFile,
    isMediaFolder,
} from '@zetadisplay/engage-components/models';
import { useViewMode } from '@zetadisplay/engage-components/modules/options';
import { getIconType } from '@zetadisplay/engage-components/modules/view/utils';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { getPreviewUrl, getThumbnailUrl, isPreviewable } from '@zetadisplay/engage-components/utils/media';
import { generateRouteFromItem, RouteNode } from '@zetadisplay/engage-components/utils/route/route';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup, DropObject, DropTarget, ItemProps, NativeFilesDropObject } from '@zetadisplay/zeta-ui-components';
import { DefaultNotFoundImage } from '@zetadisplay/zeta-ui-components/assets';
import { DragObject } from '@zetadisplay/zeta-ui-components/components/drag-source';
import { useExpandableItems } from '@zetadisplay/zeta-ui-components/hooks';
import { IdentifiableObject, NamedObject } from '@zetadisplay/zeta-ui-components/models';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import UploadMediaFileForm, {
    availableFileExtensions,
    UploadMediaFileFormProps,
} from 'src/components/Modals/Library/MediaFile/UploadMediaFileForm';
import MediaSubtitle from 'src/components/Subtitles/Library/MediaSubtitle';
import Tag, { SupportedTags } from 'src/components/Tag';
import { LibraryItemsType } from 'src/views/LibraryView';
import LibraryListMediaDetails from 'src/views/LibraryView/Components/LibraryViewList/LibraryListMediaDetails';
import { emitOnMediaFileCreated } from 'src/views/LibraryView/Events/onMediaFileCreatedEvent';
import { emitOnMediaFileDragged } from 'src/views/LibraryView/Events/onMediaFileDraggedEvent';
import useLibraryListFolderActions from 'src/views/LibraryView/Hooks/useLibraryListFolderActions';
import useLibraryListMediaActions from 'src/views/LibraryView/Hooks/useLibraryListMediaActions';

const { BLACK } = themeOptions.colors;

const useStyles = makeStyles()(() => ({
    root: {
        flex: 1,
    },
    section: {
        borderTop: '1px solid',
        borderColor: '#D9D9D9',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
    },
    container: {
        height: '100%',
        flexGrow: 1,
    },
    info: {
        color: BLACK,
        padding: 48,
    },
}));

const getMediaFileStatusLabel = (status: LibraryModelsMediaStatus) => {
    switch (status) {
        case LibraryModelsMediaStatus.New:
            return 'engage.model.media.file.state.new';
        case LibraryModelsMediaStatus.Pending:
            return 'engage.model.media.file.state.pending';
        case LibraryModelsMediaStatus.Processing:
            return 'engage.model.media.file.state.processing';
        case LibraryModelsMediaStatus.Failed:
            return 'engage.model.media.file.state.failed';
        default:
            return undefined;
    }
};

const getMediaFileStatusColor = (status: LibraryModelsMediaStatus): SupportedTags => {
    switch (status) {
        case LibraryModelsMediaStatus.New:
            return 'GREEN';
        case LibraryModelsMediaStatus.Pending:
        case LibraryModelsMediaStatus.Processing:
            return 'YELLOW';
        case LibraryModelsMediaStatus.Failed:
            return 'RED';
        default:
            return 'DEFAULT';
    }
};

const getItemThumbnailUrl = (item: LibraryItemsType) => {
    if (isMediaFolder(item)) {
        return '';
    }

    return getThumbnailUrl(item) || DefaultNotFoundImage;
};

const isNativeFilesDropObject = (item: DropObject | NativeFilesDropObject): item is NativeFilesDropObject => {
    return 'files' in item;
};

type Props<T extends IdentifiableObject & NamedObject> = {
    basePath?: string;
    infiniteScrolling?: boolean;
    loading?: boolean;
    multiSelect?: boolean;
    nodes: T[];
    onInfiniteScroll?: () => void;
    onItemSelect?: (item: T) => void;
    path: RouteNode[];
    selectable?: boolean;
    selectedItems?: Record<string, T>;
};

const LibraryViewList = ({
    basePath = '/library',
    infiniteScrolling = false,
    loading = false,
    multiSelect = false,
    nodes = [],
    onInfiniteScroll,
    onItemSelect = undefined,
    path = [],
    selectable = false,
    selectedItems = {},
}: Props<LibraryItemsType>) => {
    const { expandedItems, expandItem } = useExpandableItems(nodes);
    const mediaFileActions = useLibraryListMediaActions();
    const mediaFolderActions = useLibraryListFolderActions();
    const history = useNavigate();
    const { classes } = useStyles();
    const t = useTranslation();
    const { viewMode } = useViewMode();
    const { workspaceSettings } = useWorkspace();

    const generateRoute = useCallback(
        (item: DiscriminatedEntity<LibraryModelsFolder>) => {
            return generateRouteFromItem(basePath, item, path);
        },
        [basePath, path]
    );

    const onDropMediaFile = useCallback(
        (source: DragObject<LibraryItemsType> | NativeFilesDropObject, item: LibraryItemsType | undefined) => {
            if (isNativeFilesDropObject(source) || item === undefined) {
                return undefined;
            }

            // TODO: We need to refactor item check earlier to actually tell TypeScript that item is a MediaFile
            // Type casting is not the best solution here
            return emitOnMediaFileDragged({
                source: source as DragObject<DiscriminatedEntity<LibraryModelsMedia>>,
                target: item as LibraryModelsMedia,
            });
        },
        []
    );

    // TODO: We need to most likely filter files which are not allowed to be uploaded
    const onDropNativeFile = useCallback(
        (item: DropObject | NativeFilesDropObject) => {
            if (!isNativeFilesDropObject(item)) {
                return undefined;
            }

            const allowedFileExtensions = availableFileExtensions.filter(
                (extension) => !workspaceSettings?.mediaSettings?.disabledMediaAssetExtensions?.includes(extension)
            );

            const filteredFiles = item.files.filter((file) => {
                const extension = file.name.split('.').pop()?.toLowerCase();
                if (extension === undefined) {
                    return false;
                }

                return allowedFileExtensions.includes(`.${extension}`);
            });

            if (filteredFiles.length === 0) {
                return undefined;
            }

            return NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>[], UploadMediaFileFormProps>(
                UploadMediaFileForm,
                { initialFiles: filteredFiles }
            ).then((files) => {
                files.forEach(emitOnMediaFileCreated);
            });
        },
        [workspaceSettings?.mediaSettings?.disabledMediaAssetExtensions]
    );

    const renderExpandedDetails = useCallback((item: LibraryItemsType) => {
        if (isMediaFolder(item)) {
            return null;
        }

        return <LibraryListMediaDetails item={item} />;
    }, []);

    const renderPreview = (item: LibraryItemsType) => {
        if (isMediaFolder(item) || item.thumbStatus !== LibraryModelsThumbStatus.HasThumb) {
            return null;
        }

        return (
            <PreviewDialog
                autoplay
                media={item}
                previewable={isPreviewable(item)}
                previewUrl={getPreviewUrl(item)}
                renderSubtitle={(media) => <MediaSubtitle item={media} />}
            />
        );
    };

    const renderStatus = useCallback(
        (item: LibraryItemsType) => {
            if (isMediaFolder(item)) {
                return null;
            }

            if (item.status === LibraryModelsMediaStatus.Ready) {
                return null;
            }

            return (
                <Tag
                    sx={{ margin: '5px' }}
                    text={t.trans(getMediaFileStatusLabel(item.status) || '')}
                    type={getMediaFileStatusColor(item.status)}
                />
            );
        },
        [t]
    );

    const itemProps: ItemProps<LibraryItemsType> = useMemo(
        () => ({
            acceptedDropTypes: [EntityDiscriminators.MediaFile],
            actions: [...mediaFolderActions, ...mediaFileActions] as ActionGroup<LibraryItemsType>[],
            canBeDragged: isMediaFile,
            canBeDroppedOn: isMediaFolder,
            clickable: isMediaFolder,
            expandable: isMediaFile,
            getItemType: (item) => item.discriminatorType || 'undefined',
            getItemIconType: getIconType,
            getThumbnailUrl: getItemThumbnailUrl,
            inViewThreshold: 0.3,
            multiSelect,
            onDrop: onDropMediaFile,
            onItemClick: (item) => (isMediaFolder(item) && history(generateRoute(item))) || undefined,
            onSelectItem: onItemSelect,
            renderExpandedDetails,
            renderPreview,
            renderSubtitle: (item) => <MediaSubtitle item={item} />,
            renderStatus,
            selectable,
            showThumbnail: isMediaFile,
        }),
        [
            generateRoute,
            onDropMediaFile,
            history,
            mediaFileActions,
            mediaFolderActions,
            multiSelect,
            onItemSelect,
            renderExpandedDetails,
            renderStatus,
            selectable,
        ]
    );

    const renderItemsView = () => {
        if (loading && nodes.length === 0) {
            return (
                <SkeletonItemsView
                    actions={[...mediaFileActions, ...mediaFolderActions] as ActionGroup<LibraryItemsType>[]}
                    viewMode={viewMode}
                />
            );
        }

        if (!nodes || nodes.length === 0) {
            return (
                <Typography align="center" className={classes.info}>
                    {t.trans('engage.library.content.no_content')}
                </Typography>
            );
        }

        return (
            <ItemsView
                expandedItems={expandedItems}
                infiniteScrolling={infiniteScrolling}
                isLoadingMore={loading && nodes.length > 0}
                ItemProps={itemProps}
                items={nodes}
                onExpandItemToggle={expandItem}
                onInfiniteScroll={onInfiniteScroll}
                selectedItems={selectedItems}
                viewMode={viewMode}
            />
        );
    };

    return (
        <div id="libraryList" className={classes.root}>
            <section className={classes.section}>
                <DropTarget
                    acceptTypes={[NativeTypes.FILE]}
                    onDrop={onDropNativeFile}
                    validateDrop={(_, monitor) => monitor.getItemType() === NativeTypes.FILE}
                >
                    <div className={classes.container}>{renderItemsView()}</div>
                </DropTarget>
            </section>
        </div>
    );
};

export default memo(LibraryViewList);
