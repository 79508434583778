import React, { useCallback, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Box, Divider, FormControlLabel, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Checkbox, ComponentLoader, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { kebabCase } from 'lodash';

import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

import usePowerSchedules from '../../../views/PowerScheduleSetupView/Components/PowerScheduleLibrary/Hooks/usePowerSchedules';

export const getPowerScheduleTestIdName = (name: string, isChecked: boolean) => {
    let testIdName = `powerSchedule-${kebabCase(name)}`;

    if (isChecked) {
        testIdName = `${testIdName}-checked`;
    }
    return testIdName;
};

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        height: 'calc(80vh - 138px)',
        justifyContent: 'center',
    },
}));

export type PowerScheduleSelectionModalProps = {
    initialSelectedPowerSchedules?: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>[];
    resolveSelectedPowerSchedules?: (
        powerSchedules?: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>[]
    ) => Promise<unknown>;
} & NiceModalHocProps;

const PowerScheduleSelectionModal = NiceModal.create<PowerScheduleSelectionModalProps>(
    ({ initialSelectedPowerSchedules, resolveSelectedPowerSchedules }) => {
        const { classes } = useStyles();
        const { data, isLoading } = usePowerSchedules();
        const modal = useModal();
        const t = useTranslation();
        const theme = useTheme();

        const [selectedPowerSchedules, setSelectedPowerSchedules] = useState(initialSelectedPowerSchedules || []);

        const onSelectPowerSchedules = useAsyncCallback(async () => {
            modal.resolve(
                resolveSelectedPowerSchedules
                    ? await resolveSelectedPowerSchedules(selectedPowerSchedules)
                    : selectedPowerSchedules
            );
            modal.hide();
        });

        const toggleSelectedPowerSchedule = useCallback(
            (powerSchedule: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>) => {
                setSelectedPowerSchedules((prevState) => {
                    return prevState.some((p) => p.id === powerSchedule.id)
                        ? prevState.filter((p) => p.id !== powerSchedule.id)
                        : [...prevState, powerSchedule];
                });
            },
            []
        );

        const checkIfPowerScheduleIsSelected = useCallback(
            (powerSchedule: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>) => {
                return !!selectedPowerSchedules.find((p) => p.id === powerSchedule.id);
            },
            [selectedPowerSchedules]
        );

        const checkIfEveryPowerScheduleIsSelected = useCallback(
            (
                powerSchedules: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>[]
            ): boolean => {
                return powerSchedules.every((powerSchedule) => {
                    return checkIfPowerScheduleIsSelected(powerSchedule);
                });
            },
            [checkIfPowerScheduleIsSelected]
        );

        const handleSelectPowerSchedule = (
            powerSchedule: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>
        ) => {
            if (selectedPowerSchedules.find((p) => p.id === powerSchedule.id) === undefined) {
                toggleSelectedPowerSchedule(powerSchedule);
            }
        };

        const isEverythingSelected = checkIfEveryPowerScheduleIsSelected(data || []);
        const handleSelectAll = () => {
            if (isEverythingSelected) {
                return setSelectedPowerSchedules([]);
            }

            return data.forEach((powerSchedule) => handleSelectPowerSchedule(powerSchedule));
        };

        const renderPowerScheduleItem = (
            powerSchedule: DiscriminatedEntity<EndpointsRootNetworkModelsPowerSchedulePowerScheduleInfo>,
            depth: number
        ) => {
            const isCheckedPowerSchedule = checkIfPowerScheduleIsSelected(powerSchedule);

            return (
                <div key={powerSchedule.id}>
                    <ListItem
                        data-testid={`${kebabCase(powerSchedule.name)}`}
                        dense
                        style={{ paddingLeft: theme.spacing(depth * 4) }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCheckedPowerSchedule}
                                    data-testid={getPowerScheduleTestIdName(powerSchedule.name, isCheckedPowerSchedule)}
                                    onChange={() => toggleSelectedPowerSchedule(powerSchedule)}
                                />
                            }
                            label={powerSchedule.name}
                        />
                    </ListItem>
                </div>
            );
        };

        const renderPowerSchedules = () => {
            if (isLoading) {
                return <ComponentLoader />;
            }

            return (
                <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <ListItem data-testid="select-all-item" dense sx={{ paddingLeft: 0 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isEverythingSelected}
                                    data-testid="checkbox-select-all"
                                    onChange={() => handleSelectAll()}
                                />
                            }
                            label={t.trans(
                                (!isEverythingSelected && 'engage.playlist.setup.targeting.groups.select_all') ||
                                    'engage.playlist.setup.targeting.groups.deselect_all'
                            )}
                        />
                    </ListItem>
                    <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                    <Box sx={{ overflowY: 'auto' }}>
                        {data.map((powerSchedule) => renderPowerScheduleItem(powerSchedule, 0))}
                    </Box>
                </List>
            );
        };

        return (
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: onSelectPowerSchedules.loading,
                            label: 'common.action.continue',
                            onClick: () => onSelectPowerSchedules.execute(),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="OPENING_HOURS" />,
                    label: 'engage.modal.assign.power_schedule.title',
                }}
            >
                <div className={classes.root} data-testid="power-schedule-selection-root">
                    {renderPowerSchedules()}
                </div>
            </Modal>
        );
    }
);

export default PowerScheduleSelectionModal;
