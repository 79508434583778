import { NotifyFunctionType, ShowNotificationParams } from 'src/hooks/useNotify';

export type MessageType = Pick<ShowNotificationParams, 'message' | 'params' | 'plural'>;
export type PendingPromiseMessages = {
    pending: MessageType | string;
    success: MessageType | string;
};

const handlePendingPromise = async <ReturnValue>(
    promise: Promise<ReturnValue> | (() => Promise<ReturnValue>),
    messages: PendingPromiseMessages,
    notify: NotifyFunctionType
): Promise<ReturnValue> => {
    const pendingMessage = typeof messages.pending === 'string' ? { message: messages.pending } : messages.pending;
    const key = notify({
        ...pendingMessage,
        persist: true,
        variant: 'info',
    });

    try {
        const successMessage = typeof messages.success === 'string' ? { message: messages.success } : messages.success;
        const awaitable = typeof promise === 'function' ? promise() : promise;
        const response = await awaitable;

        // Notify only on successful requests
        notify({ ...successMessage, key, variant: 'success' });

        return response;
    } catch (e) {
        notify({ key, remove: true });

        throw e;
    }
};

export default handlePendingPromise;
