import React, { useCallback, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Box, Divider, FormControlLabel, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ConfigModelsGroupInfo, ConfigModelsGroupInfoWithChildren } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { TreeNode } from '@zetadisplay/engage-components/utils/tree-builder';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Checkbox, ComponentLoader, Expander, Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { kebabCase } from 'lodash';

import useGroups from 'src/components/Modals/Group/Hooks/useGroups';
import { createDefaultButtons } from 'src/modules/Modal/Components/ModalActions';
import Modal from 'src/modules/Modal/Modal';

export const getGroupTestIdName = (name: string, isChecked: boolean, isIndeterminate: boolean) => {
    let testIdName = `group-${kebabCase(name)}`;

    if (isChecked) {
        testIdName = `${testIdName}-checked`;
    }

    if (isIndeterminate) {
        testIdName = `${testIdName}-indeterminate`;
    }

    return testIdName;
};

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        height: 'calc(80vh - 138px)',
        justifyContent: 'center',
    },
}));

export type GroupSelectionModalProps = {
    initialExpandedGroups?: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
    initialSelectedGroups?: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[];
    resolveSelectedGroups?: (
        groups?: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>[]
    ) => Promise<unknown>;
} & NiceModalHocProps;

const GroupSelectionModal = NiceModal.create<GroupSelectionModalProps>(
    ({ initialExpandedGroups, initialSelectedGroups, resolveSelectedGroups }) => {
        const { classes } = useStyles();
        const groups = useGroups();
        const modal = useModal();
        const t = useTranslation();
        const theme = useTheme();

        const rootGroups = [...(groups.data[0]?.children?.values() || [])];
        const [selectedGroups, setSelectedGroups] = useState(initialSelectedGroups || []);
        const [expandedGroups, setExpandedGroups] = useState(initialExpandedGroups || []);

        const onSelectGroups = useAsyncCallback(async () => {
            modal.resolve(resolveSelectedGroups ? await resolveSelectedGroups(selectedGroups) : selectedGroups);
            modal.hide();
        });

        const toggleExpandedGroup = useCallback(
            (group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>) => {
                setExpandedGroups((prevState) => {
                    return prevState.some((p) => p.id === group.id)
                        ? prevState.filter((p) => p.id !== group.id)
                        : [...prevState, group];
                });
            },
            []
        );

        const toggleSelectedGroup = useCallback(
            (group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfoWithChildren>, number>) => {
                setSelectedGroups((prevState) => {
                    return prevState.some((p) => p.id === group.id)
                        ? prevState.filter((p) => p.id !== group.id)
                        : [...prevState, group];
                });
            },
            []
        );

        const checkIfGroupIsExpanded = useCallback(
            (group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>) => {
                return !!expandedGroups.find((g) => g.id === group.id);
            },
            [expandedGroups]
        );

        const checkIfGroupIsSelected = useCallback(
            (group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>) => {
                return !!selectedGroups.find((g) => g.id === group.id);
            },
            [selectedGroups]
        );

        const checkIfChildrenAreSelected = useCallback(
            (
                group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>,
                operation: 'every' | 'some' = 'every'
            ): boolean => {
                if (group.children.size) {
                    return [...group.children.values()][operation](
                        (child) => checkIfGroupIsSelected(child) && checkIfChildrenAreSelected(child, operation)
                    );
                }

                return checkIfGroupIsSelected(group);
            },
            [checkIfGroupIsSelected]
        );

        const checkIfEveryGroupAndChildrenAreSelected = useCallback(
            (gprs: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[]): boolean => {
                return gprs.every((group) => {
                    return checkIfGroupIsSelected(group) && checkIfChildrenAreSelected(group);
                });
            },
            [checkIfChildrenAreSelected, checkIfGroupIsSelected]
        );

        const checkIfSomeGroupAndChildrenAreSelected = useCallback(
            (gprs: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>[]): boolean => {
                return gprs.some((group) => {
                    return checkIfGroupIsSelected(group) || checkIfChildrenAreSelected(group, 'some');
                });
            },
            [checkIfChildrenAreSelected, checkIfGroupIsSelected]
        );

        const checkIfIsPartiallySelected = (group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>) => {
            const isExpandableGroup = !!group.children.size;
            if (!isExpandableGroup) {
                return false;
            }

            const isCurrentlySelected = checkIfGroupIsSelected(group);
            if (isCurrentlySelected && checkIfEveryGroupAndChildrenAreSelected([...group.children.values()])) {
                return false;
            }

            if (isCurrentlySelected && !checkIfSomeGroupAndChildrenAreSelected([...group.children.values()])) {
                return true;
            }

            return checkIfSomeGroupAndChildrenAreSelected([...group.children.values()]);
        };

        const handleSelectGroupAndAllChildren = (
            group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>
        ) => {
            if (selectedGroups.find((g) => g.id === group.id) === undefined) {
                toggleSelectedGroup(group);
            }

            if (group.children.size) {
                group.children.forEach((child) => handleSelectGroupAndAllChildren(child));
            }
        };

        const isEverythingSelected = checkIfEveryGroupAndChildrenAreSelected(rootGroups || []);
        const handleSelectAll = () => {
            if (isEverythingSelected) {
                return setSelectedGroups([]);
            }

            return rootGroups.forEach((group) => handleSelectGroupAndAllChildren(group));
        };

        const renderGroupItem = (
            group: TreeNode<DiscriminatedEntity<ConfigModelsGroupInfo>, number>,
            depth: number
        ) => {
            const isCheckedGroup = checkIfGroupIsSelected(group);
            const isExpandableGroup = !!group.children.size;
            const isExpandedGroup = checkIfGroupIsExpanded(group);
            const isIndeterminateGroup = checkIfIsPartiallySelected(group);

            return (
                <div key={group.id}>
                    <ListItem
                        data-testid={`${kebabCase(group.name)}-group`}
                        dense
                        style={{ paddingLeft: theme.spacing(depth * 4) }}
                    >
                        <Expander
                            disabled={!group.children.size}
                            expanded={isExpandedGroup}
                            onClick={() => isExpandableGroup && toggleExpandedGroup(group)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCheckedGroup}
                                    data-testid={getGroupTestIdName(group.name, isCheckedGroup, isIndeterminateGroup)}
                                    indeterminate={isIndeterminateGroup}
                                    onChange={() => toggleSelectedGroup(group)}
                                />
                            }
                            label={group.name}
                        />
                    </ListItem>

                    {isExpandableGroup && isExpandedGroup && (
                        <List component="div" disablePadding>
                            {[...group.children.values()].map((child) => renderGroupItem(child, depth + 1))}
                        </List>
                    )}
                </div>
            );
        };

        const renderGroups = () => {
            if (groups.isLoading) {
                return <ComponentLoader />;
            }

            return (
                <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <ListItem data-testid="select-all-item" dense sx={{ paddingLeft: '36px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isEverythingSelected}
                                    data-testid="checkbox-select-all"
                                    onChange={() => handleSelectAll()}
                                />
                            }
                            label={t.trans(
                                (!isEverythingSelected && 'engage.playlist.setup.targeting.groups.select_all') ||
                                    'engage.playlist.setup.targeting.groups.deselect_all'
                            )}
                        />
                    </ListItem>
                    <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                    <Box sx={{ overflowY: 'auto' }}>{rootGroups.map((group) => renderGroupItem(group, 0))}</Box>
                </List>
            );
        };

        return (
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: { onClick: modal.hide },
                        submit: {
                            busy: onSelectGroups.loading,
                            label: 'common.action.continue',
                            onClick: () => onSelectGroups.execute(),
                        },
                    }),
                }}
                dark
                title={{
                    icon: <Icon type="PLAYER_GROUP" />,
                    label: 'engage.modal.players.group.targeting.title',
                }}
            >
                <div className={classes.root} data-testid="group-selection-root">
                    {renderGroups()}
                </div>
            </Modal>
        );
    }
);

export default GroupSelectionModal;
